.svg-sprites {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
}

.block-style-css {
  background: @color_w;
  border: 1px solid @color_b5;
  border-radius: @br;
  //box-shadow: 0 3px 13px -5px rgba(@color_b1, 0.2);
}

.main-container {
  .bg-decor {
    display: inline-block;
    width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: -1;

    path:nth-child(1) {
      animation: transformsvg 23s infinite ease-in-out;
    }

    path:nth-child(2) {
      animation: transformsvg 30s infinite ease-in-out;
    }
  }
}

br {
  display: none !important;
}

@keyframes transformsvg {
  0%,
  100% {
    transform: translate(-20%, 0) scale(1.2, 1) skew(0, 0) rotate(0);
  }
  50% {
    transform: translate(-40%, 20px) scale(1.4, 1) skew(5deg, 0) rotate(4deg);
  }
}

.hn2 {
  margin-bottom: @gap;

  > * {
    font: @f_hbold 32px/110% @font;

    @media only screen and (max-width: 860px), only screen and (max-device-width: 860px) {
      font: @f_hbold 28px/110% @font;
    }
  }
}

.btn-more-css {
  .btn-small-size;
}

.btn-load-more-css {
  .btn-small-size;

  &::after {
    content: '\f078';
  }
}

.btn-learn-more-css {
  .btn-small-size;
}

.btn-book-now-css {
  .btn-small-size;

  &::after {
    content: '\f02b';
  }
}

// ---------------------------------- MAIN WELCOME PAGE
.section__welcome {
  display: flex;
  width: 100%;
  height: 70vh;
  max-height: 700px;
  min-height: 380px;
  background: url('@{gallery}welcome-bg.jpg') 50% 100% no-repeat;
  background-size: cover !important;
  border-radius: 0;
  position: relative;

  .resolution-wrap {
    height: 100%;
    display: flex;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }

  .welcome-content {
    display: flex;
    justify-content: center; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios
    margin-right: auto;
    color: @color_w;
    text-align: center;

    h1 {
      font: @f_hbold 60px/120% @font !important;
    }

    p {
      font: @f_reg 20px/120% @font;
    }
  }
}

.section__factlist {
  padding-top: @gap*3;
  padding-bottom: @gap*3;

  .factlist {
    display: flex;
    margin-left: -@gap;
    margin-right: -@gap;

    @media only screen and (max-width: 580px), only screen and (max-device-width: 580px) {
      flex-wrap: wrap;
    }

    &__item {
      padding-left: @gap;
      padding-right: @gap;
      width: 33.33%;
      display: flex;
      flex-direction: column;
      align-content: center;
      align-items: center;
      justify-content: flex-start;
      text-align: center;

      @media only screen and (max-width: 890px), only screen and (max-device-width: 890px) {
        > * {
          max-width: 160px;
        }

        h3 {
          font: @f_hbold 20px/120% @font;
        }

        p {
          font: @f_reg 16px/120% @font;
        }
      }

      @media only screen and (max-width: 580px), only screen and (max-device-width: 580px) {
        width: 100%;
        text-align: left;
        margin-bottom: @gap*2;

        > * {
          max-width: 320px;
          flex-direction: row;
          align-content: flex-start;
          align-items: flex-start;
          justify-content: center;

          > *:last-child {
            padding-left: @gap;
          }
        }

        h3 {
          font: @f_hbold 20px/120% @font;
        }

        p {
          font: @f_reg 16px/120% @font;
        }
      }

      > * {
        max-width: 260px;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
      }

      h3 {
        font: @f_hbold 30px/120% @font;
      }

      p {
        font: @f_reg 18px/120% @font;
      }

      &-image {
        width: 100%;
        margin-left: auto;
        margin-right: auto;

        img {
          width: 100%;
        }
      }
    }
  }
}

.section__list {
  padding-top: @gap*2;
  padding-bottom: @gap*2;
}

.main-headline {
  position: relative;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: @gap*2;

  &__icon {
    display: flex;
    height: 88px;
    position: absolute;
    z-index: -1;
    left: -40px;
    top: -20px;

    img {
      height: 88px;
    }
  }

  h2 {
    font: @f_hbold 40px/120% @font;
  }

  @media only screen and (max-width: 860px), only screen and (max-device-width: 860px) {
    &__icon {
      height: 50px;
      left: auto;
      right: -3px;
      top: -8px;

      img {
        height: 50px;
      }
    }

    h2 {
      font: @f_hbold 32px/120% @font;
    }
  }
}

.section__map-decor {
  &__wrap {
    background: url('@{gallery}map.jpg') 50% 50% no-repeat;
    background-size: cover !important;
    padding-bottom: 37%;
    position: relative;

    .map__link {
      display: flex;
      position: absolute;
      z-index: 2;
      text-decoration: none;
      color: @color_b1;
      font: @f_hbold 2vw/120% @font;

      &:hover {
        color: @color_1;
        text-shadow: 0 6px 10px rgba(@color_b1, 0.06), 0 13px 30px rgba(@color_b1, 0.06);
      }

      &.link-1 {
        top: 45%;
        left: 20%;
      }

      &.link-2 {
        top: 40%;
        left: 47%;
      }
    }
  }
}

.section__about-us {
  padding-top: @gap*3;
  padding-bottom: @gap*3;

  .img-wrap {
    border-radius: @br;
    overflow: hidden;
  }

  .col-wrap__about {
    display: flex;
    margin-left: -@gap;
    margin-right: -@gap;
    flex-wrap: wrap;

    > * {
      padding-left: @gap;
      padding-right: @gap;
    }

    > *:first-child {
      width: 40%;
    }

    > *:last-child {
      width: 60%;
    }

    @media only screen and (max-width: 860px), only screen and (max-device-width: 860px) {
      > * {
        width: 100% !important;
      }
    }
  }
}

.slider-x3 {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: @gap;
  gap: @gap / 2;

  .slick-list {
    .slick-track {
      display: flex !important;
      min-width: 100%;
      justify-content: stretch; // align X
      align-items: stretch; // align Y
      align-content: stretch; // align Y ios
    }
  }

  &__item {
    width: 100%;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    height: 100%;

    > div {
      display: flex;
      padding: @gap;
      flex-direction: column;
      text-decoration: none;
      .block-style-css;

      > * {
        margin-bottom: 8px;
      }
    }

    &__title {
      > * {
        font: @f_sbold 18px/120% @font;
      }
    }

    &__prev-img {
      display: flex;
      position: relative;
      padding-bottom: 56.25%;
      width: 100%;

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        font-family: 'object-fit: cover;';
      }
    }

    .limited-x5-lines {
      display: -webkit-box;
      -webkit-line-clamp: 5; // количество строк
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    &__content {
      > * {
        font: @f_reg var(--paragraph-2)/130% @font;
      }
    }

    &__contacts {

      [class*='icn-']:not(:last-child) {
        margin-bottom: 6px;
      }

      a, p {
        display: flex;
        padding: 1px 0;
        text-decoration: none;
        color: @color_b1;
        border-radius: @br;
        font: @f_reg 14px/110% @font;

        //&:hover {
        //  background: @color_b6;
        //  padding-left: 4px;
        //}

        &::before {
          content: '';
          display: flex !important;
          width: 18px;
          height: 18px;
          align-content: center;
          align-items: center;
          justify-content: center;
          position: relative;
          margin-right: 8px;
          top: -2px;
          .far;
          font-size: 14px;
          color: @color_1;
        }

      }

      .icn-address a::before, .icn-address p::before {
        content: '\f3c5';
      }

      .icn-phone a::before, .icn-phone p::before {
        content: '\f879';
      }

      .icn-website a::before, .icn-website p::before {
        content: '\f0ac';
      }

      .icn-email a::before, .icn-email p::before {
        content: '\f0e0';
      }
    }

    &__routes {
      > * {
        display: flex;
        flex-direction: column;

        b {
          display: inline-block;
          padding-right: 8px;
          white-space: nowrap;
          font-size: var(--caption);
        }

        p {
          font-size: var(--caption);
        }

        @media only screen and (max-width: 580px), only screen and (max-device-width: 580px) {
          flex-direction: column;
          justify-content: flex-start; // align X
          align-items: flex-start; // align Y
          align-content: flex-start; // align Y ios

        }

      }
    }

    &__footer {
      display: flex;
      width: 100%;
      justify-content: space-between; // align X
      align-items: center; // align Y
      align-content: center; // align Y ios

      margin-top: auto;
      margin-bottom: 0 !important;

      > * {
        width: auto;
        display: inline-flex !important;
      }
    }
  }

  .other-description {
    display: flex;
    align-content: center;
    align-items: flex-start;
    justify-content: flex-start;
    width: calc(100% + 8px);
    margin-left: -4px;

    > * {
      width: 50%;
      margin-left: 4px;
      margin-right: 4px;
      padding: 4px 8px 6px;
      border-radius: @br;
      background: @color_b6;

      span {
        font: @f_reg 11px/100% @font;
      }

      div {
        font: @f_reg 14px/120% @font;
      }
    }
  }

  .btn-more {
    width: 100%;
    height: auto;
    display: flex;
    align-content: flex-end;
    align-items: flex-end;
    justify-content: flex-end;
    margin-bottom: 0;

    a {
      .btn-more-css;
    }
  }
}

.business-slider__wrap {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: @gap;
  overflow: hidden;

  .transfer-logo {
    display: block;
    position: absolute;
    z-index: 3;
    left: @gap / 2;
    top: @gap / 2;
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: @br;

    @media only screen and (max-width: 860px), only screen and (max-device-width: 860px) {
      width: 72px;
      height: 72px;
    }

    img {
      position: absolute;

      width: 100%;
      height: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover;';
    }
  }

  .business-slider {
    margin-bottom: @gap;
    position: relative;
    border-radius: @br;

    .slick-track {
      display: flex;
    }

    .slick-slide {
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      overflow: hidden;
      text-align: center;
      outline: none !important;
      border-radius: @br;
    }

    a {
      position: relative;
      display: flex;
      width: 100%;
      padding-bottom: 56.25%;
      overflow: hidden;

      img {
        top: 0;
        left: 0;
        position: absolute;
        -o-object-fit: cover !important;
        object-fit: cover !important;
        width: 100%;
        height: 100%;
        font-family: 'object-fit: cover;';
      }
    }
  }

  .business-slider-nav {
    margin: auto;
    position: relative;
    overflow: hidden;

    .slick-list {
      margin: 0 -@gap / 2;
    }

    .slick-slide {
      margin-left: @gap / 2;
      margin-right: @gap / 2;
      border-radius: @br;
      overflow: hidden;

      &.slick-current {
        .img-wrap::after {
          content: '';
          display: block;
          z-index: 10;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          border-radius: @br;
          position: absolute;
          box-shadow: inset 0 0 0 4px rgba(@color_1);
        }
      }
    }

    .img-wrap {
      position: relative;
      display: flex;
      width: 100%;
      padding-bottom: 52.6%;

      // &::after {}

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        font-family: 'object-fit: cover;';
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
      }
    }
  }

  .business-slider,
  .business-slider-nav {
    width: 100%;

    img {
      width: 100%;
    }
  }

  button.slick-prev.slick-arrow {
    left: 5px;
  }

  button.slick-next.slick-arrow {
    right: 5px;
  }

  button.slick-arrow {
    transform-origin: 50% 50%;
  }

  button.slick-arrow:hover {
    transform: translateY(-50%) scale(1.2);
  }

  .item {
    outline: none !important;
  }
}

// SLICK TOOL THEME START
button.slick-arrow {
  display: block;
  top: 50%;
  width: 40px;
  height: 40px;
  z-index: 8;
  background: rgba(@color_1, 0.5) !important;
  .radius(@br);
  .centerxy;
  .transform(translateY(-50%));
  backdrop-filter: blur(4px);

  &:hover {
    background: @color_2;
  }

  &::before {
    position: relative;
    .fas;
  }
}

&:hover {
  button.slick-arrow {
    background: rgba(@color_1, 0.5) !important;
  }
}

button.slick-prev.slick-arrow {
  left: 6px;

  &::before {
    content: '\f053';
    left: -1px;
  }
}

button.slick-next.slick-arrow {
  right: 6px;

  &::before {
    content: '\f054';
    top: 1px;
    right: -1px;
  }
}

button.slick-lightbox-close {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  right: 10px;
  top: 10px;
  width: 40px;
  height: 40px;
  z-index: 8;

  &::before {
    content: @fa-var-times;
    position: relative;
    font-size: 22px;
    color: @color_1;
    .fas;
  }
}

.slick-lightbox {
  .slick-lightbox-slick-item-inner {
    width: 100%;

    img {
      //width: 100%;
      max-width: 980px;
      height: auto;
      max-height: 80vh;
    }
  }
}

// SLICK TOOL THEME END

.loader-mask {
  display: flex;
  width: 100%;
  height: 33.33vh;
  min-height: 200px;
  max-height: 480px;
  justify-content: center; // align X
  align-items: center; // align Y
  align-content: center; // align Y ios

  > div {
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios

    &::after {
      content: '\f110';
      .fas;
      font-size: 26px;
      color: @color_1;
      animation: fa-spin 1s steps(8) infinite;
    }
  }
}

.preview-price-description {
  padding: 8px;
  background: @color_b6;
  border-radius: @br;
  display: flex;
  justify-content: space-between; // align X
  align-items: center; // align Y
  align-content: center; // align Y ios

  &__passengers {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; // align X
    align-items: flex-start; // align Y
    align-content: flex-start; // align Y ios

    > span {
      font-size: 13px;
      display: flex;
      line-height: 110%;
    }

    .passengers {
      display: flex;
      text-align: left;
      justify-content: flex-start; // align X
      align-items: flex-end; // align Y
      align-content: flex-end; // align Y ios
      height: 34px;

      > div {
        font-size: 28px;
        font-weight: @f_sbold;
      }

      > span {
        font-size: 16px;
        font-weight: @f_sbold;
        line-height: 100%;
        margin-right: 3px;
      }
    }
  }

  &__base-rate {
    display: flex;
    flex-direction: row;
    justify-content: flex-start; // align X
    align-items: flex-end; // align Y
    align-content: flex-end; // align Y ios

    > span {
      font-size: 13px;
      text-align: right;
      line-height: 110%;
    }

    .base-rate {
      display: flex;
      text-align: right;
      justify-content: flex-end; // align X
      align-items: flex-end; // align Y
      align-content: flex-end; // align Y ios
      height: 34px;

      > div {
        font-size: 28px;
        font-weight: @f_sbold;
      }

      > span {
        font-size: 18px;
        margin-right: 3px;
        font-weight: @f_sbold;
        line-height: 110%;
      }
    }
  }
}

.btn-load-more {
  width: 100%;
  height: auto;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin-bottom: @gap;
  margin-top: @gap;

  a {
    .btn-load-more-css;
  }
}

.btn-download-pdf {
  margin-bottom: 0;

  a {
    .btn-small-size;

    &::after {
      content: '\f1c1';
      margin-right: 6px;
      .fal;
    }
  }
}


.btn-learn-more {
  a {
    .btn-learn-more-css;
  }
}

.btn-book-now {
  a {
    .btn-book-now-css;
  }
}

.weather-container {
  margin: 0 -@gap / 2;
  position: relative;

  .slick-list {
    padding-bottom: @gap * 2;
  }

  &__item {
    padding: 0 @gap / 2;

    > div {
      display: flex;
      flex-direction: column;
      align-content: flex-start;
      align-items: center;
      justify-content: center;
      width: 100%;
      text-align: center;

      .forecast-icon {
        width: 86px;
        height: 86px;
        overflow: hidden;
        border-radius: @br;
        margin-bottom: 8px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          font-family: 'object-fit: cover;';
        }
      }

      .period-name {
        font: @f_sbold var(--caption)/110% @font;
        margin-bottom: 4px;
      }

      .temp {
        font: @f_sbold var(--caption-micro)/110% @font;
        color: @color_1;
        margin-bottom: 4px;
      }

      .short-desc {
        font: @f_reg var(--caption-micro)/110% @font;
      }
    }
  }

  .slick-dots {
    bottom: 24px !important;
  }
}

.select-city form {
  max-width: 520px;
}

// ---------------------------------- AIRPORT
.page-content {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.section__airport-dashboard {
  width: 100%;

  .resolution-wrap {
    display: flex;
    width: 100%;
    position: relative;
    flex: 1 1 auto;
    gap: @gap * 1.5;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.route-description {
  display: flex;
  justify-content: flex-start; // align X
  align-items: center; // align Y
  align-content: center; // align Y ios
  background: @color_b6;
  border-radius: @gap / 2;
  padding: @gap;
  margin-bottom: @gap;

  &__info {
    display: flex;
    margin-right: @gap*2;
    justify-content: flex-start; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios

    > * {
      min-height: 26px;
      font-size: 16px;
      display: flex;
      justify-content: flex-start; // align X
      align-items: center; // align Y
      align-content: center; // align Y ios
    }

    &.info-distance::before {
      content: '\f545';
      .fal;
      position: relative;
      top: -2px;
      color: @color_1;
      margin-right: 3px;
    }

    &.info-time::before {
      content: '\f017';
      .fal;
      position: relative;
      top: -2px;
      color: @color_1;
      margin-right: 3px;
    }
  }

  .btn-directions {
    margin-left: auto;
    display: inline-flex;

    a {
      //margin-left: auto;
      display: inline-flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      padding: 5px 12px 4px;
      border-radius: @br / 1.5;
      border: 1px solid @color_1;
      background: @color_1;
      color: @color_w;
      font-size: 12px;

      &:after {
        content: '\f124';
        .fal;
        margin-left: 8px;
      }

      &:hover {
        background: @color_2;
        color: @color_w;
        border-color: @color_2;
        box-shadow: @primary-btn-hover;
      }
    }
  }
}

// About main image
.about-main-image {
  display: inline-flex;
  width: 100%;
  height: auto;
  position: relative;
  margin-bottom: @gap*2;

  > .big-img {
    display: flex;
    width: 100%;
    min-height: 350px;
    height: 40vh;
    max-height: 620px;
    position: relative;
    padding-bottom: 30%;
    overflow: hidden;
    border-radius: @br;
    // box-shadow: 0 5px 40px -9px rgba(0, 0, 0, 0.08);
    // border: 1px solid @color_b5;
    filter: brightness(99%) saturate(100%) contrast(102%);

    &::after {
      content: '';
      position: absolute;
      pointer-events: none;
      backdrop-filter: invert(70%);
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      background: @color_1;
      opacity: 0.04;
      z-index: 2;
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
      font-family: 'object-fit: cover;';
    }
  }

  .scroll-hint {
    position: absolute;
    width: calc(100% - 32px);
    left: 50%;
    bottom: 16px;
    transform: translateX(-50%);
    z-index: 2;
    font: @f_reg 14px/105% @font;
    display: flex;
    padding: 15px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: @br;
    background: rgba(255, 255, 255, 0.70);
    box-shadow: 0 4px 20px 0 rgba(56, 71, 94, 0.10);
    backdrop-filter: blur(8px);

  }
}


.transportation-banner-block {
  display: flex;
  padding: 38px 38px 135px;

  flex-direction: column;
  justify-content: flex-start; // align X
  align-items: flex-start; // align Y
  align-content: flex-start; // align Y ios
  background: @color_w;
  border-radius: @br;
  position: relative;
  min-height: 298px;

  h2 {
    margin-bottom: 24px;
    color: @color_w;
    font: @f_bold 42px/105% @font;

    @media only screen and (max-width: 720px), only screen and (max-device-width: 720px) {
      font: @f_bold 26px/105% @font;
    }

  }

  > h2, > .style-btn__1 {
    position: relative;
    z-index: 3;
  }

  &__cars-image {
    overflow: hidden;
    position: absolute;
    display: block;
    bottom: 0;
    left: auto;
    right: 0;
    z-index: 2;
    background: url("@{gallery}transportation-cars.png") 100% calc(100% - 30px) no-repeat;
    background-size: 70% auto !important;
    width: calc(100% - 22px);
    padding-bottom: 27.6%;

    @media only screen and (max-width: 720px), only screen and (max-device-width: 720px) {
      background-size: 100% auto !important;
      padding-bottom: calc(27.6% + 30px);

    }

  }

  &__city-image {
    border-radius: @br;
    overflow: hidden;
    background: @color_b1;
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    left: 0;
    height: calc(100% - 74px);
    z-index: 1;
    width: 100%;

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover;';
    }
  }

}

.transportation-add-nav {
  display: flex;
  flex-wrap: wrap;
  gap: @gap;
  margin-bottom: @gap;

  &__item {
    padding: 0;
    flex: 1 0 300px;

    a {
      display: flex;
      justify-content: flex-start; // align X
      align-items: center; // align Y
      align-content: center; // align Y ios
      background: @color_1;
      color: @color_w;
      text-decoration: none;
      border-radius: @br;

      &::after {
        content: url('data:image/svg+xml;charset=utf-8,<svg width="29" height="12" viewBox="0 0 29 12" fill="none" xmlns="http://www.w3.org/2000/svg"><g opacity="0.5"><path d="M15.668 1L20.668 6L15.668 11" stroke="white" stroke-width="2" stroke-linecap="round"/><path d="M6.66797 6H19.168" stroke="white" stroke-width="2" stroke-linecap="round"/></g></svg>');
        margin-left: 4px;
        position: relative;
        top: 1px;
        transition: all 0.23s linear;
      }

      svg {
        width: 65px;
        height: 65px;
        min-width: 65px;
        min-height: 65px;
        max-width: 65px;
        max-height: 65px;
        fill: @color_w;
      }
    }
  }
}

body:not(.mobile) {
  .transportation-add-nav {
    &__item {
      a:hover {
        background: @color_2;
        box-shadow: @primary-btn-hover;

        &::after {
          transform: translateX(10px);
        }
      }
    }
  }
}

.accordion {
  .big-img {
    width: 100%;

    img {
      width: 100%;
    }
  }
}

.dashboard-content {
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 960px) and (max-width: 2480px) {
    max-width: calc(100% - var(--left-nav-width) - var(--right-parking-sidebar-width) - (@gap * 2));
  }
  @media (min-width: 280px) and (max-width: 959.98px) {
    max-width: 100%;
  }

  padding: 16px 0;
  background: @color_w;
  position: relative;
  z-index: 1;

  @media only screen and (max-width: 860px), only screen and (max-device-width: 860px) {
    width: 100%;
    padding: 16px 0;
  }

  .col-wrap__2 {
    @media only screen and (max-width: 1124px), only screen and (max-device-width: 1124px) {
      > * {
        width: 100%;
      }
    }
  }

  .crumbs {
    display: flex;
    width: calc(100% + @gap);
    overflow: hidden;
    overflow-x: auto;
    -ms-overflow-style: none; /* Приховує скроллбар в Internet Explorer і Edge */
    scrollbar-width: none; /* Приховує скроллбар в Firefox */

    & > * {
      padding: 8px 8px 8px 0;
      display: flex;
      text-decoration: none;
      align-content: center;
      align-items: center;
      justify-content: center;
      font-size: var(--caption);
      white-space: nowrap;
      position: relative;

      &:not(:last-child) {
        &::after {
          content: '\f105';
          font-size: 16px;
          .fal;
          margin-left: 8px;
          color: @color_b3;
        }
      }
    }

    & > a {

      &:first-child {
        font-size: 0 !important;

        &::before {
          content: url('data:image/svg+xml;charset=utf-8,<svg width="16" height="16" viewBox="0 0 576 576" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M536.611 287.524C536.611 303.074 523.652 315.256 508.966 315.256H481.321L481.926 453.654C481.926 455.987 481.753 458.319 481.494 460.652V474.647C481.494 493.74 466.03 509.203 446.937 509.203H433.115C432.164 509.203 431.214 509.204 430.264 509.117C429.054 509.204 427.845 509.203 426.635 509.203L398.558 509.117H377.824C358.732 509.117 343.268 493.653 343.268 474.561V453.827V398.537C343.268 383.245 330.914 370.891 315.623 370.891H260.333C245.041 370.891 232.687 383.245 232.687 398.537V453.827V474.561C232.687 493.653 217.223 509.117 198.131 509.117H177.397H149.838C148.542 509.117 147.247 509.031 145.951 508.944C144.914 509.031 143.877 509.117 142.841 509.117H129.018C109.926 509.117 94.4617 493.653 94.4617 474.561V377.803C94.4617 377.025 94.4617 376.161 94.5481 375.384V315.169H66.9029C51.3526 315.169 39.2578 303.074 39.2578 287.438C39.2578 279.662 41.8495 272.751 47.8969 266.704L269.317 73.7062C275.365 67.6588 282.276 66.7949 288.323 66.7949C294.371 66.7949 301.282 68.5227 306.465 72.8423L527.108 266.79C534.019 272.838 537.475 279.749 536.611 287.524Z" fill="rgb(28,124,207)"/></svg>');
          height: 16px;
          width: 16px;
          min-width: 16px;
          transition: all 0.2s linear;

          opacity: .5;
        }
      }

      color: @color_1;


    }

    & > div {
      color: @color_b1;
    }

    & > a:hover {
      color: @color_2 + 20%;

      &::before {
        opacity: 1;
      }
    }
  }

  &__title {
    margin-bottom: @gap / 2;

    > * {
      //font: @f_hbold 44px/110% @font;
      font: @f_hbold var(--headname-1)/110% @font;

      @media only screen and (max-width: 860px), only screen and (max-device-width: 860px) {
        font: @f_hbold var(--headname-2)/110% @font;
      }
    }
  }

  &__sub-title {
    margin-bottom: @gap;
    display: flex;
    flex-wrap: wrap;
    gap: @gap;
    position: relative;
    justify-content: flex-start; // align X
    align-items: flex-start; // align Y
    align-content: flex-start; // align Y ios
    min-height: 40px;

    > *:not(.sort-by-block):not(.rates-updated-date) {
      font: @f_hbold var(--headname-3)/113% @font;

      @media only screen and (max-width: 860px), only screen and (max-device-width: 860px) {
        font: @f_hbold var(--headname-4)/113% @font;
      }
    }

    .sort-by-block {
      display: flex;
      flex: 1 1 200px;
      min-height: 40px;
      margin-left: auto;
      white-space: nowrap;
      justify-content: flex-end; // align X
      align-items: center; // align Y
      align-content: center; // align Y ios
      gap: 8px;
      position: relative;

      > div:first-child {
        font-size: var(--caption);
      }
    }

    .rates-updated-date {
      display: flex;
      justify-content: flex-start; // align X
      align-items: center; // align Y
      align-content: center; // align Y ios
      font-size: var(--caption);

      flex: 1 1 200px;
      min-height: 40px;
      margin-left: auto;
      white-space: nowrap;

      span {
        display: inline-flex;
        opacity: 0.7;
        margin-right: 4px;
      }
    }

  }

  &__sub-sub-title {
    margin-bottom: @gap;

    > * {
      font: @f_sbold var(--headname-4)/113% @font;

      @media only screen and (max-width: 860px), only screen and (max-device-width: 860px) {
        font: @f_sbold var(--headname-5)/113% @font;
      }
    }
  }

  .airport-information {
    .table-list {
      margin-bottom: @gap*2;
    }

    .hn4 {
      margin-bottom: 8px;

      > * {
        font: @f_hbold var(--headname-4)/120% @font;

        @media only screen and (max-width: 860px), only screen and (max-device-width: 860px) {
          font: @f_hbold var(--headname-5)/120% @font;
        }
      }
    }
  }

  .table-list {
    margin-bottom: @gap * 2;

    &__item {
      background: @color_b6;
      margin-bottom: 3px;
      border-radius: @br;
      display: flex;
      overflow: hidden;
      position: relative;

      .copy-btn {
        position: absolute;
        z-index: 2;
        top: 2px;
        right: 8px;

      }

      @media only screen and (max-width: 640px), only screen and (max-device-width: 640px) {
        flex-direction: column;
        justify-content: flex-start; // align X
        align-items: flex-start; // align Y
        align-content: flex-start; // align Y ios
        > * {
          padding: 4px 16px;
          font-size: var(--paragraph-2);
          flex: 1 1 auto !important;

          &:nth-child(1) {
            margin-bottom: -13px;
          }

          &:nth-child(2) {
            padding-left: 16px;
          }
        }
      }

      > * {
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: var(--paragraph-1);

        &:nth-child(1) {
          flex: 0 0 200px;
          text-align: left;
          white-space: nowrap;
          font-weight: @f_sbold !important;
          padding-left: 16px;
          padding-right: 4px;
        }

        &:nth-child(2) {
          font-weight: @f_reg;
          padding-left: 4px;
          padding-right: 16px;


          a {
            max-width: 100%;
            display: inline-flex;
            word-wrap: break-word !important;
          }
        }
      }
    }
  }

  .about-description {
    margin-bottom: @gap*3;
  }

  .map-container {
    width: 100%;
    overflow: hidden;
    border-radius: @br;

    iframe {
      width: 100%;
      height: 48vh;
      min-height: 460px;
      max-height: 640px;
    }
  }

  .headline-more {
    width: 100%;
    //background: @color_b6;
    padding: 10px 0;
    border-radius: @br;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    margin-bottom: @gap / 1.5;
    margin-top: @gap;

    h2 {
      font: @f_hbold var(--headname-3)/110% @font;

      @media only screen and (max-width: 860px), only screen and (max-device-width: 860px) {
        font: @f_hbold var(--headname-4)/110% @font;
      }
    }

    a {
      .btn-more-css;
    }
  }
}

// MOBILE DASHBOARD CONTENT
.header {
  @media (min-width: 280px) and (max-width: 959.98px) {
    & ~ .page-content .dashboard-content {
      width: 100%;
      padding: 16px 0;
    }
  }
}

.section-error {
  display: flex;
  height: 100%;
  flex-direction: column;
  margin: auto;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: center;

  &__number {
    font: @f_hbold 80px/120% @font;
    color: @color_1;
    margin-bottom: 20px;
  }

  &__details {
    margin-bottom: 20px;
  }
}

.bottom-hr {
  box-shadow: inset 0 -1px 0 0 rgba(@color_b5, 1);
  padding-bottom: @gap;
  margin-bottom: @gap*3;
}

// ---------------------------------- AIRPORT - TERMINAL

.description-box {
  padding: 16px;
  display: flex;
  background: @color_b6;
  border-radius: @br;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: @gap * 2;

  &__text {
    font-size: var(--paragraph-1);
    padding-right: 16px;
  }
}

.ground-transportation-form {
  max-width: 420px;
  margin-bottom: @gap*3;
}

.ground-transportation-map {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__description {
    display: flex;
    margin-bottom: 8px;

    > * {
      display: flex;
      margin-right: @gap;
      align-content: center;
      align-items: center;
      justify-content: center;
      padding: 8px 0;

      i {
        position: relative;
        top: -1px;
        display: flex;
        width: 20px;
        height: 20px;
        align-content: center;
        align-items: center;
        justify-content: center;

        &::before {
          color: @color_1 !important;
        }
      }

      > * {
        margin-right: 4px;
        white-space: nowrap;
      }
    }
  }

  .map {
    display: block;
    width: 100%;

    iframe {
      width: 100%;
      height: 40vh;
      min-height: 320px;
      max-height: 720px;
    }
  }
}

// ---------------------------------- ID STYLES GROUP

.col-list {
  display: flex;
  flex-wrap: wrap;
  gap: @gap;
  margin-bottom: @gap / 2;

  &.state-list div a {
    min-height: 80px;
    align-content: center;
    align-items: center;
    justify-content: flex-start;

    h3 {
      font: @f_reg var(--headname-4)/120% @font;
      padding-left: 38px;
      z-index: 2;

      @media only screen and (max-width: 860px), only screen and (max-device-width: 860px) {
        font: @f_reg var(--headname-5)/120% @font;
      }

    }
  }

  &__item {
    flex: 1 1 300px;
    position: relative;

    > a {
      border: 1px solid @color_b5;
      //box-shadow: @shadow;
      background: rgba(@color_w, 0.8);
      backdrop-filter: opacity(13px);
      padding: @gap @gap*2 @gap @gap;
      display: flex;
      text-decoration: none;
      color: @color_b1;
      border-radius: @br;
      height: 100%;
      margin: 0 auto !important;
      position: relative;
      font-size: var(--paragraph-2);

      &::after {
        content: '\f178';
        .fal;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        color: @color_b5;
      }

      .icn-state {
        position: absolute;
        z-index: 1;
        left: 2px;
        top: 50%;
        transform: translateY(-50%);
        width: 70px;
        height: 70px;
        transition: all 0.2s linear;
      }

      h3 {
        z-index: 2;
      }
    }

    &::before {
      display: none !important;
    }

    &:hover {
      z-index: 3;

      > a {
        border: 1px solid @color_1;
        box-shadow: 0 8px 30px -2px rgba(@color_1, 0.08);

        &::after {
          color: @color_3;
          right: 10px;
        }

        svg {
          left: 8px;
        }
      }
    }
  }
}

.tabs {
  display: block;
  background: @color_b6;
  padding: @gap / 2;
  border-radius: @br;
  margin-bottom: @gap*2;

  // CUSTOM SCROLLBAR
  //=============================================================================
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: @color_w !important;
  }

  &::-webkit-scrollbar-thumb {
    background: @color_w !important;
    border-radius: @br;

    &:hover {
      background: @color_w !important;
    }

    &:active {
      background: @color_w !important;
    }
  }

  &__wrap {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
  }

  &__item {
    display: flex;

    a {
      padding: @gap / 1.6 @gap / 1.2;
      margin-right: 8px;
      color: @color_b2;
      text-decoration: none;
      border-radius: @br;
      font-size: var(--paragraph-2);
    }

    &.active {
      a {
        background: @color_1;
        color: @color_w;
      }
    }
  }
}

body:not(.mobile) .tabs {
  &__item:not(.active) {
    a:hover {
      background: @color_b6 - 20%;
      color: @color_b1;
    }
  }

  @media only screen and (max-width: @resolution), only screen and (max-device-width: @resolution) {
    background: none;
    padding: 0;

    &__wrap {
      flex-wrap: wrap;
    }

    &__item {
      flex: 0 1 auto;
      margin-bottom: 10px;

      a {
        white-space: nowrap;
        background: @color_b6;
      }

      &.active {
        a {
          background: @color_1;
          color: @color_w;
        }
      }
    }
  }
}

body.mobile .tabs {
  @media only screen and (max-width: @resolution), only screen and (max-device-width: @resolution) {
    background: none;
    width: calc(100% + @gap);
    display: block;
    overflow: hidden;
    overflow-x: auto;
    padding: 0 @gap*8 0 0 !important;
    white-space: nowrap;

    &__wrap {
      display: inline-flex;
      white-space: nowrap;
      flex-wrap: nowrap !important;
      width: auto;
      position: relative;
    }

    &__item {
      a {
        white-space: nowrap;
        background: @color_b6;
      }

      &.active {
        a {
          background: @color_1;
          color: @color_w;
        }
      }
    }
  }
}

.info-block {
  margin-bottom: @gap*3;

  &__item {
    margin-bottom: 8px;

    p {
      display: flex;
      margin-bottom: 0 !important;
      flex-wrap: wrap;

      > * {
        flex: 1 1 250px;
        padding: 10px @gap;
        background: @color_b6;
        border-radius: @br;
      }
    }
  }
}

#map.services-map {
  height: 40vh;
  border: 1px solid @color_b5;
  overflow: hidden;
  border-radius: @br;
}

// weather
.weather {
  display: block;
  width: 100%;
  margin-bottom: @gap*2;

  &__list {
    display: flex;
    margin-left: -@gap;
    margin-right: -@gap;
    flex-wrap: wrap;
  }

  &__item {
    padding-left: @gap;
    padding-right: @gap;
    flex: 1 1 120px;

    &-container {
      margin-top: @gap;
      margin-bottom: @gap;

      .period-name {
        font-size: var(--caption-micro);
        line-height: 120%;
        text-transform: uppercase;
        font-weight: @f_bold;
        white-space: nowrap;
        margin-bottom: 4px;
      }

      .temp {
        font-weight: @f_bold;
        margin-bottom: 3px;

        &::before {
          font-size: var(--caption);
          line-height: 120%;
          margin-right: 2px;
        }

        &.temp-low {
          &::before {
            content: 'Low:';
          }

          color: @color_1 !important;
        }

        &.temp-high {
          &::before {
            content: 'High:';
          }

          color: @color_4 !important;
        }
      }

      .short-desc {
        font-size: var(--paragraph-2);
        line-height: 117%;
      }

      .img-wrap {
        width: 90px;
        height: 90px;
        max-height: 90px;
        overflow: hidden;
        position: relative;
        border-radius: @br;
        margin-bottom: 2px;
        // padding-bottom: 100%;
        box-shadow: @shadow;

        img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          font-family: 'object-fit: cover;';
        }
      }
    }
  }
}

.weekly-forecast {
  display: flex;
  flex-wrap: wrap;
  margin-left: -@gap / 2;
  margin-right: -@gap / 2;

  &__item {
    padding-left: @gap / 2;
    padding-right: @gap / 2;
    margin-bottom: @gap;
    width: 100% / 4;

    @media only screen and (max-width: 1024px), only screen and (max-device-width: 1024px) {
      width: 100% / 3;
    }

    @media only screen and (max-width: 860px), only screen and (max-device-width: 860px) {
      width: 100% / 2;
    }

    @media only screen and (max-width: 560px), only screen and (max-device-width: 560px) {
      width: 100%;
    }

    .wrap {
      padding: @gap*1.3;
      border-radius: @br;
      box-shadow: @shadow_s;
      background: @color_w;
      height: 100%;
    }
  }

  &__title {
    > * {
      font-size: var(--headname-5);
      font-weight: @f_bold !important;
    }
  }

  &__info,
  &__info > * {
    font-size: var(--paragraph-2);
    font-weight: @f_reg;
  }
}

// ---------------------------------- ПЛИТКА
.tile-list {
  position: relative;

  &__wrap {
    display: flex;
    flex-wrap: wrap;
    gap: @gap / 2;
    justify-content: flex-start;
  }

  &__item {
    display: flex;
    width: 100%;
    flex: 1 1 auto; // Ширина для трьох блоків в рядку


    > div {
      display: flex;
      width: 100%;
      padding: @gap;
      flex-direction: column;
      text-decoration: none;
      .block-style-css;

      > * {
        margin-bottom: 8px;
      }
    }

    &__title {
      > * {
        font: @f_sbold var(--headname-3)/120% @font;

        @media only screen and (max-width: 860px), only screen and (max-device-width: 860px) {
          font: @f_sbold var(--headname-4)/120% @font;
        }

      }
    }

    .limited-x5-lines {
      display: -webkit-box;
      -webkit-line-clamp: 5; // количество строк
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    &__content {
      > * {
        font: @f_reg var(--paragraph-2)/120% @font;
      }
    }

    [class*='__contacts'] {

      [class*='icn-'] {
        margin-bottom: 3px;
      }

      a, p {
        display: flex;
        position: relative;
        padding: 1px 0 0 20px;
        text-decoration: none;
        color: @color_b1;
        border-radius: @br;
        font: @f_reg var(--paragraph-2)/110% @font;

        //&:hover {
        //  background: @color_b6;
        //  padding-left: 4px;
        //}

        &::before {
          content: '';
          display: flex !important;
          width: 18px;
          position: absolute;
          height: 18px;
          align-content: center;
          align-items: center;
          justify-content: center;
          margin-right: 8px;
          top: 0;
          left: 0;
          .far;
          font-size: 14px;
          color: @color_1;
        }
      }

      .icn-email {
        width: 100%;
        display: flex;

        p {
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          width: 100%;
          max-width: 200px;
          text-overflow: ellipsis;
        }
      }

      .icn-address a::before, .icn-address p::before {
        content: '\f3c5';
      }

      .icn-phone a::before, .icn-phone p::before {
        content: '\f879';
      }

      .icn-website a::before, .icn-website p::before {
        content: '\f0ac';
      }

      .icn-email a::before, .icn-email p::before {
        content: '\f0e0';
      }
    }
  }

  .other-description {
    display: flex;
    align-content: center;
    align-items: flex-start;
    justify-content: flex-start;
    width: calc(100% + 8px);
    margin-left: -4px;

    > * {
      width: 50%;
      margin-left: 4px;
      margin-right: 4px;
      padding: 4px 8px 6px;
      border-radius: @br;
      background: @color_b6;

      span {
        font: @f_reg var(--caption-micro)/100% @font;
      }

      div {
        font: @f_reg var(--paragraph-2)/120% @font;
      }
    }
  }

  .btn-more {
    width: 100%;
    height: auto;
    display: flex;
    align-content: flex-end;
    align-items: flex-end;
    justify-content: flex-end;
    margin-bottom: 0;

    &.btn_c {
      justify-content: center !important; // align X
      align-items: center !important; // align Y
      align-content: center !important; // align Y ios
    }

    a {
      .btn-more-css;
    }
  }
}

// ---------------------------------- вид строк
.tile-list-lines-type {
  position: relative;

  .tile-list-lines-type__wrap {
    display: flex;
    flex-direction: column;
    margin-left: -@gap / 2;
    margin-right: -@gap / 2;

    @media only screen and (max-width: 1024px), only screen and (max-device-width: 1024px) {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  .tile-list-lines-type__item {
    padding-left: @gap / 2;
    padding-right: @gap / 2;
    display: flex;
    height: auto;
    width: 100%;
    margin-bottom: @gap / 2;

    @media only screen and (max-width: 1024px), only screen and (max-device-width: 1024px) {
      width: 50%;
    }
    @media only screen and (max-width: 680px), only screen and (max-device-width: 680px) {
      width: 100%;
    }

    > div {
      display: flex;
      justify-content: flex-start; // align X
      align-items: flex-start; // align Y
      align-content: flex-start; // align Y ios
      width: 100%;
      padding: @gap;
      flex-direction: row;
      text-decoration: none;
      .block-style-css;

      @media only screen and (max-width: 1024px), only screen and (max-device-width: 1024px) {
        flex-direction: column;
      }
    }

    .tile-list-lines-type__item__prev-img {
      position: relative;
      width: 206px;
      max-width: 206px;
      min-width: 206px;
      height: auto;
      padding-bottom: 0 !important;

      @media only screen and (max-width: 1024px), only screen and (max-device-width: 1024px) {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        margin-bottom: 8px;
      }

      > div {
        position: relative;
        padding-bottom: 56.25%;
        width: 100%;
        overflow: hidden;

        img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          font-family: 'object-fit: cover;';
        }
      }
    }

    .tile-list-lines-type__item__text-content {
      width: 100%;
      padding-left: @gap;
      padding-right: @gap;

      @media only screen and (max-width: 1024px), only screen and (max-device-width: 1024px) {
        padding-left: 0;
        padding-right: 0;
      }

      .tile-list-lines-type__item__title {
        margin-bottom: 8px;

        > * {
          font: @f_sbold var(--headname-5)/120% @font;

          @media only screen and (max-width: 860px), only screen and (max-device-width: 860px) {
            font: @f_sbold var(--headname-6)/120% @font;
          }

        }
      }

      .tile-list-lines-type__item__content {
        width: 100%;
        margin-bottom: 8px;

        > * {
          font: @f_reg var(--paragraph-2)/120% @font;

        }
      }

      .tile-list-lines-type__item__content.limited-x5-lines {
        display: -webkit-box;
        -webkit-line-clamp: 5; // количество строк
        -webkit-box-orient: vertical;
        overflow: hidden;

        @media only screen and (max-width: 1024px), only screen and (max-device-width: 1024px) {
          -webkit-line-clamp: 4; // количество строк
        }
      }
    }

    .tile-list-lines-type__item__price-box {
      display: flex;
      flex-direction: column;
      width: auto;
      justify-content: flex-start; // align X
      align-items: flex-end; // align Y
      align-content: flex-end; // align Y ios

      @media only screen and (max-width: 1024px), only screen and (max-device-width: 1024px) {
        width: 100%;
        justify-content: flex-start; // align X
        align-items: flex-end; // align Y
        align-content: flex-end; // align Y ios
        margin-top: auto;
      }

      .preview-price-description {
        margin-bottom: 8px;

        @media only screen and (max-width: 1024px), only screen and (max-device-width: 1024px) {
          width: 100%;
        }

        &__passengers {
          * {
            white-space: nowrap;
          }
        }

        &__base-rate {
          * {
            white-space: nowrap;
          }
        }
      }

      .price-box__buttons {
        width: 100%;
        display: flex;
        justify-content: flex-end; // align X
        align-items: flex-start; // align Y
        align-content: flex-start; // align Y ios

        > * {
          margin-left: @gap / 2;
        }
      }
    }
  }
}

.distance-list {
  position: relative;

  &__wrap {
    display: flex;
    flex-wrap: wrap;
    margin-left: -@gap / 2;
    margin-right: -@gap / 2;
  }

  &__item {
    padding-left: @gap / 2;
    padding-right: @gap / 2;
    display: flex;
    width: 33.33%;
    margin-bottom: @gap;

    @media only screen and (max-width: 960px), only screen and (max-device-width: 960px) {
      width: 50%;
    }

    @media only screen and (max-width: 520px), only screen and (max-device-width: 520px) {
      width: 100%;
    }

    > a {
      display: flex;
      width: 100%;
      padding: @gap @gap*3 @gap @gap;
      flex-direction: column;
      text-decoration: none;
      color: @color_b1;
      position: relative;
      .block-style-css;

      &::after {
        content: '\f061';
        .fal;
        opacity: 0;
        display: flex;
        font-size: 20px;
        position: absolute;
        z-index: 1;
        top: 50%;
        right: 30px;
        color: @color_b5;
        transform: translateY(-50%);
      }

      &:hover::after {
        opacity: 1;
        right: 16px;
      }

      > * {
        margin-bottom: 8px;
        position: relative;
        z-index: 2;
      }
    }

    &__title {
      > * {
        font: @f_sbold var(--headname-5)/120% @font;

        @media only screen and (max-width: 860), only screen and (max-device-width: 860) {
          font: @f_sbold var(--headname-6)/120% @font;
        }

      }
    }

    &__description {
      display: flex;

      > * {
        margin-right: 8px;

        &::before {
          margin-right: 4px;
          color: @color_1;
          font: @f_sbold var(--paragraph-2)/120% @font;
        }
      }
    }

    &__distance {
      &::before {
        content: '\f545';
        .fal;
      }
    }

    &__time {
      &::before {
        content: '\f2f2';
        .fal;
      }
    }
  }
}

// ------------------------------- Список


// --- 03.08.2023

.terminal-map-preview-wrap {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center; // align X
  align-items: flex-start; // align Y
  align-content: flex-start; // align Y ios
  padding-bottom: 40%;
  margin-bottom: 8px;
  overflow: hidden;

  img {
    top: 0;
    left: 0;
    right: 0;
    bottom: auto;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover;';
    transition: all 0.1s linear;
  }
}

.accordion-terminal-preview {
  &__footer {
    display: flex;
    width: 100%;
    justify-content: space-between; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios
    flex-wrap: wrap;

    margin-top: auto;
    margin-bottom: 0 !important;

    .btn-download-pdf {
      margin-right: 8px;
      order: 3;
    }

    @media only screen and (max-width: 860px), only screen and (max-device-width: 860px) {
      .btn-download-pdf, .btn-more {
        a {
          font-size: var(--caption-micro);
        }
      }
    }


    .btn-more {
      width: auto;
      height: auto;
      display: flex;
      align-content: flex-end;
      align-items: flex-end;
      justify-content: flex-end;
      order: 4;

      &.btn_c {
        justify-content: center !important; // align X
        align-items: center !important; // align Y
        align-content: center !important; // align Y ios
      }

      a {
        .btn-more-css;
      }
    }
  }
}

body:not(.mobile) {
  .accordion-terminal-preview {
    .terminal-map-preview-wrap:hover {
      img {
        transform: scale(1.1);
      }
    }
  }
}

.terminal-levels-slider-container {
  position: relative;
}

.zoom-in-hint {
  position: absolute;
  display: inline-flex;
  width: auto;
  padding: 10px 16px;
  justify-content: center; // align X
  align-items: center; // align Y
  align-content: center; // align Y ios
  border: 1px solid @color_1;
  border-radius: @br;
  z-index: 6;
  pointer-events: none;
  color: @color_1;
  background: rgba(@color_w, 0.6);
  backdrop-filter: blur(3px);
  top: 50%;
  left: 50%;

  animation: zoomInOne 5s infinite ease-in-out;

  &::before {
    content: '\f00e';
    .fas;
    margin-right: 8px;
  }

  &.go-to-interactive-map::before {
    content: '\f1b2' !important;
  }
}


@keyframes zoomInOne {
  0%,
  100% {
    transform: translate(-50%, -50%) scale(0.9);
  }
  50% {
    transform: translate(-50%, -50%) scale(1);
  }
}


.terminal-levels-slider {
  position: relative;
  margin-bottom: @gap*3;

  &__item {
    .img-wrap {
      display: flex;
      position: relative;
      //border: 1px solid red;
      overflow: hidden;
      padding-bottom: 60%;

      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        font-family: 'object-fit: cover;';
      }
    }
  }
}

.terminal-levels-zoom {
  display: block;
  position: fixed;
  z-index: 99999999999;
  background: @color_w;
  width: 100%;
  height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: all 0.2s linear;
  transform: scale(0.5);
  opacity: 0;
  pointer-events: none;

  &.open {
    transform: scale(1);
    opacity: 1;
    pointer-events: all;
  }

  &__top-tool {
    height: 1px;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;

    &::after {
      content: '';
      width: 100%;
      height: 40px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1;
      background: rgba(@color_1, 0);
      pointer-events: none;
    }

    &__close,
    &__hint {
      position: absolute;
      z-index: 3;
    }

    &__close {
      width: auto;
      display: flex;
      justify-content: center; // align X
      align-items: center; // align Y
      align-content: center; // align Y ios
      top: 10px;
      right: 16px;
      font: @f_reg var(--paragraph-1)/120% @font;
      cursor: pointer;
      background: @color_w;
      border-radius: @br;
      padding: 8px 16px;
      border: 1px solid @color_1;
      transition: all 0.1s linear;
      box-shadow: 0 4px 20px -4px rgba(@color_1, 0.2);
    }

    &__hint {
      position: absolute;
      width: 60px;
      height: 60px;
      bottom: 32px;
      left: 50%;
      background: url('@{img}dragged.png') 50% 50% no-repeat;
      background-size: cover !important;
      animation: zoomInHint 2s infinite ease-in-out;
      pointer-events: none;

      span {
        position: absolute;
        left: 50%;
        bottom: -16px;
        font: @f_reg var(--caption-micro)/120% @font;
        white-space: nowrap;
        transform: translateX(-50%);
      }
    }
  }

  &__image {
    width: 100%;
    max-width: 100%;
    height: 100%;
    overflow: hidden;

    &:hover {
      cursor: url('@{img}icn_cursor_hand.png'), auto;
    }

    &:active {
      cursor: url('@{img}icn_cursor_drag.png'), auto !important;
    }

    img {
      width: 2527px;
      height: auto;

      @media only screen and (max-width: 860px), only screen and (max-device-width: 860px) {
        width: 1920px;
      }
    }
  }
}

@keyframes zoomInHint {
  0%,
  100% {
    transform: translateX(-50%) scale(0.9);
  }
  50% {
    transform: translateX(-50%) scale(1);
  }
}

body:not(.mobile) {
  .terminal-levels-zoom {
    &__top-tool {
      &__close:hover {
        transform: translateY(-1px);
        box-shadow: 0 6px 15px -2px rgba(@color_1, 0.3);
      }
    }
  }
}

body.mobile {
  .terminal-levels-zoom {
    &__top-tool {
      &__hint {
        width: 40px;
        height: 40px;
        background: url('@{img}dragged_mobile.png') 50% 50% no-repeat;
      }
    }
  }
}

body:not(.mobile) {
  .table-theme-2 tr td a:hover {
    color: @color_1;
  }
}

.table-theme-2 {
  width: 100%;

  tr {
    border-bottom: 2px solid @color_w;

    td {
      padding-top: 8px;
      padding-bottom: 8px;
      background: @color_b6;
      //border-bottom: 3px solid @color_w;
      position: relative;

      a {
        color: @color_b1;
      }

      &:first-child {
        padding-left: 16px;
        border-radius: @br 0 0 @br;
      }

      &:last-child {
        padding-right: 16px;
        border-radius: 0 @br @br 0;
      }

      &:not(:first-child):not(:last-child) {
        padding-right: 8px;
        padding-left: 8px;
      }
    }
  }

  thead {
    td,
    th {
      font-size: var(--paragraph-1);
      font-weight: @f_sbold;
      background: @color_1;

      color: @color_w;

      * {
        color: @color_w;
      }
    }
  }

  @media only screen and (max-width: 780px), only screen and (max-device-width: 780px) {
    display: flex;
    width: 100%;

    thead {
      display: none !important;
    }

    tbody {
      width: 100%;
    }

    tr {
      display: flex;
      background: @color_b6;
      margin-bottom: 3px;
      flex-wrap: wrap;
      border-radius: @br * 2;
      padding: 8px;

      td {
        background: none;
        border: 0 !important;

        &::before {
          content: attr(data-hd);
          display: block;
          width: 100%;
          font-size: 12px;
          color: @color_b2;
        }

        &:first-child {
          width: 100%;
          padding-left: 8px;
          border-radius: 0;
        }

        &:last-child {
          padding-right: 8px;
          border-radius: 0;
        }

        &:not(:first-child):not(:last-child) {
          padding-right: 8px;
          padding-left: 8px;
        }

        &:nth-child(2) {
          width: 26%;
        }

        &:nth-child(3) {
          width: 40%;
        }

        &:nth-child(4) {
          width: 34%;
        }
      }
    }
  }
}

.terminal-levels-nav {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  border-bottom: 1px solid @color_b5;
  margin-bottom: 8px !important;

  &__item {
    margin-bottom: 8px;

    @media only screen and (max-width: 860px), only screen and (max-device-width: 860px) {
      a {
        font-size: 11px;
      }

    }


    &:not(:last-child) {
      margin-right: 10px;
    }

    &.active {
      a {
        pointer-events: none;
        background: @color_b6 - 10%;
        position: relative;
        border-color: @color_b6 - 10%;
        color: @color_b2;

        &::after {
          content: '';
          position: absolute;
          width: 10px;
          height: 10px;
          background: @color_b6 - 10%;
          transform: translateX(-50%) rotate(-45deg);
          top: -5px;
          left: 50%;
        }
      }
    }

    a {
      .btn-small-size-secondary;

      &::after {
        content: '';
        margin: 0;
      }
    }
  }

}


.accordion.accordion__theme-one {
  .terminal-levels-nav {
    margin-bottom: 0;
    padding-left: 0;
  }
}

body:not(.mobile) {
  .terminal-levels-nav {
    &__item:not(.active) {
      a:hover {
        //background: @color_w;
        //color: @color_1;
      }
    }
  }
}

.bottom-box-buttons {
  min-width: 100% !important;
  display: flex;
  justify-content: center; // align X
  align-items: center; // align Y
  align-content: center; // align Y ios

}


// Airlines preview
.airlines-preview {

  &__wrap {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center; // align X
    align-items: flex-start; // align Y
    align-content: flex-start; // align Y ios
    gap: @gap;
    margin-bottom: @gap;

    @media only screen and (max-width: 680px), only screen and (max-device-width: 680px) {
      flex-wrap: wrap;
    }
  }

  &__item {
    width: calc(100% / 3);
    @media only screen and (max-width: 680px), only screen and (max-device-width: 680px) {
      width: 100%;
    }


    > div {
      display: flex;
      padding: @gap;
      flex-direction: column;
      text-decoration: none;
      justify-content: center; // align X
      align-items: center; // align Y
      align-content: center; // align Y ios
      text-decoration: none;
      color: @color_b1;
      .block-style-css;
    }

    &__image {
      width: 100%;
      overflow: hidden;
      margin-top: @gap;
      padding-bottom: 10%;
      position: relative;
      margin-bottom: 8px;

      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        font-family: 'object-fit: cover;';
      }
    }

    &__name {
      width: 100%;

      > * {

        a {
          display: flex;
          text-align: center;
          justify-content: center; // align X
          align-items: center; // align Y
          align-content: center; // align Y ios
          width: 100%;
          text-decoration: none;
          color: @color_b1;
          font: @f_sbold var(--paragraph-1)/110% @font;

          @media only screen and (max-width: 960px), only screen and (max-device-width: 960px) {
            font-size: var(--paragraph-2);
          }
        }

      }
    }
  }
}

// Destinations preview
.destination-preview {

  &__wrap {
    display: flex;
    flex-wrap: wrap;
    gap: @gap;
    margin-bottom: @gap;
  }

  &__item {
    flex: 1 1 220px;

    > div {
      display: flex;
      padding: @gap;
      border-radius: @br;
      flex-direction: row;
      text-decoration: none;
      justify-content: flex-start; // align X
      align-items: flex-start; // align Y
      align-content: flex-start; // align Y ios
      text-decoration: none;
      color: @color_b1;
      position: relative;
      min-height: 90px;
      .block-style-css;
    }

    &__image {
      width: 100%;
      overflow: hidden;
      padding-bottom: 20%;
      position: relative;
      margin-bottom: 8px;

      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        font-family: 'object-fit: cover;';
      }
    }

    &__name {
      width: 100%;

      > * {
        text-align: left;

        a, > * {
          display: flex;
          position: relative;
          text-decoration: none;
          color: @color_b1;
          font: @f_sbold var(--paragraph-1)/110% @font;
          padding-right: @gap*2;

          &::after {
            position: absolute;
            content: '\f178';
            display: flex;
            justify-content: center; // align X
            align-items: center; // align Y
            align-content: center; // align Y ios
            .fal;
            right: -4px;
            top: 10px;
            transform: translateY(-50%);
            color: @color_b5;
            width: 24px;
            min-width: 24px;
            height: 24px;
          }

          &::before {
            width: 24px;
            min-width: 24px;
            height: 24px;
            margin-right: 8px;
            position: relative;
            content: '\f5b0';
            .fas;
            color: @color_1;
          }

          @media only screen and (max-width: 960px), only screen and (max-device-width: 960px) {
            font-size: var(--paragraph-2);
          }
        }
      }
    }
  }
}

// Airlines
.airlines-list {
  &__item {
    width: 100%;
    margin-bottom: @gap/2;

    > div {
      display: flex;
      padding: @gap;
      flex-direction: row;
      text-decoration: none;
      justify-content: flex-start; // align X
      align-items: flex-start; // align Y
      align-content: flex-start; // align Y ios
      text-decoration: none;
      color: @color_b1;
      position: relative;
      flex-wrap: nowrap;
      .block-style-css;

      @media only screen and (max-width: 580px), only screen and (max-device-width: 580px) {
        flex-direction: column;
        justify-content: center; // align X
        align-items: center; // align Y
        align-content: center; // align Y ios
      }

    }

    &__image {
      margin-bottom: 8px;
      margin-right: @gap;
      flex: 0 0 110px;

      @media only screen and (max-width: 580px), only screen and (max-device-width: 580px) {
        flex: 1 1 auto;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        max-width: 280px;
      }

      .img-wrap {
        display: flex;
        width: 100%;
        padding-bottom: 34%;
        overflow: hidden;
        position: relative;
        transition: all 0.23s linear;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        font-family: 'object-fit: cover;';
      }
    }

    &__description {
      flex: 1 1 auto;

      @media only screen and (max-width: 580px), only screen and (max-device-width: 580px) {
        h3 {
          text-align: center;
          margin-bottom: @gap;
        }
      }


      > h3 {
        text-align: left;

        a {
          text-decoration: none;
          font: @f_sbold var(--paragraph-1)/110% @font;
          color: @color_b1;
          padding: 4px 0;
        }
      }

      p {
        font: @f_reg var(--paragraph-2)/110% @font;
      }
    }

    &__soc-links {
      display: flex;

      @media only screen and (max-width: 580px), only screen and (max-device-width: 580px) {
        margin-top: @gap;

      }


      a {
        display: flex;
        justify-content: center; // align X
        align-items: center; // align Y
        align-content: center; // align Y ios

        &:not(:first-child) {
          margin-left: @gap /2;
        }

        svg {
          width: 32px;
          height: 32px;
          fill: @color_1;
        }
      }
    }
  }
}

//  Destinations
.destination-list {
  &__item {
    width: 100%;
    margin-bottom: @gap/2;

    > a, > div {
      .block-style-css;
      display: flex;
      padding: @gap;
      flex-direction: row;
      text-decoration: none;
      justify-content: flex-start; // align X
      align-items: flex-start; // align Y
      align-content: flex-start; // align Y ios
      text-decoration: none;
      color: @color_b1;
      position: relative;
      flex-wrap: nowrap;
    }

    &__icon {
      margin-right: @gap;

      @media only screen and (max-width: 580px), only screen and (max-device-width: 580px) {
        margin-right: @gap / 2;
      }

      svg {
        width: 24px;
        min-width: 24px;
        height: 24px;
        fill: @color_1;

        @media only screen and (max-width: 580px), only screen and (max-device-width: 580px) {
          width: 18px;
          min-width: 18px;
          height: 18px;
        }

      }
    }

    &__description {
      flex: 1 1 auto;
      display: flex;

      @media only screen and (max-width: 580px), only screen and (max-device-width: 580px) {
        flex-direction: column;
      }


      > * {
        width: 50%;

        @media only screen and (max-width: 580px), only screen and (max-device-width: 580px) {
          width: 100%;

          &:not(:last-child) {
            margin-bottom: @gap/2;
          }
        }

        h2, h3 {
          font: @f_sbold var(--paragraph-1)/110% @font;

          @media only screen and (max-width: 580px), only screen and (max-device-width: 580px) {
            font: @f_sbold var(--paragraph-2)/110% @font;
          }
        }

        sup {
          font-size: var(--paragraph-1);
          color: @color_b2;
          top: 0;
          line-height: 105%;
          display: flex;
        }

        margin-right: @gap;
      }


    }

    &__more-link {
      display: flex;
      white-space: nowrap;
      position: relative;
      padding: 6px 16px 6px @gap;
      font-size: var(--caption);
      text-decoration: none;
      background: @color_1;
      color: @color_w;
      justify-content: center; // align X
      align-items: center; // align Y
      align-content: center; // align Y ios
      border-radius: @br;
      border: 1px solid @color_1;

      &::after {
        content: '\f178';
        display: flex;
        justify-content: center; // align X
        align-items: center; // align Y
        align-content: center; // align Y ios
        .fal;
        color: @color_w;
        width: 16px;
        min-width: 16px;
        height: 16px;
        margin-left: 3px;
      }
    }

  }
}


body:not(.mobile) {
  .airlines-preview__item, .destination-preview__item, .destination-list__item {
    a:hover {
      //border: 1px solid @color_1;
      //box-shadow: 0 10px 33px -5px rgba(@color_b1, 0.2);
      color: @color_1;

      &::after {
        right: -10px;
      }
    }
  }

  .airlines-list__item {
    &__image {
      a:hover {
        transform: scale(1.02);
      }
    }

    &__description {
      h3 {
        a:hover {
          color: @color_1;
        }
      }
    }

    &__soc-links {
      a:hover {
        svg {
          fill: @color_2;
        }
      }
    }
  }

  .destination-list__item__more-link:hover {
    background: @color_w;
    color: @color_1;

    &::after {
      color: @color_1;
    }

  }


}


.icn-sn {
  position: relative;
  top: 3px;
}


.where-are-you-heading-to {
  display: flex;
  border-radius: @br;
  background: @color_1;
  color: @color_w;
  flex-direction: column;
  padding: 24px;
  margin-bottom: @gap;

  &__title {
    margin-bottom: @gap;

    h2 {
      font: @f_bold var(--headname-2)/110% @font;

    }
  }

  &__search-container {
    display: flex;
    gap: @gap / 2;
    flex-wrap: wrap;


    .links {
      display: flex;
      gap: @gap / 2;
      margin-bottom: @gap;

      @media only screen and (max-width: 1200px), only screen and (max-device-width: 1200px) {
        width: 100%;
        margin-bottom: @gap;
        flex-wrap: wrap;
      }


      a {
        display: flex;
        justify-content: flex-start; // align X
        align-items: center; // align Y
        align-content: center; // align Y ios
        gap: 8px;
        text-decoration: none;
        border-radius: @br;
        padding: 8px @gap;
        min-height: 44px;
        background: @color_w;
        color: @color_b1;

        @media only screen and (max-width: 1200px), only screen and (max-device-width: 1200px) {
          flex: 1 1 220px;
        }

        &::before {
          content: url('data:image/svg+xml;charset=utf-8,<svg width="19" height="24" viewBox="0 0 19 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.5556 23.0886C10.1333 24.3038 9.28889 24.3038 8.86667 23.0886C7.73194 18.8449 0 16.6076 0 9.11392C0.0659722 6.51899 0.989583 4.36709 2.77083 2.65823C4.55208 0.949367 6.79514 0.0632911 9.5 0C12.2049 0.0632911 14.4479 0.949367 16.2292 2.65823C18.0104 4.36709 18.934 6.51899 19 9.11392C19 16.6076 11.8222 19.038 10.5556 23.0886ZM9.5 12.1519C10.3906 12.1203 11.1328 11.8196 11.7266 11.25C12.3203 10.6804 12.6337 9.96835 12.6667 9.11392C12.6337 8.25949 12.3203 7.54747 11.7266 6.97785C11.1328 6.40823 10.3906 6.10759 9.5 6.07595C8.60938 6.10759 7.86719 6.40823 7.27344 6.97785C6.67969 7.54747 6.36632 8.25949 6.33333 9.11392C6.36632 9.96835 6.67969 10.6804 7.27344 11.25C7.86719 11.8196 8.60938 12.1203 9.5 12.1519Z" fill="rgb(51, 122, 183)"/></svg>');
          display: flex;
          height: 24px;
        }

        &::after {
          content: url('data:image/svg+xml;charset=utf-8,<svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 4.5L11 9L6 13.5" stroke="rgb(51, 122, 183)" stroke-width="2" stroke-linecap="round"/></svg>');
          display: flex;
          height: 18px;
        }
      }

      p {
        display: inline-flex;
        width: 100%;
        white-space: nowrap;
      }
    }

    .search-city-form {
      flex: 1 1 300px;

      input:focus:placeholder-shown ~ label.label_bottom,
      input:not(:placeholder-shown) ~ label.label_bottom {
        color: @color_w !important;
        padding-left: 16px;
      }

      .search_box {
        input#search-city {
          padding-left: 45px !important;
          padding-right: 16px !important;
        }

        .label_bottom {
          padding-left: 45px;
        }

      }


      button {
        transform: scaleX(-1) translateY(-50%);
        right: auto;
        left: 0px;
        pointer-events: none;
        min-width: 0 !important;
      }
    }
  }
}

.list-your-service {
  display: flex;
  gap: @gap;

  @media only screen and (max-width: 1024px), only screen and (max-device-width: 1024px) {
    flex-direction: column;
  }


  &__illustration {
    flex: 1 0 370px;

    @media only screen and (max-width: 1024px), only screen and (max-device-width: 1024px) {
      flex: 1 1 auto;
    }


    svg {
      width: 100%;
      max-height: 163px;
    }
  }

  &__content {
    p {
      line-height: 135%;
    }

  }
}

.link-in-text {
  display: inline-flex;
  min-height: 20px;
  border: 1px solid @color_1;
  color: @color_1;
  justify-content: center; // align X
  align-items: center; // align Y
  align-content: center; // align Y ios
  border-radius: @br;
  text-decoration: none;
  padding: 2px 10px;
}

body:not(.mobile) {
  .link-in-text:hover {
    background: @color_2;
    color: @color_w;

  }
}


// ---------------------- ONE BUSINESS --------------------
.one-business-sub-line {
  display: flex;
  justify-content: space-between; // align X
  align-items: center; // align Y
  align-content: center; // align Y ios
  margin-bottom: 16px;

  @media only screen and (max-width: 480px), only screen and (max-device-width: 480px) {
    flex-wrap: wrap;
    gap: @gap;
    justify-content: flex-start; // align X
  }


  > * {
    display: flex;
    gap: 8px;
    justify-content: flex-start; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios
    margin-bottom: -8px;
  }

  &__rating {
    font-size: 18px;

    @media only screen and (max-width: 860px), only screen and (max-device-width: 860px) {
      font-size: 15px;

    }


    &__number {
      display: none;
    }

    &__stars {
      display: flex;
      justify-content: flex-start; // align X
      align-items: center; // align Y
      align-content: center; // align Y ios

      .stars-svg {
        width: 100px;
        min-width: 100px;
        height: calc(100px / 5.9);
        position: relative;
        top: -1px;

        .stars-mask {
          fill: blanchedalmond;
        }


        .stars-scale {
          transform: translateX(-100%);
          fill: @color_3;
        }

        .stars-border {
          stroke: @color_3;
        }
      }
    }

    &__reviews {
      color: @color_b3;

    }

    &__price {
      padding-left: 8px;
      border-left: 1px solid @color_b5;
    }
  }

  &__work-time {
    font-size: var(--paragraph-2);

    @media only screen and (max-width: 860px), only screen and (max-device-width: 860px) {
      font-size: 15px;

    }


    &__status {
      color: @color_5;
      padding-right: 8px;
      border-right: 1px solid @color_b5;

      &.closed {
        color: @color_4;
      }
    }

    &__icon {
      display: flex;
      justify-content: center; // align X
      align-items: center; // align Y
      align-content: center; // align Y ios
      position: relative;
      top: -1px;

      svg {
        width: 18px;
        height: 18px;
        fill: @color_1;
      }
    }

  }


}

.business-reviews {
  display: flex;
  gap: @gap / 2;
  margin-bottom: @gap *2;
  flex-wrap: wrap;

  &__item {
    padding: @gap;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: 100%;
    gap: @gap/3;
    .block-style-css;

    .author {
      font: @f_sbold var(--paragraph-1)/120% @font;
    }

    time {
      display: block;
      width: 100%;
      font-size: 12px;
      color: @color_b2;
    }

    p {
      //display: -webkit-box; /* Включаємо флекс-контейнер */
      //-webkit-box-orient: vertical; /* Орієнтація контейнера вертикальна */
      //-webkit-line-clamp: 4; /* Обмежуємо текст 4-ма рядками */
      //overflow: hidden; /* Приховуємо те, що виходить за межі контейнера */
      //text-overflow: ellipsis; /* Додаємо три крапки ("...") */
      //line-height: 1.5; /* Встановлюємо висоту рядка, за бажанням */
      //max-height: calc(1.5em * 4); /* Контролюємо висоту для 4 рядків */
    }
  }

}

.copy-btn {
  display: flex;
  padding: 8px;
  cursor: pointer;

  &:active {
    transform: translateY(1px);
  }

  .alert {
    display: flex;
    position: fixed;
    padding: 12px 22px;
    border-radius: @br;
    background: rgba(@color_1, 0.7);
    color: @color_w;
    backdrop-filter: blur(3px);
    left: 50%;
    bottom: 100px;
    transform: translate(0, -50%);
    z-index: 99999;
    pointer-events: none;
    transition: all 0.2s linear;

    opacity: 0;
    visibility: hidden;

  }

  &.copied .alert {
    opacity: 1;
    visibility: visible;
  }

  svg {
    width: 24px;
    height: 24px;
    fill: @color_1;
  }
}


.price-prev-top {
  margin-left: auto;
  display: flex;

  span, b {
    font-size: var(--paragraph-2);
  }

  b {
    font-weight: @f_hbold;
  }
}

// ----------- Список бізнесов в терміналі
.terminal-business-list {
  display: flex;
  flex-direction: column;
  margin-bottom: @gap * 2;
  gap: @gap / 2;

  > * {
    display: flex;


    a {
      display: flex;
      padding: 2px 0;
      font: @f_reg 16px/120% @font;
      color: @color_b1;
      gap: @gap;
      min-height: 30px;

      .business-marker {
        display: block;
        width: 30px;
        height: 30px;
        max-width: 30px;
        max-height: 30px;
        position: relative;
        top: -2px;
        transition: all 0.2s linear;

        img {
          width: inherit;
          height: inherit;
        }
      }

      &:hover {
        color: @color_2;

        .business-marker {
          transform: scale(1.08);

        }
      }
    }

    p {
      padding: 0;
      margin: 0;
    }
  }
}


// Навігація по терміналам та поверхам
.airport-map-navigation-table {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: @gap*2;
  gap: @gap*2;

  &__item {
    flex: 0 1 calc(33.33% - @gap*2);
    gap: @gap*2;

    &__terminal {
      font-size: var(--headname-4);
      font-weight: @f_sbold;
    }

    &__nav {
      display: flex;
      flex-direction: column;
      column-gap: @gap / 2;

      a{
        display: flex;
        justify-content: flex-start; // align X
        align-items: flex-start; // align Y
        align-content: flex-start; // align Y ios
        padding: 8px 0;
        font-size: 14px;
        gap: @gap / 2;

        &::after{
          content: url('data:image/svg+xml;charset=utf-8,<svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.66016 9.15918C9.53255 9.0498 9.46875 8.90397 9.46875 8.72168C9.46875 8.53939 9.53255 8.38444 9.66016 8.25684L12.2578 5.65918H1.15625C0.755208 5.62272 0.536458 5.39486 0.5 4.97559C0.536458 4.59277 0.755208 4.38314 1.15625 4.34668H12.2578L9.66016 1.74902C9.40495 1.43913 9.40495 1.12923 9.66016 0.819336C9.97005 0.564128 10.2799 0.564128 10.5898 0.819336L14.3086 4.53809C14.5638 4.84798 14.5638 5.15788 14.3086 5.46777L10.5898 9.18652C10.2799 9.44173 9.97005 9.43262 9.66016 9.15918Z" fill="rgb(192, 199, 203)"/></svg>');

        }
      }
    }
  }
}


.filters-block{
  display: inline-flex;
  gap: @gap;
}


