//============================================================================
//+++++++++++++++++++++++++++++++++ HEADER +++++++++++++++++++++++++++++++++++
//============================================================================

.header-fixed .notice-popup__covid,
.header.notice-closed .notice-popup__covid {
  //transform: scaleY(0);
  opacity: 0;
  visibility: hidden;
  max-height: 0;
  padding: 0 80px;
}

[class*='notice-popup_'] {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: auto;
  padding: @gap/2 @gap*2 @gap/2 @gap;
  max-height: 800px;
  background: @color_3;
  color: @color_w;
  z-index: 99;
  font: @f_reg var(--caption) /140% @font;
  //box-shadow: 0 10px 30px -5px rgba(@color_b1, .3);
  .anim_on(@speed);

  .close-notice-btn {
    display: block;
    width: 36px;
    height: 36px;
    position: absolute;
    top: 7px;
    right: 0;
    align-items: center;
    align-content: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }

    &::after {
      content: @fa-var-times;
      .fas;
      color: @color_w;
    }
  }

  .notice__content {
    display: flex;
    max-height: 800px;
    overflow: hidden;
    .anim_on(@speed);

    a {
      color: @color_w;
    }
  }

  .btn-slide-notice {
    position: absolute;
    width: 100%;
    height: 36px;
    display: none;
    background: linear-gradient(0, rgba(@color_3, 1) 0%, rgba(@color_3, .98) 28%, rgba(@color_3, 0) 100%);
    left: 0;
    bottom: 0;
    justify-content: center;
    align-content: flex-end;
    align-items: flex-end;

    > span {
      display: none;
      padding: 2px 10px;
      user-select: none;
      background: rgba(@color_w, .6);
      color: @color_3;
      border-radius: @br @br 0 0;
      .anim_on(@speed);

      &::after {
        .fas;
      }

      &:first-child::after {
        content: @fa-var-caret-down;
      }

      &:last-child::after {
        content: @fa-var-caret-up;
      }

      &:hover {
        cursor: pointer;
        background: rgba(@color_w, 1);
      }
    }

  }

  @media only screen and (max-width: 780px), only screen and (max-device-width: 780px) {
    font-size: 13px;

    &:not(.deployed) {
      > .notice__content {
        padding: 10px 16px 0;
        max-height: 75px;
      }

      .btn-slide-notice {
        span:first-child {
          display: block;
        }
      }
    }

    &.deployed {
      > .notice__content {
        padding: 10px 16px 40px;
      }

      .btn-slide-notice {
        span:last-child {
          display: block;
        }
      }
    }

    .btn-slide-notice {
      display: flex;
    }

  }
}

// BIG HEADER
// ====================================

// LOGO

.svg-logo {
  shape-rendering: geometricPrecision;
  text-rendering: geometricPrecision;
  image-rendering: optimizeQuality;
  fill-rule: evenodd;
  clip-rule: evenodd;

  &__p1 {
    fill: @color_1;
  }

  &__p2 {
    fill: @color_b1;
  }
}

// MOBILE HEADER
// ====================================


// SMALL HEADRER
// ====================================
.header.small-header {
  .resolution-wrap {
    height: var(--header-m-height);
  }
}

.header.mobile-header ~ .footer {
  // padding-bottom: 82px; // if bottom toolbar on
}

.header {
  @media (min-width: 960px) and (max-width: 2480px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    z-index: 90;
    width: var(--app-width);
    height: auto;
    top: 0;
    left: 0;
    background: @color_w;

    .logo {
      display: block;
      width: 60px;
      height: 60px;
      margin-right: auto;

      a {
        display: flex;
        width: 60px;
        height: 60px;
        align-content: center;
        align-items: center;
        justify-content: center;
      }

      img {
        width: 100%;
        height: 100%;
      }

      svg {
        width: 60px;
        height: 60px;
      }

    }

    //.box-shadow;
    border-bottom: 1px solid @color_b5;

    > .resolution-wrap {
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: flex-start;
      height:  var(--header-d-height);
    }

    .menu-dashboard-btn {
      display: none;
    }

    .search-btn {
      display: none;
    }

    .menu-burger {
      display: none;
    }

    .header-search {
      //border: 1px solid red;
      height: 38px;
      margin-right: auto;
      width: 100%;
      padding-left: 16px;
      padding-right: 16px;

      &__form {
        position: relative;
        z-index: 1;
        margin-bottom: @gap;

        .search_box {
          height: 36px;
          max-width: 640px;

          .label_bottom {
            height: 36px;
          }
        }

        input, textarea, select {
          height: 36px !important;
          width: 100%;
          -webkit-appearance: none !important;
          -moz-appearance: none !important;
          appearance: none !important;

          &:focus:placeholder-shown,
          &:not(:placeholder-shown) {
            & ~ label.label_bottom {
              color: rgba(@color_b1, 0) !important;
            }
          }
        }
      }
    }

    .menu {
      display: flex;
    }

    .menu-wrap {
      display: flex;
      align-items: center;
      align-content: center;
    }

    .menu__item {
      margin-left: @gap*2;
      position: relative;
      padding-top: 6px;
      padding-bottom: 6px;

      &.active {
        > a::before, > .menu__sub-h::before {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          bottom: -6px;
          width: 100%;
          height: 2px;
          background: @color_1;
        }
      }

      > a, > .menu__sub-h {
        display: flex;
        min-height: 36px;
        align-content: center;
        align-items: center;
        white-space: nowrap;
        text-decoration: none;
        font-size: 14px;
        color: @color_b1;
        transition: all .2s linear;

        &:hover {
          color: @color_1;
          cursor: pointer;
          user-select: auto;
        }

        > * {
          margin: auto 2px !important;
        }
      }

      > .menu__sub-h {
        &::after {
          content: @fa-var-angle-down;
          .fas;
          color: @color_b4;
          margin-left: 5px;
          transition: all .2s ease-out;
        }
      }

      .menu__sub {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        align-content: stretch;
        top: 48px + 6px;
        left: -15px;
        position: absolute;
        opacity: 0;
        visibility: hidden;
        transition: all .2s ease-out;
        background: @color_b6;
        transform-origin: 0 0 !important;
        transform: translateY(-10px) scaleY(0);
        min-width: 180px;

        &::before {
          content: '';
          display: block;
          width: 8px;
          height: 8px;
          transform: rotate(-45deg);
          background: @color_b6;
          position: absolute;
          z-index: -1;
          left: 10px;
          top: -4px;
        }

        > a {
          display: flex;
          padding: 13px 15px;
          text-decoration: none;
          color: @color_b1;
          font-size: 15px;

          &:hover {
            background: @color_b6 - 10%;
          }
        }
      }

      &:hover {
        > .menu__sub-h {
          &::after {
            transform: scaleY(-1);
          }
        }

        .menu__sub {
          opacity: 1;
          visibility: visible;
          transform: translateY(0) scaleY(1);
        }
      }

      .menu-mobile-icon {
        display: none;
      }

      // login btn
      &.menu__action-btn a {
        background: @color_1;
        color: @color_w;
        border-radius: @br;
        padding: 0 20px;
        transition: all .2s linear;

        &:hover {
          background: @color_2;
          box-shadow: @primary-btn-hover;
        }
      }
    }

    &.header__small {
      .resolution-wrap {
        height: var(--header-m-height);
      }

      .logo {
        margin: auto;
        width: 40px;
        height: 40px;
        position: relative;
        z-index: 3;

        a {
          display: flex;
          align-content: center;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;

          svg {
            svg {
              width: 40px;
              height: 40px;
            }
          }
        }
      }
    }
  }
  @media (min-width: 280px) and (max-width: 959.98px) {
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background: @color_w;
    z-index: 90;

    //.box-shadow;
    border-bottom: 1px solid @color_b5;

    .resolution-wrap {
      display: flex;
      height: var(--header-m-height);
      position: relative;
      max-width: none;
    }

    .menu-burger {
      display: none;
      width: 46px;
      height: 46px;
      position: absolute;
      left: 10px;
      top: 50%;
      z-index: 3;
      transform: translateY(-50%);

      &:hover {
        cursor: pointer;
      }

      &::before,
      i,
      &::after {
        content: '';
        display: block;
        width: 60%;
        height: 2px;
        background: @color_1;
        position: absolute;
        transform-origin: 0 50%;
        left: 50%;
        transform: translateX(-50%);
        transition: all .2s ease-out;
      }

      &::before {
        top: 30%;
      }

      i {
        top: 50%;
      }

      &::after {
        top: 70%;
      }
    }

    .logo {
      margin: auto;
      width: 40px;
      height: 40px;
      position: relative;
      z-index: 3;

      a {
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }

    .menu {
      width: 50px;
      position: fixed;
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      top: 0;
      //background: @color_w;
      right: 55px;
      z-index: 2;
      transition: all .3s ease-out;

      .menu-wrap {
        width: 50px;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        //background: @color_1;
        position: relative;
        z-index: 2;
        height: 50px;
        //box-shadow: inset 0 1px 0 0 rgba(255,255,255, .095), 0 -4px 20px -4px rgba(@color_b1, .1);

        .menu__item {
          display: flex;
          position: relative;
          flex-direction: column;
          width: 25%;
          height: 50px;
          align-content: center;
          align-items: center;
          justify-content: center;

          &.active {
            > a::before,
            > .menu__sub-h::before {
              content: '';
              display: block;
              position: absolute;
              right: 0;
              top: 0;
              width: 2px;
              height: 100%;
              background: @color_1;
            }
          }

          &:not(:last-child) {
            &::after {
              content: '';
              position: absolute;
              bottom: 0;
              right: 0;
              display: block;
              width: 1px;
              height: 100%;
              background: rgba(@color_b1, .1);
              z-index: 1;
            }
          }

          > a, .menu__sub-h {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-content: center;
            align-items: center;
            justify-content: center;
            position: relative;
            z-index: 2;
            text-align: left;
            //padding-left: 20px;
            //padding-right: 20px;
            text-decoration: none;
            min-height: 66px;
            color: @color_w;

            &.active {
              &::after {
                content: '';
                display: block;
                position: absolute;
                right: 0;
                top: 0;
                width: 2px;
                height: 100%;
                background: @color_1;
              }
            }

            > div {
              display: none;
              font-size: 13px;
              line-height: 110%;
            }
          }

          .menu__sub {
            display: flex;
            position: relative;
            flex-direction: column;
            background: @color_b1 + 5%;

            > a {
              display: flex;
              text-decoration: none;
              color: @color_b2;
              font-size: 13px;
              text-align: left;
              padding: 10px 13px 10px 22px;
            }
          }

          &.menu__action-btn {

            a::before {
              // content: '\f007';
              .fal;
              color: @color_1;
              margin-right: 10px;
            }
          }

          .menu-mobile-icon {
            display: flex;
            width: 36px;
            height: 36px;
            align-content: center;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            color: @color_1;
          }

          .menu-desktop-icon {
            display: none;
          }
        }
      }
    }

    .menu-dashboard-btn {
      position: absolute;
      left: 8px;
      width: 46px;
      height: 46px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      z-index: 2;

      &::after {
        content: '\f0c9';
        .far;
        color: @color_1;
        font-size: 22px;
        transition: all .2s linear;
      }

      &.active {
        &::after {
          content: '\f060';
        }
      }
    }

    &.menu-active {
      .resolution-wrap {
        .menu-burger {
          &::before {
            top: 50%;
            left: 20%;
            transform: rotate(-40deg);
            width: 20px !important;
          }

          i {
            left: 70%;
            opacity: 0;
          }

          &::after {
            top: 50%;
            left: 20%;
            transform: rotate(40deg);
            width: 20px !important;
          }
        }

        .menu {
          transform: translateX(0);
          opacity: 1;
          visibility: visible;
        }
      }
    }

    .search-btn {
      position: absolute;
      right: 8px;
      width: 46px;
      height: 46px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;


      &::after {
        content: '\f002';
        .far;
        color: @color_1;
        font-size: 18px;
        transition: all .2s linear;
      }
    }


    .header-search {
      position: absolute;
      top: 0;
      left: -20%;
      background: @color_w;
      opacity: 0;
      visibility: hidden;
      z-index: 5;
      width: calc(100% - 50px);
      height: 50px;
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      padding-left: 16px;
      padding-right: 16px;
      transition: all .2s linear;


      &__form {
        position: relative;
        z-index: 1;
        flex: 1 1 auto;

        .search_box {
          height: 36px;
          max-width: 640px;

          .label_bottom {
            height: 36px;
          }
        }

        input, textarea, select {
          height: 36px !important;
          width: 100%;

          &:focus:placeholder-shown,
          &:not(:placeholder-shown) {
            & ~ label.label_bottom {
              color: rgba(@color_b1, 0) !important;
            }
          }
        }
      }
    }

    &.search-active {
      .search-btn {
        &::after {
          content: '\f00d';
        }
      }

      .header-search {
        left: 0;
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.dynamic-padding {
  @media (min-width: 960px) and (max-width: 2480px) {
    padding-top: var(--header-d-height);
  }
  @media (min-width: 280px) and (max-width: 959.98px) {
    padding-top: var(--header-m-height);
  }
}

