/*!
 * Font Awesome Pro 5.12.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@import "_variables.less";

@font-face {
  font-family: 'Font Awesome 5 Duotone';
  font-style: normal;
  font-weight: 900;
  font-display: @fa-font-display;
  src: url('@{fa-font-path}/fa-duotone-900.eot');
  src: url('@{fa-font-path}/fa-duotone-900.eot?#iefix') format('embedded-opentype'),
    url('@{fa-font-path}/fa-duotone-900.woff2') format('woff2'),
    url('@{fa-font-path}/fa-duotone-900.woff') format('woff'),
    url('@{fa-font-path}/fa-duotone-900.ttf') format('truetype'),
    url('@{fa-font-path}/fa-duotone-900.svg#fontawesome') format('svg');
}

.fad {
  position: relative;
  font-family: 'Font Awesome 5 Duotone';
  font-weight: 900;
}

.fad:before {
  position: absolute;
  color: ~'var(--@{fa-css-prefix}-primary-color, inherit)';
  opacity: @fa-primary-opacity;
  opacity: ~'var(--@{fa-css-prefix}-primary-opacity, @{fa-primary-opacity})';
}

.fad:after {
  color: ~'var(--@{fa-css-prefix}-secondary-color, inherit)';
  opacity: @fa-secondary-opacity;
  opacity: ~'var(--@{fa-css-prefix}-secondary-opacity, @{fa-secondary-opacity})';
}

.@{fa-css-prefix}-swap-opacity .fad:before,
.fad.@{fa-css-prefix}-swap-opacity:before {
  opacity: @fa-secondary-opacity;
  opacity: ~'var(--@{fa-css-prefix}-secondary-opacity, @{fa-secondary-opacity})';
}

.@{fa-css-prefix}-swap-opacity .fad:after,
.fad.@{fa-css-prefix}-swap-opacity:after {
  opacity: @fa-primary-opacity;
  opacity: ~'var(--@{fa-css-prefix}-primary-opacity, @{fa-primary-opacity})';
}

.fad.@{fa-css-prefix}-inverse {
  color: @fa-inverse;
}

.fad.@{fa-css-prefix}-stack-1x, .fad.@{fa-css-prefix}-stack-2x {
  position: absolute;
}

.fad.@{fa-css-prefix}-stack-1x:before,
.fad.@{fa-css-prefix}-stack-2x:before,
.fad.@{fa-css-prefix}-fw:before {
  left: 50%;
  transform: translateX(-50%);
}

.fad.@{fa-css-prefix}-abacus:after { content: "\10f640"; }
.fad.@{fa-css-prefix}-acorn:after { content: "\10f6ae"; }
.fad.@{fa-css-prefix}-ad:after { content: "\10f641"; }
.fad.@{fa-css-prefix}-address-book:after { content: "\10f2b9"; }
.fad.@{fa-css-prefix}-address-card:after { content: "\10f2bb"; }
.fad.@{fa-css-prefix}-adjust:after { content: "\10f042"; }
.fad.@{fa-css-prefix}-air-conditioner:after { content: "\10f8f4"; }
.fad.@{fa-css-prefix}-air-freshener:after { content: "\10f5d0"; }
.fad.@{fa-css-prefix}-alarm-clock:after { content: "\10f34e"; }
.fad.@{fa-css-prefix}-alarm-exclamation:after { content: "\10f843"; }
.fad.@{fa-css-prefix}-alarm-plus:after { content: "\10f844"; }
.fad.@{fa-css-prefix}-alarm-snooze:after { content: "\10f845"; }
.fad.@{fa-css-prefix}-album:after { content: "\10f89f"; }
.fad.@{fa-css-prefix}-album-collection:after { content: "\10f8a0"; }
.fad.@{fa-css-prefix}-alicorn:after { content: "\10f6b0"; }
.fad.@{fa-css-prefix}-alien:after { content: "\10f8f5"; }
.fad.@{fa-css-prefix}-alien-monster:after { content: "\10f8f6"; }
.fad.@{fa-css-prefix}-align-center:after { content: "\10f037"; }
.fad.@{fa-css-prefix}-align-justify:after { content: "\10f039"; }
.fad.@{fa-css-prefix}-align-left:after { content: "\10f036"; }
.fad.@{fa-css-prefix}-align-right:after { content: "\10f038"; }
.fad.@{fa-css-prefix}-align-slash:after { content: "\10f846"; }
.fad.@{fa-css-prefix}-allergies:after { content: "\10f461"; }
.fad.@{fa-css-prefix}-ambulance:after { content: "\10f0f9"; }
.fad.@{fa-css-prefix}-american-sign-language-interpreting:after { content: "\10f2a3"; }
.fad.@{fa-css-prefix}-amp-guitar:after { content: "\10f8a1"; }
.fad.@{fa-css-prefix}-analytics:after { content: "\10f643"; }
.fad.@{fa-css-prefix}-anchor:after { content: "\10f13d"; }
.fad.@{fa-css-prefix}-angel:after { content: "\10f779"; }
.fad.@{fa-css-prefix}-angle-double-down:after { content: "\10f103"; }
.fad.@{fa-css-prefix}-angle-double-left:after { content: "\10f100"; }
.fad.@{fa-css-prefix}-angle-double-right:after { content: "\10f101"; }
.fad.@{fa-css-prefix}-angle-double-up:after { content: "\10f102"; }
.fad.@{fa-css-prefix}-angle-down:after { content: "\10f107"; }
.fad.@{fa-css-prefix}-angle-left:after { content: "\10f104"; }
.fad.@{fa-css-prefix}-angle-right:after { content: "\10f105"; }
.fad.@{fa-css-prefix}-angle-up:after { content: "\10f106"; }
.fad.@{fa-css-prefix}-angry:after { content: "\10f556"; }
.fad.@{fa-css-prefix}-ankh:after { content: "\10f644"; }
.fad.@{fa-css-prefix}-apple-alt:after { content: "\10f5d1"; }
.fad.@{fa-css-prefix}-apple-crate:after { content: "\10f6b1"; }
.fad.@{fa-css-prefix}-archive:after { content: "\10f187"; }
.fad.@{fa-css-prefix}-archway:after { content: "\10f557"; }
.fad.@{fa-css-prefix}-arrow-alt-circle-down:after { content: "\10f358"; }
.fad.@{fa-css-prefix}-arrow-alt-circle-left:after { content: "\10f359"; }
.fad.@{fa-css-prefix}-arrow-alt-circle-right:after { content: "\10f35a"; }
.fad.@{fa-css-prefix}-arrow-alt-circle-up:after { content: "\10f35b"; }
.fad.@{fa-css-prefix}-arrow-alt-down:after { content: "\10f354"; }
.fad.@{fa-css-prefix}-arrow-alt-from-bottom:after { content: "\10f346"; }
.fad.@{fa-css-prefix}-arrow-alt-from-left:after { content: "\10f347"; }
.fad.@{fa-css-prefix}-arrow-alt-from-right:after { content: "\10f348"; }
.fad.@{fa-css-prefix}-arrow-alt-from-top:after { content: "\10f349"; }
.fad.@{fa-css-prefix}-arrow-alt-left:after { content: "\10f355"; }
.fad.@{fa-css-prefix}-arrow-alt-right:after { content: "\10f356"; }
.fad.@{fa-css-prefix}-arrow-alt-square-down:after { content: "\10f350"; }
.fad.@{fa-css-prefix}-arrow-alt-square-left:after { content: "\10f351"; }
.fad.@{fa-css-prefix}-arrow-alt-square-right:after { content: "\10f352"; }
.fad.@{fa-css-prefix}-arrow-alt-square-up:after { content: "\10f353"; }
.fad.@{fa-css-prefix}-arrow-alt-to-bottom:after { content: "\10f34a"; }
.fad.@{fa-css-prefix}-arrow-alt-to-left:after { content: "\10f34b"; }
.fad.@{fa-css-prefix}-arrow-alt-to-right:after { content: "\10f34c"; }
.fad.@{fa-css-prefix}-arrow-alt-to-top:after { content: "\10f34d"; }
.fad.@{fa-css-prefix}-arrow-alt-up:after { content: "\10f357"; }
.fad.@{fa-css-prefix}-arrow-circle-down:after { content: "\10f0ab"; }
.fad.@{fa-css-prefix}-arrow-circle-left:after { content: "\10f0a8"; }
.fad.@{fa-css-prefix}-arrow-circle-right:after { content: "\10f0a9"; }
.fad.@{fa-css-prefix}-arrow-circle-up:after { content: "\10f0aa"; }
.fad.@{fa-css-prefix}-arrow-down:after { content: "\10f063"; }
.fad.@{fa-css-prefix}-arrow-from-bottom:after { content: "\10f342"; }
.fad.@{fa-css-prefix}-arrow-from-left:after { content: "\10f343"; }
.fad.@{fa-css-prefix}-arrow-from-right:after { content: "\10f344"; }
.fad.@{fa-css-prefix}-arrow-from-top:after { content: "\10f345"; }
.fad.@{fa-css-prefix}-arrow-left:after { content: "\10f060"; }
.fad.@{fa-css-prefix}-arrow-right:after { content: "\10f061"; }
.fad.@{fa-css-prefix}-arrow-square-down:after { content: "\10f339"; }
.fad.@{fa-css-prefix}-arrow-square-left:after { content: "\10f33a"; }
.fad.@{fa-css-prefix}-arrow-square-right:after { content: "\10f33b"; }
.fad.@{fa-css-prefix}-arrow-square-up:after { content: "\10f33c"; }
.fad.@{fa-css-prefix}-arrow-to-bottom:after { content: "\10f33d"; }
.fad.@{fa-css-prefix}-arrow-to-left:after { content: "\10f33e"; }
.fad.@{fa-css-prefix}-arrow-to-right:after { content: "\10f340"; }
.fad.@{fa-css-prefix}-arrow-to-top:after { content: "\10f341"; }
.fad.@{fa-css-prefix}-arrow-up:after { content: "\10f062"; }
.fad.@{fa-css-prefix}-arrows:after { content: "\10f047"; }
.fad.@{fa-css-prefix}-arrows-alt:after { content: "\10f0b2"; }
.fad.@{fa-css-prefix}-arrows-alt-h:after { content: "\10f337"; }
.fad.@{fa-css-prefix}-arrows-alt-v:after { content: "\10f338"; }
.fad.@{fa-css-prefix}-arrows-h:after { content: "\10f07e"; }
.fad.@{fa-css-prefix}-arrows-v:after { content: "\10f07d"; }
.fad.@{fa-css-prefix}-assistive-listening-systems:after { content: "\10f2a2"; }
.fad.@{fa-css-prefix}-asterisk:after { content: "\10f069"; }
.fad.@{fa-css-prefix}-at:after { content: "\10f1fa"; }
.fad.@{fa-css-prefix}-atlas:after { content: "\10f558"; }
.fad.@{fa-css-prefix}-atom:after { content: "\10f5d2"; }
.fad.@{fa-css-prefix}-atom-alt:after { content: "\10f5d3"; }
.fad.@{fa-css-prefix}-audio-description:after { content: "\10f29e"; }
.fad.@{fa-css-prefix}-award:after { content: "\10f559"; }
.fad.@{fa-css-prefix}-axe:after { content: "\10f6b2"; }
.fad.@{fa-css-prefix}-axe-battle:after { content: "\10f6b3"; }
.fad.@{fa-css-prefix}-baby:after { content: "\10f77c"; }
.fad.@{fa-css-prefix}-baby-carriage:after { content: "\10f77d"; }
.fad.@{fa-css-prefix}-backpack:after { content: "\10f5d4"; }
.fad.@{fa-css-prefix}-backspace:after { content: "\10f55a"; }
.fad.@{fa-css-prefix}-backward:after { content: "\10f04a"; }
.fad.@{fa-css-prefix}-bacon:after { content: "\10f7e5"; }
.fad.@{fa-css-prefix}-badge:after { content: "\10f335"; }
.fad.@{fa-css-prefix}-badge-check:after { content: "\10f336"; }
.fad.@{fa-css-prefix}-badge-dollar:after { content: "\10f645"; }
.fad.@{fa-css-prefix}-badge-percent:after { content: "\10f646"; }
.fad.@{fa-css-prefix}-badge-sheriff:after { content: "\10f8a2"; }
.fad.@{fa-css-prefix}-badger-honey:after { content: "\10f6b4"; }
.fad.@{fa-css-prefix}-bags-shopping:after { content: "\10f847"; }
.fad.@{fa-css-prefix}-bahai:after { content: "\10f666"; }
.fad.@{fa-css-prefix}-balance-scale:after { content: "\10f24e"; }
.fad.@{fa-css-prefix}-balance-scale-left:after { content: "\10f515"; }
.fad.@{fa-css-prefix}-balance-scale-right:after { content: "\10f516"; }
.fad.@{fa-css-prefix}-ball-pile:after { content: "\10f77e"; }
.fad.@{fa-css-prefix}-ballot:after { content: "\10f732"; }
.fad.@{fa-css-prefix}-ballot-check:after { content: "\10f733"; }
.fad.@{fa-css-prefix}-ban:after { content: "\10f05e"; }
.fad.@{fa-css-prefix}-band-aid:after { content: "\10f462"; }
.fad.@{fa-css-prefix}-banjo:after { content: "\10f8a3"; }
.fad.@{fa-css-prefix}-barcode:after { content: "\10f02a"; }
.fad.@{fa-css-prefix}-barcode-alt:after { content: "\10f463"; }
.fad.@{fa-css-prefix}-barcode-read:after { content: "\10f464"; }
.fad.@{fa-css-prefix}-barcode-scan:after { content: "\10f465"; }
.fad.@{fa-css-prefix}-bars:after { content: "\10f0c9"; }
.fad.@{fa-css-prefix}-baseball:after { content: "\10f432"; }
.fad.@{fa-css-prefix}-baseball-ball:after { content: "\10f433"; }
.fad.@{fa-css-prefix}-basketball-ball:after { content: "\10f434"; }
.fad.@{fa-css-prefix}-basketball-hoop:after { content: "\10f435"; }
.fad.@{fa-css-prefix}-bat:after { content: "\10f6b5"; }
.fad.@{fa-css-prefix}-bath:after { content: "\10f2cd"; }
.fad.@{fa-css-prefix}-battery-bolt:after { content: "\10f376"; }
.fad.@{fa-css-prefix}-battery-empty:after { content: "\10f244"; }
.fad.@{fa-css-prefix}-battery-full:after { content: "\10f240"; }
.fad.@{fa-css-prefix}-battery-half:after { content: "\10f242"; }
.fad.@{fa-css-prefix}-battery-quarter:after { content: "\10f243"; }
.fad.@{fa-css-prefix}-battery-slash:after { content: "\10f377"; }
.fad.@{fa-css-prefix}-battery-three-quarters:after { content: "\10f241"; }
.fad.@{fa-css-prefix}-bed:after { content: "\10f236"; }
.fad.@{fa-css-prefix}-bed-alt:after { content: "\10f8f7"; }
.fad.@{fa-css-prefix}-bed-bunk:after { content: "\10f8f8"; }
.fad.@{fa-css-prefix}-bed-empty:after { content: "\10f8f9"; }
.fad.@{fa-css-prefix}-beer:after { content: "\10f0fc"; }
.fad.@{fa-css-prefix}-bell:after { content: "\10f0f3"; }
.fad.@{fa-css-prefix}-bell-exclamation:after { content: "\10f848"; }
.fad.@{fa-css-prefix}-bell-on:after { content: "\10f8fa"; }
.fad.@{fa-css-prefix}-bell-plus:after { content: "\10f849"; }
.fad.@{fa-css-prefix}-bell-school:after { content: "\10f5d5"; }
.fad.@{fa-css-prefix}-bell-school-slash:after { content: "\10f5d6"; }
.fad.@{fa-css-prefix}-bell-slash:after { content: "\10f1f6"; }
.fad.@{fa-css-prefix}-bells:after { content: "\10f77f"; }
.fad.@{fa-css-prefix}-betamax:after { content: "\10f8a4"; }
.fad.@{fa-css-prefix}-bezier-curve:after { content: "\10f55b"; }
.fad.@{fa-css-prefix}-bible:after { content: "\10f647"; }
.fad.@{fa-css-prefix}-bicycle:after { content: "\10f206"; }
.fad.@{fa-css-prefix}-biking:after { content: "\10f84a"; }
.fad.@{fa-css-prefix}-biking-mountain:after { content: "\10f84b"; }
.fad.@{fa-css-prefix}-binoculars:after { content: "\10f1e5"; }
.fad.@{fa-css-prefix}-biohazard:after { content: "\10f780"; }
.fad.@{fa-css-prefix}-birthday-cake:after { content: "\10f1fd"; }
.fad.@{fa-css-prefix}-blanket:after { content: "\10f498"; }
.fad.@{fa-css-prefix}-blender:after { content: "\10f517"; }
.fad.@{fa-css-prefix}-blender-phone:after { content: "\10f6b6"; }
.fad.@{fa-css-prefix}-blind:after { content: "\10f29d"; }
.fad.@{fa-css-prefix}-blinds:after { content: "\10f8fb"; }
.fad.@{fa-css-prefix}-blinds-open:after { content: "\10f8fc"; }
.fad.@{fa-css-prefix}-blinds-raised:after { content: "\10f8fd"; }
.fad.@{fa-css-prefix}-blog:after { content: "\10f781"; }
.fad.@{fa-css-prefix}-bold:after { content: "\10f032"; }
.fad.@{fa-css-prefix}-bolt:after { content: "\10f0e7"; }
.fad.@{fa-css-prefix}-bomb:after { content: "\10f1e2"; }
.fad.@{fa-css-prefix}-bone:after { content: "\10f5d7"; }
.fad.@{fa-css-prefix}-bone-break:after { content: "\10f5d8"; }
.fad.@{fa-css-prefix}-bong:after { content: "\10f55c"; }
.fad.@{fa-css-prefix}-book:after { content: "\10f02d"; }
.fad.@{fa-css-prefix}-book-alt:after { content: "\10f5d9"; }
.fad.@{fa-css-prefix}-book-dead:after { content: "\10f6b7"; }
.fad.@{fa-css-prefix}-book-heart:after { content: "\10f499"; }
.fad.@{fa-css-prefix}-book-medical:after { content: "\10f7e6"; }
.fad.@{fa-css-prefix}-book-open:after { content: "\10f518"; }
.fad.@{fa-css-prefix}-book-reader:after { content: "\10f5da"; }
.fad.@{fa-css-prefix}-book-spells:after { content: "\10f6b8"; }
.fad.@{fa-css-prefix}-book-user:after { content: "\10f7e7"; }
.fad.@{fa-css-prefix}-bookmark:after { content: "\10f02e"; }
.fad.@{fa-css-prefix}-books:after { content: "\10f5db"; }
.fad.@{fa-css-prefix}-books-medical:after { content: "\10f7e8"; }
.fad.@{fa-css-prefix}-boombox:after { content: "\10f8a5"; }
.fad.@{fa-css-prefix}-boot:after { content: "\10f782"; }
.fad.@{fa-css-prefix}-booth-curtain:after { content: "\10f734"; }
.fad.@{fa-css-prefix}-border-all:after { content: "\10f84c"; }
.fad.@{fa-css-prefix}-border-bottom:after { content: "\10f84d"; }
.fad.@{fa-css-prefix}-border-center-h:after { content: "\10f89c"; }
.fad.@{fa-css-prefix}-border-center-v:after { content: "\10f89d"; }
.fad.@{fa-css-prefix}-border-inner:after { content: "\10f84e"; }
.fad.@{fa-css-prefix}-border-left:after { content: "\10f84f"; }
.fad.@{fa-css-prefix}-border-none:after { content: "\10f850"; }
.fad.@{fa-css-prefix}-border-outer:after { content: "\10f851"; }
.fad.@{fa-css-prefix}-border-right:after { content: "\10f852"; }
.fad.@{fa-css-prefix}-border-style:after { content: "\10f853"; }
.fad.@{fa-css-prefix}-border-style-alt:after { content: "\10f854"; }
.fad.@{fa-css-prefix}-border-top:after { content: "\10f855"; }
.fad.@{fa-css-prefix}-bow-arrow:after { content: "\10f6b9"; }
.fad.@{fa-css-prefix}-bowling-ball:after { content: "\10f436"; }
.fad.@{fa-css-prefix}-bowling-pins:after { content: "\10f437"; }
.fad.@{fa-css-prefix}-box:after { content: "\10f466"; }
.fad.@{fa-css-prefix}-box-alt:after { content: "\10f49a"; }
.fad.@{fa-css-prefix}-box-ballot:after { content: "\10f735"; }
.fad.@{fa-css-prefix}-box-check:after { content: "\10f467"; }
.fad.@{fa-css-prefix}-box-fragile:after { content: "\10f49b"; }
.fad.@{fa-css-prefix}-box-full:after { content: "\10f49c"; }
.fad.@{fa-css-prefix}-box-heart:after { content: "\10f49d"; }
.fad.@{fa-css-prefix}-box-open:after { content: "\10f49e"; }
.fad.@{fa-css-prefix}-box-up:after { content: "\10f49f"; }
.fad.@{fa-css-prefix}-box-usd:after { content: "\10f4a0"; }
.fad.@{fa-css-prefix}-boxes:after { content: "\10f468"; }
.fad.@{fa-css-prefix}-boxes-alt:after { content: "\10f4a1"; }
.fad.@{fa-css-prefix}-boxing-glove:after { content: "\10f438"; }
.fad.@{fa-css-prefix}-brackets:after { content: "\10f7e9"; }
.fad.@{fa-css-prefix}-brackets-curly:after { content: "\10f7ea"; }
.fad.@{fa-css-prefix}-braille:after { content: "\10f2a1"; }
.fad.@{fa-css-prefix}-brain:after { content: "\10f5dc"; }
.fad.@{fa-css-prefix}-bread-loaf:after { content: "\10f7eb"; }
.fad.@{fa-css-prefix}-bread-slice:after { content: "\10f7ec"; }
.fad.@{fa-css-prefix}-briefcase:after { content: "\10f0b1"; }
.fad.@{fa-css-prefix}-briefcase-medical:after { content: "\10f469"; }
.fad.@{fa-css-prefix}-bring-forward:after { content: "\10f856"; }
.fad.@{fa-css-prefix}-bring-front:after { content: "\10f857"; }
.fad.@{fa-css-prefix}-broadcast-tower:after { content: "\10f519"; }
.fad.@{fa-css-prefix}-broom:after { content: "\10f51a"; }
.fad.@{fa-css-prefix}-browser:after { content: "\10f37e"; }
.fad.@{fa-css-prefix}-brush:after { content: "\10f55d"; }
.fad.@{fa-css-prefix}-bug:after { content: "\10f188"; }
.fad.@{fa-css-prefix}-building:after { content: "\10f1ad"; }
.fad.@{fa-css-prefix}-bullhorn:after { content: "\10f0a1"; }
.fad.@{fa-css-prefix}-bullseye:after { content: "\10f140"; }
.fad.@{fa-css-prefix}-bullseye-arrow:after { content: "\10f648"; }
.fad.@{fa-css-prefix}-bullseye-pointer:after { content: "\10f649"; }
.fad.@{fa-css-prefix}-burger-soda:after { content: "\10f858"; }
.fad.@{fa-css-prefix}-burn:after { content: "\10f46a"; }
.fad.@{fa-css-prefix}-burrito:after { content: "\10f7ed"; }
.fad.@{fa-css-prefix}-bus:after { content: "\10f207"; }
.fad.@{fa-css-prefix}-bus-alt:after { content: "\10f55e"; }
.fad.@{fa-css-prefix}-bus-school:after { content: "\10f5dd"; }
.fad.@{fa-css-prefix}-business-time:after { content: "\10f64a"; }
.fad.@{fa-css-prefix}-cabinet-filing:after { content: "\10f64b"; }
.fad.@{fa-css-prefix}-cactus:after { content: "\10f8a7"; }
.fad.@{fa-css-prefix}-calculator:after { content: "\10f1ec"; }
.fad.@{fa-css-prefix}-calculator-alt:after { content: "\10f64c"; }
.fad.@{fa-css-prefix}-calendar:after { content: "\10f133"; }
.fad.@{fa-css-prefix}-calendar-alt:after { content: "\10f073"; }
.fad.@{fa-css-prefix}-calendar-check:after { content: "\10f274"; }
.fad.@{fa-css-prefix}-calendar-day:after { content: "\10f783"; }
.fad.@{fa-css-prefix}-calendar-edit:after { content: "\10f333"; }
.fad.@{fa-css-prefix}-calendar-exclamation:after { content: "\10f334"; }
.fad.@{fa-css-prefix}-calendar-minus:after { content: "\10f272"; }
.fad.@{fa-css-prefix}-calendar-plus:after { content: "\10f271"; }
.fad.@{fa-css-prefix}-calendar-star:after { content: "\10f736"; }
.fad.@{fa-css-prefix}-calendar-times:after { content: "\10f273"; }
.fad.@{fa-css-prefix}-calendar-week:after { content: "\10f784"; }
.fad.@{fa-css-prefix}-camcorder:after { content: "\10f8a8"; }
.fad.@{fa-css-prefix}-camera:after { content: "\10f030"; }
.fad.@{fa-css-prefix}-camera-alt:after { content: "\10f332"; }
.fad.@{fa-css-prefix}-camera-home:after { content: "\10f8fe"; }
.fad.@{fa-css-prefix}-camera-movie:after { content: "\10f8a9"; }
.fad.@{fa-css-prefix}-camera-polaroid:after { content: "\10f8aa"; }
.fad.@{fa-css-prefix}-camera-retro:after { content: "\10f083"; }
.fad.@{fa-css-prefix}-campfire:after { content: "\10f6ba"; }
.fad.@{fa-css-prefix}-campground:after { content: "\10f6bb"; }
.fad.@{fa-css-prefix}-candle-holder:after { content: "\10f6bc"; }
.fad.@{fa-css-prefix}-candy-cane:after { content: "\10f786"; }
.fad.@{fa-css-prefix}-candy-corn:after { content: "\10f6bd"; }
.fad.@{fa-css-prefix}-cannabis:after { content: "\10f55f"; }
.fad.@{fa-css-prefix}-capsules:after { content: "\10f46b"; }
.fad.@{fa-css-prefix}-car:after { content: "\10f1b9"; }
.fad.@{fa-css-prefix}-car-alt:after { content: "\10f5de"; }
.fad.@{fa-css-prefix}-car-battery:after { content: "\10f5df"; }
.fad.@{fa-css-prefix}-car-building:after { content: "\10f859"; }
.fad.@{fa-css-prefix}-car-bump:after { content: "\10f5e0"; }
.fad.@{fa-css-prefix}-car-bus:after { content: "\10f85a"; }
.fad.@{fa-css-prefix}-car-crash:after { content: "\10f5e1"; }
.fad.@{fa-css-prefix}-car-garage:after { content: "\10f5e2"; }
.fad.@{fa-css-prefix}-car-mechanic:after { content: "\10f5e3"; }
.fad.@{fa-css-prefix}-car-side:after { content: "\10f5e4"; }
.fad.@{fa-css-prefix}-car-tilt:after { content: "\10f5e5"; }
.fad.@{fa-css-prefix}-car-wash:after { content: "\10f5e6"; }
.fad.@{fa-css-prefix}-caravan:after { content: "\10f8ff"; }
.fad.@{fa-css-prefix}-caravan-alt:after { content: "\10f900"; }
.fad.@{fa-css-prefix}-caret-circle-down:after { content: "\10f32d"; }
.fad.@{fa-css-prefix}-caret-circle-left:after { content: "\10f32e"; }
.fad.@{fa-css-prefix}-caret-circle-right:after { content: "\10f330"; }
.fad.@{fa-css-prefix}-caret-circle-up:after { content: "\10f331"; }
.fad.@{fa-css-prefix}-caret-down:after { content: "\10f0d7"; }
.fad.@{fa-css-prefix}-caret-left:after { content: "\10f0d9"; }
.fad.@{fa-css-prefix}-caret-right:after { content: "\10f0da"; }
.fad.@{fa-css-prefix}-caret-square-down:after { content: "\10f150"; }
.fad.@{fa-css-prefix}-caret-square-left:after { content: "\10f191"; }
.fad.@{fa-css-prefix}-caret-square-right:after { content: "\10f152"; }
.fad.@{fa-css-prefix}-caret-square-up:after { content: "\10f151"; }
.fad.@{fa-css-prefix}-caret-up:after { content: "\10f0d8"; }
.fad.@{fa-css-prefix}-carrot:after { content: "\10f787"; }
.fad.@{fa-css-prefix}-cars:after { content: "\10f85b"; }
.fad.@{fa-css-prefix}-cart-arrow-down:after { content: "\10f218"; }
.fad.@{fa-css-prefix}-cart-plus:after { content: "\10f217"; }
.fad.@{fa-css-prefix}-cash-register:after { content: "\10f788"; }
.fad.@{fa-css-prefix}-cassette-tape:after { content: "\10f8ab"; }
.fad.@{fa-css-prefix}-cat:after { content: "\10f6be"; }
.fad.@{fa-css-prefix}-cat-space:after { content: "\10f901"; }
.fad.@{fa-css-prefix}-cauldron:after { content: "\10f6bf"; }
.fad.@{fa-css-prefix}-cctv:after { content: "\10f8ac"; }
.fad.@{fa-css-prefix}-certificate:after { content: "\10f0a3"; }
.fad.@{fa-css-prefix}-chair:after { content: "\10f6c0"; }
.fad.@{fa-css-prefix}-chair-office:after { content: "\10f6c1"; }
.fad.@{fa-css-prefix}-chalkboard:after { content: "\10f51b"; }
.fad.@{fa-css-prefix}-chalkboard-teacher:after { content: "\10f51c"; }
.fad.@{fa-css-prefix}-charging-station:after { content: "\10f5e7"; }
.fad.@{fa-css-prefix}-chart-area:after { content: "\10f1fe"; }
.fad.@{fa-css-prefix}-chart-bar:after { content: "\10f080"; }
.fad.@{fa-css-prefix}-chart-line:after { content: "\10f201"; }
.fad.@{fa-css-prefix}-chart-line-down:after { content: "\10f64d"; }
.fad.@{fa-css-prefix}-chart-network:after { content: "\10f78a"; }
.fad.@{fa-css-prefix}-chart-pie:after { content: "\10f200"; }
.fad.@{fa-css-prefix}-chart-pie-alt:after { content: "\10f64e"; }
.fad.@{fa-css-prefix}-chart-scatter:after { content: "\10f7ee"; }
.fad.@{fa-css-prefix}-check:after { content: "\10f00c"; }
.fad.@{fa-css-prefix}-check-circle:after { content: "\10f058"; }
.fad.@{fa-css-prefix}-check-double:after { content: "\10f560"; }
.fad.@{fa-css-prefix}-check-square:after { content: "\10f14a"; }
.fad.@{fa-css-prefix}-cheese:after { content: "\10f7ef"; }
.fad.@{fa-css-prefix}-cheese-swiss:after { content: "\10f7f0"; }
.fad.@{fa-css-prefix}-cheeseburger:after { content: "\10f7f1"; }
.fad.@{fa-css-prefix}-chess:after { content: "\10f439"; }
.fad.@{fa-css-prefix}-chess-bishop:after { content: "\10f43a"; }
.fad.@{fa-css-prefix}-chess-bishop-alt:after { content: "\10f43b"; }
.fad.@{fa-css-prefix}-chess-board:after { content: "\10f43c"; }
.fad.@{fa-css-prefix}-chess-clock:after { content: "\10f43d"; }
.fad.@{fa-css-prefix}-chess-clock-alt:after { content: "\10f43e"; }
.fad.@{fa-css-prefix}-chess-king:after { content: "\10f43f"; }
.fad.@{fa-css-prefix}-chess-king-alt:after { content: "\10f440"; }
.fad.@{fa-css-prefix}-chess-knight:after { content: "\10f441"; }
.fad.@{fa-css-prefix}-chess-knight-alt:after { content: "\10f442"; }
.fad.@{fa-css-prefix}-chess-pawn:after { content: "\10f443"; }
.fad.@{fa-css-prefix}-chess-pawn-alt:after { content: "\10f444"; }
.fad.@{fa-css-prefix}-chess-queen:after { content: "\10f445"; }
.fad.@{fa-css-prefix}-chess-queen-alt:after { content: "\10f446"; }
.fad.@{fa-css-prefix}-chess-rook:after { content: "\10f447"; }
.fad.@{fa-css-prefix}-chess-rook-alt:after { content: "\10f448"; }
.fad.@{fa-css-prefix}-chevron-circle-down:after { content: "\10f13a"; }
.fad.@{fa-css-prefix}-chevron-circle-left:after { content: "\10f137"; }
.fad.@{fa-css-prefix}-chevron-circle-right:after { content: "\10f138"; }
.fad.@{fa-css-prefix}-chevron-circle-up:after { content: "\10f139"; }
.fad.@{fa-css-prefix}-chevron-double-down:after { content: "\10f322"; }
.fad.@{fa-css-prefix}-chevron-double-left:after { content: "\10f323"; }
.fad.@{fa-css-prefix}-chevron-double-right:after { content: "\10f324"; }
.fad.@{fa-css-prefix}-chevron-double-up:after { content: "\10f325"; }
.fad.@{fa-css-prefix}-chevron-down:after { content: "\10f078"; }
.fad.@{fa-css-prefix}-chevron-left:after { content: "\10f053"; }
.fad.@{fa-css-prefix}-chevron-right:after { content: "\10f054"; }
.fad.@{fa-css-prefix}-chevron-square-down:after { content: "\10f329"; }
.fad.@{fa-css-prefix}-chevron-square-left:after { content: "\10f32a"; }
.fad.@{fa-css-prefix}-chevron-square-right:after { content: "\10f32b"; }
.fad.@{fa-css-prefix}-chevron-square-up:after { content: "\10f32c"; }
.fad.@{fa-css-prefix}-chevron-up:after { content: "\10f077"; }
.fad.@{fa-css-prefix}-child:after { content: "\10f1ae"; }
.fad.@{fa-css-prefix}-chimney:after { content: "\10f78b"; }
.fad.@{fa-css-prefix}-church:after { content: "\10f51d"; }
.fad.@{fa-css-prefix}-circle:after { content: "\10f111"; }
.fad.@{fa-css-prefix}-circle-notch:after { content: "\10f1ce"; }
.fad.@{fa-css-prefix}-city:after { content: "\10f64f"; }
.fad.@{fa-css-prefix}-clarinet:after { content: "\10f8ad"; }
.fad.@{fa-css-prefix}-claw-marks:after { content: "\10f6c2"; }
.fad.@{fa-css-prefix}-clinic-medical:after { content: "\10f7f2"; }
.fad.@{fa-css-prefix}-clipboard:after { content: "\10f328"; }
.fad.@{fa-css-prefix}-clipboard-check:after { content: "\10f46c"; }
.fad.@{fa-css-prefix}-clipboard-list:after { content: "\10f46d"; }
.fad.@{fa-css-prefix}-clipboard-list-check:after { content: "\10f737"; }
.fad.@{fa-css-prefix}-clipboard-prescription:after { content: "\10f5e8"; }
.fad.@{fa-css-prefix}-clipboard-user:after { content: "\10f7f3"; }
.fad.@{fa-css-prefix}-clock:after { content: "\10f017"; }
.fad.@{fa-css-prefix}-clone:after { content: "\10f24d"; }
.fad.@{fa-css-prefix}-closed-captioning:after { content: "\10f20a"; }
.fad.@{fa-css-prefix}-cloud:after { content: "\10f0c2"; }
.fad.@{fa-css-prefix}-cloud-download:after { content: "\10f0ed"; }
.fad.@{fa-css-prefix}-cloud-download-alt:after { content: "\10f381"; }
.fad.@{fa-css-prefix}-cloud-drizzle:after { content: "\10f738"; }
.fad.@{fa-css-prefix}-cloud-hail:after { content: "\10f739"; }
.fad.@{fa-css-prefix}-cloud-hail-mixed:after { content: "\10f73a"; }
.fad.@{fa-css-prefix}-cloud-meatball:after { content: "\10f73b"; }
.fad.@{fa-css-prefix}-cloud-moon:after { content: "\10f6c3"; }
.fad.@{fa-css-prefix}-cloud-moon-rain:after { content: "\10f73c"; }
.fad.@{fa-css-prefix}-cloud-music:after { content: "\10f8ae"; }
.fad.@{fa-css-prefix}-cloud-rain:after { content: "\10f73d"; }
.fad.@{fa-css-prefix}-cloud-rainbow:after { content: "\10f73e"; }
.fad.@{fa-css-prefix}-cloud-showers:after { content: "\10f73f"; }
.fad.@{fa-css-prefix}-cloud-showers-heavy:after { content: "\10f740"; }
.fad.@{fa-css-prefix}-cloud-sleet:after { content: "\10f741"; }
.fad.@{fa-css-prefix}-cloud-snow:after { content: "\10f742"; }
.fad.@{fa-css-prefix}-cloud-sun:after { content: "\10f6c4"; }
.fad.@{fa-css-prefix}-cloud-sun-rain:after { content: "\10f743"; }
.fad.@{fa-css-prefix}-cloud-upload:after { content: "\10f0ee"; }
.fad.@{fa-css-prefix}-cloud-upload-alt:after { content: "\10f382"; }
.fad.@{fa-css-prefix}-clouds:after { content: "\10f744"; }
.fad.@{fa-css-prefix}-clouds-moon:after { content: "\10f745"; }
.fad.@{fa-css-prefix}-clouds-sun:after { content: "\10f746"; }
.fad.@{fa-css-prefix}-club:after { content: "\10f327"; }
.fad.@{fa-css-prefix}-cocktail:after { content: "\10f561"; }
.fad.@{fa-css-prefix}-code:after { content: "\10f121"; }
.fad.@{fa-css-prefix}-code-branch:after { content: "\10f126"; }
.fad.@{fa-css-prefix}-code-commit:after { content: "\10f386"; }
.fad.@{fa-css-prefix}-code-merge:after { content: "\10f387"; }
.fad.@{fa-css-prefix}-coffee:after { content: "\10f0f4"; }
.fad.@{fa-css-prefix}-coffee-pot:after { content: "\10f902"; }
.fad.@{fa-css-prefix}-coffee-togo:after { content: "\10f6c5"; }
.fad.@{fa-css-prefix}-coffin:after { content: "\10f6c6"; }
.fad.@{fa-css-prefix}-cog:after { content: "\10f013"; }
.fad.@{fa-css-prefix}-cogs:after { content: "\10f085"; }
.fad.@{fa-css-prefix}-coin:after { content: "\10f85c"; }
.fad.@{fa-css-prefix}-coins:after { content: "\10f51e"; }
.fad.@{fa-css-prefix}-columns:after { content: "\10f0db"; }
.fad.@{fa-css-prefix}-comet:after { content: "\10f903"; }
.fad.@{fa-css-prefix}-comment:after { content: "\10f075"; }
.fad.@{fa-css-prefix}-comment-alt:after { content: "\10f27a"; }
.fad.@{fa-css-prefix}-comment-alt-check:after { content: "\10f4a2"; }
.fad.@{fa-css-prefix}-comment-alt-dollar:after { content: "\10f650"; }
.fad.@{fa-css-prefix}-comment-alt-dots:after { content: "\10f4a3"; }
.fad.@{fa-css-prefix}-comment-alt-edit:after { content: "\10f4a4"; }
.fad.@{fa-css-prefix}-comment-alt-exclamation:after { content: "\10f4a5"; }
.fad.@{fa-css-prefix}-comment-alt-lines:after { content: "\10f4a6"; }
.fad.@{fa-css-prefix}-comment-alt-medical:after { content: "\10f7f4"; }
.fad.@{fa-css-prefix}-comment-alt-minus:after { content: "\10f4a7"; }
.fad.@{fa-css-prefix}-comment-alt-music:after { content: "\10f8af"; }
.fad.@{fa-css-prefix}-comment-alt-plus:after { content: "\10f4a8"; }
.fad.@{fa-css-prefix}-comment-alt-slash:after { content: "\10f4a9"; }
.fad.@{fa-css-prefix}-comment-alt-smile:after { content: "\10f4aa"; }
.fad.@{fa-css-prefix}-comment-alt-times:after { content: "\10f4ab"; }
.fad.@{fa-css-prefix}-comment-check:after { content: "\10f4ac"; }
.fad.@{fa-css-prefix}-comment-dollar:after { content: "\10f651"; }
.fad.@{fa-css-prefix}-comment-dots:after { content: "\10f4ad"; }
.fad.@{fa-css-prefix}-comment-edit:after { content: "\10f4ae"; }
.fad.@{fa-css-prefix}-comment-exclamation:after { content: "\10f4af"; }
.fad.@{fa-css-prefix}-comment-lines:after { content: "\10f4b0"; }
.fad.@{fa-css-prefix}-comment-medical:after { content: "\10f7f5"; }
.fad.@{fa-css-prefix}-comment-minus:after { content: "\10f4b1"; }
.fad.@{fa-css-prefix}-comment-music:after { content: "\10f8b0"; }
.fad.@{fa-css-prefix}-comment-plus:after { content: "\10f4b2"; }
.fad.@{fa-css-prefix}-comment-slash:after { content: "\10f4b3"; }
.fad.@{fa-css-prefix}-comment-smile:after { content: "\10f4b4"; }
.fad.@{fa-css-prefix}-comment-times:after { content: "\10f4b5"; }
.fad.@{fa-css-prefix}-comments:after { content: "\10f086"; }
.fad.@{fa-css-prefix}-comments-alt:after { content: "\10f4b6"; }
.fad.@{fa-css-prefix}-comments-alt-dollar:after { content: "\10f652"; }
.fad.@{fa-css-prefix}-comments-dollar:after { content: "\10f653"; }
.fad.@{fa-css-prefix}-compact-disc:after { content: "\10f51f"; }
.fad.@{fa-css-prefix}-compass:after { content: "\10f14e"; }
.fad.@{fa-css-prefix}-compass-slash:after { content: "\10f5e9"; }
.fad.@{fa-css-prefix}-compress:after { content: "\10f066"; }
.fad.@{fa-css-prefix}-compress-alt:after { content: "\10f422"; }
.fad.@{fa-css-prefix}-compress-arrows-alt:after { content: "\10f78c"; }
.fad.@{fa-css-prefix}-compress-wide:after { content: "\10f326"; }
.fad.@{fa-css-prefix}-computer-classic:after { content: "\10f8b1"; }
.fad.@{fa-css-prefix}-computer-speaker:after { content: "\10f8b2"; }
.fad.@{fa-css-prefix}-concierge-bell:after { content: "\10f562"; }
.fad.@{fa-css-prefix}-construction:after { content: "\10f85d"; }
.fad.@{fa-css-prefix}-container-storage:after { content: "\10f4b7"; }
.fad.@{fa-css-prefix}-conveyor-belt:after { content: "\10f46e"; }
.fad.@{fa-css-prefix}-conveyor-belt-alt:after { content: "\10f46f"; }
.fad.@{fa-css-prefix}-cookie:after { content: "\10f563"; }
.fad.@{fa-css-prefix}-cookie-bite:after { content: "\10f564"; }
.fad.@{fa-css-prefix}-copy:after { content: "\10f0c5"; }
.fad.@{fa-css-prefix}-copyright:after { content: "\10f1f9"; }
.fad.@{fa-css-prefix}-corn:after { content: "\10f6c7"; }
.fad.@{fa-css-prefix}-couch:after { content: "\10f4b8"; }
.fad.@{fa-css-prefix}-cow:after { content: "\10f6c8"; }
.fad.@{fa-css-prefix}-cowbell:after { content: "\10f8b3"; }
.fad.@{fa-css-prefix}-cowbell-more:after { content: "\10f8b4"; }
.fad.@{fa-css-prefix}-credit-card:after { content: "\10f09d"; }
.fad.@{fa-css-prefix}-credit-card-blank:after { content: "\10f389"; }
.fad.@{fa-css-prefix}-credit-card-front:after { content: "\10f38a"; }
.fad.@{fa-css-prefix}-cricket:after { content: "\10f449"; }
.fad.@{fa-css-prefix}-croissant:after { content: "\10f7f6"; }
.fad.@{fa-css-prefix}-crop:after { content: "\10f125"; }
.fad.@{fa-css-prefix}-crop-alt:after { content: "\10f565"; }
.fad.@{fa-css-prefix}-cross:after { content: "\10f654"; }
.fad.@{fa-css-prefix}-crosshairs:after { content: "\10f05b"; }
.fad.@{fa-css-prefix}-crow:after { content: "\10f520"; }
.fad.@{fa-css-prefix}-crown:after { content: "\10f521"; }
.fad.@{fa-css-prefix}-crutch:after { content: "\10f7f7"; }
.fad.@{fa-css-prefix}-crutches:after { content: "\10f7f8"; }
.fad.@{fa-css-prefix}-cube:after { content: "\10f1b2"; }
.fad.@{fa-css-prefix}-cubes:after { content: "\10f1b3"; }
.fad.@{fa-css-prefix}-curling:after { content: "\10f44a"; }
.fad.@{fa-css-prefix}-cut:after { content: "\10f0c4"; }
.fad.@{fa-css-prefix}-dagger:after { content: "\10f6cb"; }
.fad.@{fa-css-prefix}-database:after { content: "\10f1c0"; }
.fad.@{fa-css-prefix}-deaf:after { content: "\10f2a4"; }
.fad.@{fa-css-prefix}-debug:after { content: "\10f7f9"; }
.fad.@{fa-css-prefix}-deer:after { content: "\10f78e"; }
.fad.@{fa-css-prefix}-deer-rudolph:after { content: "\10f78f"; }
.fad.@{fa-css-prefix}-democrat:after { content: "\10f747"; }
.fad.@{fa-css-prefix}-desktop:after { content: "\10f108"; }
.fad.@{fa-css-prefix}-desktop-alt:after { content: "\10f390"; }
.fad.@{fa-css-prefix}-dewpoint:after { content: "\10f748"; }
.fad.@{fa-css-prefix}-dharmachakra:after { content: "\10f655"; }
.fad.@{fa-css-prefix}-diagnoses:after { content: "\10f470"; }
.fad.@{fa-css-prefix}-diamond:after { content: "\10f219"; }
.fad.@{fa-css-prefix}-dice:after { content: "\10f522"; }
.fad.@{fa-css-prefix}-dice-d10:after { content: "\10f6cd"; }
.fad.@{fa-css-prefix}-dice-d12:after { content: "\10f6ce"; }
.fad.@{fa-css-prefix}-dice-d20:after { content: "\10f6cf"; }
.fad.@{fa-css-prefix}-dice-d4:after { content: "\10f6d0"; }
.fad.@{fa-css-prefix}-dice-d6:after { content: "\10f6d1"; }
.fad.@{fa-css-prefix}-dice-d8:after { content: "\10f6d2"; }
.fad.@{fa-css-prefix}-dice-five:after { content: "\10f523"; }
.fad.@{fa-css-prefix}-dice-four:after { content: "\10f524"; }
.fad.@{fa-css-prefix}-dice-one:after { content: "\10f525"; }
.fad.@{fa-css-prefix}-dice-six:after { content: "\10f526"; }
.fad.@{fa-css-prefix}-dice-three:after { content: "\10f527"; }
.fad.@{fa-css-prefix}-dice-two:after { content: "\10f528"; }
.fad.@{fa-css-prefix}-digging:after { content: "\10f85e"; }
.fad.@{fa-css-prefix}-digital-tachograph:after { content: "\10f566"; }
.fad.@{fa-css-prefix}-diploma:after { content: "\10f5ea"; }
.fad.@{fa-css-prefix}-directions:after { content: "\10f5eb"; }
.fad.@{fa-css-prefix}-disc-drive:after { content: "\10f8b5"; }
.fad.@{fa-css-prefix}-disease:after { content: "\10f7fa"; }
.fad.@{fa-css-prefix}-divide:after { content: "\10f529"; }
.fad.@{fa-css-prefix}-dizzy:after { content: "\10f567"; }
.fad.@{fa-css-prefix}-dna:after { content: "\10f471"; }
.fad.@{fa-css-prefix}-do-not-enter:after { content: "\10f5ec"; }
.fad.@{fa-css-prefix}-dog:after { content: "\10f6d3"; }
.fad.@{fa-css-prefix}-dog-leashed:after { content: "\10f6d4"; }
.fad.@{fa-css-prefix}-dollar-sign:after { content: "\10f155"; }
.fad.@{fa-css-prefix}-dolly:after { content: "\10f472"; }
.fad.@{fa-css-prefix}-dolly-empty:after { content: "\10f473"; }
.fad.@{fa-css-prefix}-dolly-flatbed:after { content: "\10f474"; }
.fad.@{fa-css-prefix}-dolly-flatbed-alt:after { content: "\10f475"; }
.fad.@{fa-css-prefix}-dolly-flatbed-empty:after { content: "\10f476"; }
.fad.@{fa-css-prefix}-donate:after { content: "\10f4b9"; }
.fad.@{fa-css-prefix}-door-closed:after { content: "\10f52a"; }
.fad.@{fa-css-prefix}-door-open:after { content: "\10f52b"; }
.fad.@{fa-css-prefix}-dot-circle:after { content: "\10f192"; }
.fad.@{fa-css-prefix}-dove:after { content: "\10f4ba"; }
.fad.@{fa-css-prefix}-download:after { content: "\10f019"; }
.fad.@{fa-css-prefix}-drafting-compass:after { content: "\10f568"; }
.fad.@{fa-css-prefix}-dragon:after { content: "\10f6d5"; }
.fad.@{fa-css-prefix}-draw-circle:after { content: "\10f5ed"; }
.fad.@{fa-css-prefix}-draw-polygon:after { content: "\10f5ee"; }
.fad.@{fa-css-prefix}-draw-square:after { content: "\10f5ef"; }
.fad.@{fa-css-prefix}-dreidel:after { content: "\10f792"; }
.fad.@{fa-css-prefix}-drone:after { content: "\10f85f"; }
.fad.@{fa-css-prefix}-drone-alt:after { content: "\10f860"; }
.fad.@{fa-css-prefix}-drum:after { content: "\10f569"; }
.fad.@{fa-css-prefix}-drum-steelpan:after { content: "\10f56a"; }
.fad.@{fa-css-prefix}-drumstick:after { content: "\10f6d6"; }
.fad.@{fa-css-prefix}-drumstick-bite:after { content: "\10f6d7"; }
.fad.@{fa-css-prefix}-dryer:after { content: "\10f861"; }
.fad.@{fa-css-prefix}-dryer-alt:after { content: "\10f862"; }
.fad.@{fa-css-prefix}-duck:after { content: "\10f6d8"; }
.fad.@{fa-css-prefix}-dumbbell:after { content: "\10f44b"; }
.fad.@{fa-css-prefix}-dumpster:after { content: "\10f793"; }
.fad.@{fa-css-prefix}-dumpster-fire:after { content: "\10f794"; }
.fad.@{fa-css-prefix}-dungeon:after { content: "\10f6d9"; }
.fad.@{fa-css-prefix}-ear:after { content: "\10f5f0"; }
.fad.@{fa-css-prefix}-ear-muffs:after { content: "\10f795"; }
.fad.@{fa-css-prefix}-eclipse:after { content: "\10f749"; }
.fad.@{fa-css-prefix}-eclipse-alt:after { content: "\10f74a"; }
.fad.@{fa-css-prefix}-edit:after { content: "\10f044"; }
.fad.@{fa-css-prefix}-egg:after { content: "\10f7fb"; }
.fad.@{fa-css-prefix}-egg-fried:after { content: "\10f7fc"; }
.fad.@{fa-css-prefix}-eject:after { content: "\10f052"; }
.fad.@{fa-css-prefix}-elephant:after { content: "\10f6da"; }
.fad.@{fa-css-prefix}-ellipsis-h:after { content: "\10f141"; }
.fad.@{fa-css-prefix}-ellipsis-h-alt:after { content: "\10f39b"; }
.fad.@{fa-css-prefix}-ellipsis-v:after { content: "\10f142"; }
.fad.@{fa-css-prefix}-ellipsis-v-alt:after { content: "\10f39c"; }
.fad.@{fa-css-prefix}-empty-set:after { content: "\10f656"; }
.fad.@{fa-css-prefix}-engine-warning:after { content: "\10f5f2"; }
.fad.@{fa-css-prefix}-envelope:after { content: "\10f0e0"; }
.fad.@{fa-css-prefix}-envelope-open:after { content: "\10f2b6"; }
.fad.@{fa-css-prefix}-envelope-open-dollar:after { content: "\10f657"; }
.fad.@{fa-css-prefix}-envelope-open-text:after { content: "\10f658"; }
.fad.@{fa-css-prefix}-envelope-square:after { content: "\10f199"; }
.fad.@{fa-css-prefix}-equals:after { content: "\10f52c"; }
.fad.@{fa-css-prefix}-eraser:after { content: "\10f12d"; }
.fad.@{fa-css-prefix}-ethernet:after { content: "\10f796"; }
.fad.@{fa-css-prefix}-euro-sign:after { content: "\10f153"; }
.fad.@{fa-css-prefix}-exchange:after { content: "\10f0ec"; }
.fad.@{fa-css-prefix}-exchange-alt:after { content: "\10f362"; }
.fad.@{fa-css-prefix}-exclamation:after { content: "\10f12a"; }
.fad.@{fa-css-prefix}-exclamation-circle:after { content: "\10f06a"; }
.fad.@{fa-css-prefix}-exclamation-square:after { content: "\10f321"; }
.fad.@{fa-css-prefix}-exclamation-triangle:after { content: "\10f071"; }
.fad.@{fa-css-prefix}-expand:after { content: "\10f065"; }
.fad.@{fa-css-prefix}-expand-alt:after { content: "\10f424"; }
.fad.@{fa-css-prefix}-expand-arrows:after { content: "\10f31d"; }
.fad.@{fa-css-prefix}-expand-arrows-alt:after { content: "\10f31e"; }
.fad.@{fa-css-prefix}-expand-wide:after { content: "\10f320"; }
.fad.@{fa-css-prefix}-external-link:after { content: "\10f08e"; }
.fad.@{fa-css-prefix}-external-link-alt:after { content: "\10f35d"; }
.fad.@{fa-css-prefix}-external-link-square:after { content: "\10f14c"; }
.fad.@{fa-css-prefix}-external-link-square-alt:after { content: "\10f360"; }
.fad.@{fa-css-prefix}-eye:after { content: "\10f06e"; }
.fad.@{fa-css-prefix}-eye-dropper:after { content: "\10f1fb"; }
.fad.@{fa-css-prefix}-eye-evil:after { content: "\10f6db"; }
.fad.@{fa-css-prefix}-eye-slash:after { content: "\10f070"; }
.fad.@{fa-css-prefix}-fan:after { content: "\10f863"; }
.fad.@{fa-css-prefix}-fan-table:after { content: "\10f904"; }
.fad.@{fa-css-prefix}-farm:after { content: "\10f864"; }
.fad.@{fa-css-prefix}-fast-backward:after { content: "\10f049"; }
.fad.@{fa-css-prefix}-fast-forward:after { content: "\10f050"; }
.fad.@{fa-css-prefix}-faucet:after { content: "\10f905"; }
.fad.@{fa-css-prefix}-faucet-drip:after { content: "\10f906"; }
.fad.@{fa-css-prefix}-fax:after { content: "\10f1ac"; }
.fad.@{fa-css-prefix}-feather:after { content: "\10f52d"; }
.fad.@{fa-css-prefix}-feather-alt:after { content: "\10f56b"; }
.fad.@{fa-css-prefix}-female:after { content: "\10f182"; }
.fad.@{fa-css-prefix}-field-hockey:after { content: "\10f44c"; }
.fad.@{fa-css-prefix}-fighter-jet:after { content: "\10f0fb"; }
.fad.@{fa-css-prefix}-file:after { content: "\10f15b"; }
.fad.@{fa-css-prefix}-file-alt:after { content: "\10f15c"; }
.fad.@{fa-css-prefix}-file-archive:after { content: "\10f1c6"; }
.fad.@{fa-css-prefix}-file-audio:after { content: "\10f1c7"; }
.fad.@{fa-css-prefix}-file-certificate:after { content: "\10f5f3"; }
.fad.@{fa-css-prefix}-file-chart-line:after { content: "\10f659"; }
.fad.@{fa-css-prefix}-file-chart-pie:after { content: "\10f65a"; }
.fad.@{fa-css-prefix}-file-check:after { content: "\10f316"; }
.fad.@{fa-css-prefix}-file-code:after { content: "\10f1c9"; }
.fad.@{fa-css-prefix}-file-contract:after { content: "\10f56c"; }
.fad.@{fa-css-prefix}-file-csv:after { content: "\10f6dd"; }
.fad.@{fa-css-prefix}-file-download:after { content: "\10f56d"; }
.fad.@{fa-css-prefix}-file-edit:after { content: "\10f31c"; }
.fad.@{fa-css-prefix}-file-excel:after { content: "\10f1c3"; }
.fad.@{fa-css-prefix}-file-exclamation:after { content: "\10f31a"; }
.fad.@{fa-css-prefix}-file-export:after { content: "\10f56e"; }
.fad.@{fa-css-prefix}-file-image:after { content: "\10f1c5"; }
.fad.@{fa-css-prefix}-file-import:after { content: "\10f56f"; }
.fad.@{fa-css-prefix}-file-invoice:after { content: "\10f570"; }
.fad.@{fa-css-prefix}-file-invoice-dollar:after { content: "\10f571"; }
.fad.@{fa-css-prefix}-file-medical:after { content: "\10f477"; }
.fad.@{fa-css-prefix}-file-medical-alt:after { content: "\10f478"; }
.fad.@{fa-css-prefix}-file-minus:after { content: "\10f318"; }
.fad.@{fa-css-prefix}-file-music:after { content: "\10f8b6"; }
.fad.@{fa-css-prefix}-file-pdf:after { content: "\10f1c1"; }
.fad.@{fa-css-prefix}-file-plus:after { content: "\10f319"; }
.fad.@{fa-css-prefix}-file-powerpoint:after { content: "\10f1c4"; }
.fad.@{fa-css-prefix}-file-prescription:after { content: "\10f572"; }
.fad.@{fa-css-prefix}-file-search:after { content: "\10f865"; }
.fad.@{fa-css-prefix}-file-signature:after { content: "\10f573"; }
.fad.@{fa-css-prefix}-file-spreadsheet:after { content: "\10f65b"; }
.fad.@{fa-css-prefix}-file-times:after { content: "\10f317"; }
.fad.@{fa-css-prefix}-file-upload:after { content: "\10f574"; }
.fad.@{fa-css-prefix}-file-user:after { content: "\10f65c"; }
.fad.@{fa-css-prefix}-file-video:after { content: "\10f1c8"; }
.fad.@{fa-css-prefix}-file-word:after { content: "\10f1c2"; }
.fad.@{fa-css-prefix}-files-medical:after { content: "\10f7fd"; }
.fad.@{fa-css-prefix}-fill:after { content: "\10f575"; }
.fad.@{fa-css-prefix}-fill-drip:after { content: "\10f576"; }
.fad.@{fa-css-prefix}-film:after { content: "\10f008"; }
.fad.@{fa-css-prefix}-film-alt:after { content: "\10f3a0"; }
.fad.@{fa-css-prefix}-film-canister:after { content: "\10f8b7"; }
.fad.@{fa-css-prefix}-filter:after { content: "\10f0b0"; }
.fad.@{fa-css-prefix}-fingerprint:after { content: "\10f577"; }
.fad.@{fa-css-prefix}-fire:after { content: "\10f06d"; }
.fad.@{fa-css-prefix}-fire-alt:after { content: "\10f7e4"; }
.fad.@{fa-css-prefix}-fire-extinguisher:after { content: "\10f134"; }
.fad.@{fa-css-prefix}-fire-smoke:after { content: "\10f74b"; }
.fad.@{fa-css-prefix}-fireplace:after { content: "\10f79a"; }
.fad.@{fa-css-prefix}-first-aid:after { content: "\10f479"; }
.fad.@{fa-css-prefix}-fish:after { content: "\10f578"; }
.fad.@{fa-css-prefix}-fish-cooked:after { content: "\10f7fe"; }
.fad.@{fa-css-prefix}-fist-raised:after { content: "\10f6de"; }
.fad.@{fa-css-prefix}-flag:after { content: "\10f024"; }
.fad.@{fa-css-prefix}-flag-alt:after { content: "\10f74c"; }
.fad.@{fa-css-prefix}-flag-checkered:after { content: "\10f11e"; }
.fad.@{fa-css-prefix}-flag-usa:after { content: "\10f74d"; }
.fad.@{fa-css-prefix}-flame:after { content: "\10f6df"; }
.fad.@{fa-css-prefix}-flashlight:after { content: "\10f8b8"; }
.fad.@{fa-css-prefix}-flask:after { content: "\10f0c3"; }
.fad.@{fa-css-prefix}-flask-poison:after { content: "\10f6e0"; }
.fad.@{fa-css-prefix}-flask-potion:after { content: "\10f6e1"; }
.fad.@{fa-css-prefix}-flower:after { content: "\10f7ff"; }
.fad.@{fa-css-prefix}-flower-daffodil:after { content: "\10f800"; }
.fad.@{fa-css-prefix}-flower-tulip:after { content: "\10f801"; }
.fad.@{fa-css-prefix}-flushed:after { content: "\10f579"; }
.fad.@{fa-css-prefix}-flute:after { content: "\10f8b9"; }
.fad.@{fa-css-prefix}-flux-capacitor:after { content: "\10f8ba"; }
.fad.@{fa-css-prefix}-fog:after { content: "\10f74e"; }
.fad.@{fa-css-prefix}-folder:after { content: "\10f07b"; }
.fad.@{fa-css-prefix}-folder-minus:after { content: "\10f65d"; }
.fad.@{fa-css-prefix}-folder-open:after { content: "\10f07c"; }
.fad.@{fa-css-prefix}-folder-plus:after { content: "\10f65e"; }
.fad.@{fa-css-prefix}-folder-times:after { content: "\10f65f"; }
.fad.@{fa-css-prefix}-folder-tree:after { content: "\10f802"; }
.fad.@{fa-css-prefix}-folders:after { content: "\10f660"; }
.fad.@{fa-css-prefix}-font:after { content: "\10f031"; }
.fad.@{fa-css-prefix}-font-awesome-logo-full:after { content: "\10f4e6"; }
.fad.@{fa-css-prefix}-font-case:after { content: "\10f866"; }
.fad.@{fa-css-prefix}-football-ball:after { content: "\10f44e"; }
.fad.@{fa-css-prefix}-football-helmet:after { content: "\10f44f"; }
.fad.@{fa-css-prefix}-forklift:after { content: "\10f47a"; }
.fad.@{fa-css-prefix}-forward:after { content: "\10f04e"; }
.fad.@{fa-css-prefix}-fragile:after { content: "\10f4bb"; }
.fad.@{fa-css-prefix}-french-fries:after { content: "\10f803"; }
.fad.@{fa-css-prefix}-frog:after { content: "\10f52e"; }
.fad.@{fa-css-prefix}-frosty-head:after { content: "\10f79b"; }
.fad.@{fa-css-prefix}-frown:after { content: "\10f119"; }
.fad.@{fa-css-prefix}-frown-open:after { content: "\10f57a"; }
.fad.@{fa-css-prefix}-function:after { content: "\10f661"; }
.fad.@{fa-css-prefix}-funnel-dollar:after { content: "\10f662"; }
.fad.@{fa-css-prefix}-futbol:after { content: "\10f1e3"; }
.fad.@{fa-css-prefix}-galaxy:after { content: "\10f908"; }
.fad.@{fa-css-prefix}-game-board:after { content: "\10f867"; }
.fad.@{fa-css-prefix}-game-board-alt:after { content: "\10f868"; }
.fad.@{fa-css-prefix}-game-console-handheld:after { content: "\10f8bb"; }
.fad.@{fa-css-prefix}-gamepad:after { content: "\10f11b"; }
.fad.@{fa-css-prefix}-gamepad-alt:after { content: "\10f8bc"; }
.fad.@{fa-css-prefix}-garage:after { content: "\10f909"; }
.fad.@{fa-css-prefix}-garage-car:after { content: "\10f90a"; }
.fad.@{fa-css-prefix}-garage-open:after { content: "\10f90b"; }
.fad.@{fa-css-prefix}-gas-pump:after { content: "\10f52f"; }
.fad.@{fa-css-prefix}-gas-pump-slash:after { content: "\10f5f4"; }
.fad.@{fa-css-prefix}-gavel:after { content: "\10f0e3"; }
.fad.@{fa-css-prefix}-gem:after { content: "\10f3a5"; }
.fad.@{fa-css-prefix}-genderless:after { content: "\10f22d"; }
.fad.@{fa-css-prefix}-ghost:after { content: "\10f6e2"; }
.fad.@{fa-css-prefix}-gift:after { content: "\10f06b"; }
.fad.@{fa-css-prefix}-gift-card:after { content: "\10f663"; }
.fad.@{fa-css-prefix}-gifts:after { content: "\10f79c"; }
.fad.@{fa-css-prefix}-gingerbread-man:after { content: "\10f79d"; }
.fad.@{fa-css-prefix}-glass:after { content: "\10f804"; }
.fad.@{fa-css-prefix}-glass-champagne:after { content: "\10f79e"; }
.fad.@{fa-css-prefix}-glass-cheers:after { content: "\10f79f"; }
.fad.@{fa-css-prefix}-glass-citrus:after { content: "\10f869"; }
.fad.@{fa-css-prefix}-glass-martini:after { content: "\10f000"; }
.fad.@{fa-css-prefix}-glass-martini-alt:after { content: "\10f57b"; }
.fad.@{fa-css-prefix}-glass-whiskey:after { content: "\10f7a0"; }
.fad.@{fa-css-prefix}-glass-whiskey-rocks:after { content: "\10f7a1"; }
.fad.@{fa-css-prefix}-glasses:after { content: "\10f530"; }
.fad.@{fa-css-prefix}-glasses-alt:after { content: "\10f5f5"; }
.fad.@{fa-css-prefix}-globe:after { content: "\10f0ac"; }
.fad.@{fa-css-prefix}-globe-africa:after { content: "\10f57c"; }
.fad.@{fa-css-prefix}-globe-americas:after { content: "\10f57d"; }
.fad.@{fa-css-prefix}-globe-asia:after { content: "\10f57e"; }
.fad.@{fa-css-prefix}-globe-europe:after { content: "\10f7a2"; }
.fad.@{fa-css-prefix}-globe-snow:after { content: "\10f7a3"; }
.fad.@{fa-css-prefix}-globe-stand:after { content: "\10f5f6"; }
.fad.@{fa-css-prefix}-golf-ball:after { content: "\10f450"; }
.fad.@{fa-css-prefix}-golf-club:after { content: "\10f451"; }
.fad.@{fa-css-prefix}-gopuram:after { content: "\10f664"; }
.fad.@{fa-css-prefix}-graduation-cap:after { content: "\10f19d"; }
.fad.@{fa-css-prefix}-gramophone:after { content: "\10f8bd"; }
.fad.@{fa-css-prefix}-greater-than:after { content: "\10f531"; }
.fad.@{fa-css-prefix}-greater-than-equal:after { content: "\10f532"; }
.fad.@{fa-css-prefix}-grimace:after { content: "\10f57f"; }
.fad.@{fa-css-prefix}-grin:after { content: "\10f580"; }
.fad.@{fa-css-prefix}-grin-alt:after { content: "\10f581"; }
.fad.@{fa-css-prefix}-grin-beam:after { content: "\10f582"; }
.fad.@{fa-css-prefix}-grin-beam-sweat:after { content: "\10f583"; }
.fad.@{fa-css-prefix}-grin-hearts:after { content: "\10f584"; }
.fad.@{fa-css-prefix}-grin-squint:after { content: "\10f585"; }
.fad.@{fa-css-prefix}-grin-squint-tears:after { content: "\10f586"; }
.fad.@{fa-css-prefix}-grin-stars:after { content: "\10f587"; }
.fad.@{fa-css-prefix}-grin-tears:after { content: "\10f588"; }
.fad.@{fa-css-prefix}-grin-tongue:after { content: "\10f589"; }
.fad.@{fa-css-prefix}-grin-tongue-squint:after { content: "\10f58a"; }
.fad.@{fa-css-prefix}-grin-tongue-wink:after { content: "\10f58b"; }
.fad.@{fa-css-prefix}-grin-wink:after { content: "\10f58c"; }
.fad.@{fa-css-prefix}-grip-horizontal:after { content: "\10f58d"; }
.fad.@{fa-css-prefix}-grip-lines:after { content: "\10f7a4"; }
.fad.@{fa-css-prefix}-grip-lines-vertical:after { content: "\10f7a5"; }
.fad.@{fa-css-prefix}-grip-vertical:after { content: "\10f58e"; }
.fad.@{fa-css-prefix}-guitar:after { content: "\10f7a6"; }
.fad.@{fa-css-prefix}-guitar-electric:after { content: "\10f8be"; }
.fad.@{fa-css-prefix}-guitars:after { content: "\10f8bf"; }
.fad.@{fa-css-prefix}-h-square:after { content: "\10f0fd"; }
.fad.@{fa-css-prefix}-h1:after { content: "\10f313"; }
.fad.@{fa-css-prefix}-h2:after { content: "\10f314"; }
.fad.@{fa-css-prefix}-h3:after { content: "\10f315"; }
.fad.@{fa-css-prefix}-h4:after { content: "\10f86a"; }
.fad.@{fa-css-prefix}-hamburger:after { content: "\10f805"; }
.fad.@{fa-css-prefix}-hammer:after { content: "\10f6e3"; }
.fad.@{fa-css-prefix}-hammer-war:after { content: "\10f6e4"; }
.fad.@{fa-css-prefix}-hamsa:after { content: "\10f665"; }
.fad.@{fa-css-prefix}-hand-heart:after { content: "\10f4bc"; }
.fad.@{fa-css-prefix}-hand-holding:after { content: "\10f4bd"; }
.fad.@{fa-css-prefix}-hand-holding-box:after { content: "\10f47b"; }
.fad.@{fa-css-prefix}-hand-holding-heart:after { content: "\10f4be"; }
.fad.@{fa-css-prefix}-hand-holding-magic:after { content: "\10f6e5"; }
.fad.@{fa-css-prefix}-hand-holding-seedling:after { content: "\10f4bf"; }
.fad.@{fa-css-prefix}-hand-holding-usd:after { content: "\10f4c0"; }
.fad.@{fa-css-prefix}-hand-holding-water:after { content: "\10f4c1"; }
.fad.@{fa-css-prefix}-hand-lizard:after { content: "\10f258"; }
.fad.@{fa-css-prefix}-hand-middle-finger:after { content: "\10f806"; }
.fad.@{fa-css-prefix}-hand-paper:after { content: "\10f256"; }
.fad.@{fa-css-prefix}-hand-peace:after { content: "\10f25b"; }
.fad.@{fa-css-prefix}-hand-point-down:after { content: "\10f0a7"; }
.fad.@{fa-css-prefix}-hand-point-left:after { content: "\10f0a5"; }
.fad.@{fa-css-prefix}-hand-point-right:after { content: "\10f0a4"; }
.fad.@{fa-css-prefix}-hand-point-up:after { content: "\10f0a6"; }
.fad.@{fa-css-prefix}-hand-pointer:after { content: "\10f25a"; }
.fad.@{fa-css-prefix}-hand-receiving:after { content: "\10f47c"; }
.fad.@{fa-css-prefix}-hand-rock:after { content: "\10f255"; }
.fad.@{fa-css-prefix}-hand-scissors:after { content: "\10f257"; }
.fad.@{fa-css-prefix}-hand-spock:after { content: "\10f259"; }
.fad.@{fa-css-prefix}-hands:after { content: "\10f4c2"; }
.fad.@{fa-css-prefix}-hands-heart:after { content: "\10f4c3"; }
.fad.@{fa-css-prefix}-hands-helping:after { content: "\10f4c4"; }
.fad.@{fa-css-prefix}-hands-usd:after { content: "\10f4c5"; }
.fad.@{fa-css-prefix}-handshake:after { content: "\10f2b5"; }
.fad.@{fa-css-prefix}-handshake-alt:after { content: "\10f4c6"; }
.fad.@{fa-css-prefix}-hanukiah:after { content: "\10f6e6"; }
.fad.@{fa-css-prefix}-hard-hat:after { content: "\10f807"; }
.fad.@{fa-css-prefix}-hashtag:after { content: "\10f292"; }
.fad.@{fa-css-prefix}-hat-chef:after { content: "\10f86b"; }
.fad.@{fa-css-prefix}-hat-cowboy:after { content: "\10f8c0"; }
.fad.@{fa-css-prefix}-hat-cowboy-side:after { content: "\10f8c1"; }
.fad.@{fa-css-prefix}-hat-santa:after { content: "\10f7a7"; }
.fad.@{fa-css-prefix}-hat-winter:after { content: "\10f7a8"; }
.fad.@{fa-css-prefix}-hat-witch:after { content: "\10f6e7"; }
.fad.@{fa-css-prefix}-hat-wizard:after { content: "\10f6e8"; }
.fad.@{fa-css-prefix}-hdd:after { content: "\10f0a0"; }
.fad.@{fa-css-prefix}-head-side:after { content: "\10f6e9"; }
.fad.@{fa-css-prefix}-head-side-brain:after { content: "\10f808"; }
.fad.@{fa-css-prefix}-head-side-headphones:after { content: "\10f8c2"; }
.fad.@{fa-css-prefix}-head-side-medical:after { content: "\10f809"; }
.fad.@{fa-css-prefix}-head-vr:after { content: "\10f6ea"; }
.fad.@{fa-css-prefix}-heading:after { content: "\10f1dc"; }
.fad.@{fa-css-prefix}-headphones:after { content: "\10f025"; }
.fad.@{fa-css-prefix}-headphones-alt:after { content: "\10f58f"; }
.fad.@{fa-css-prefix}-headset:after { content: "\10f590"; }
.fad.@{fa-css-prefix}-heart:after { content: "\10f004"; }
.fad.@{fa-css-prefix}-heart-broken:after { content: "\10f7a9"; }
.fad.@{fa-css-prefix}-heart-circle:after { content: "\10f4c7"; }
.fad.@{fa-css-prefix}-heart-rate:after { content: "\10f5f8"; }
.fad.@{fa-css-prefix}-heart-square:after { content: "\10f4c8"; }
.fad.@{fa-css-prefix}-heartbeat:after { content: "\10f21e"; }
.fad.@{fa-css-prefix}-heat:after { content: "\10f90c"; }
.fad.@{fa-css-prefix}-helicopter:after { content: "\10f533"; }
.fad.@{fa-css-prefix}-helmet-battle:after { content: "\10f6eb"; }
.fad.@{fa-css-prefix}-hexagon:after { content: "\10f312"; }
.fad.@{fa-css-prefix}-highlighter:after { content: "\10f591"; }
.fad.@{fa-css-prefix}-hiking:after { content: "\10f6ec"; }
.fad.@{fa-css-prefix}-hippo:after { content: "\10f6ed"; }
.fad.@{fa-css-prefix}-history:after { content: "\10f1da"; }
.fad.@{fa-css-prefix}-hockey-mask:after { content: "\10f6ee"; }
.fad.@{fa-css-prefix}-hockey-puck:after { content: "\10f453"; }
.fad.@{fa-css-prefix}-hockey-sticks:after { content: "\10f454"; }
.fad.@{fa-css-prefix}-holly-berry:after { content: "\10f7aa"; }
.fad.@{fa-css-prefix}-home:after { content: "\10f015"; }
.fad.@{fa-css-prefix}-home-alt:after { content: "\10f80a"; }
.fad.@{fa-css-prefix}-home-heart:after { content: "\10f4c9"; }
.fad.@{fa-css-prefix}-home-lg:after { content: "\10f80b"; }
.fad.@{fa-css-prefix}-home-lg-alt:after { content: "\10f80c"; }
.fad.@{fa-css-prefix}-hood-cloak:after { content: "\10f6ef"; }
.fad.@{fa-css-prefix}-horizontal-rule:after { content: "\10f86c"; }
.fad.@{fa-css-prefix}-horse:after { content: "\10f6f0"; }
.fad.@{fa-css-prefix}-horse-head:after { content: "\10f7ab"; }
.fad.@{fa-css-prefix}-horse-saddle:after { content: "\10f8c3"; }
.fad.@{fa-css-prefix}-hospital:after { content: "\10f0f8"; }
.fad.@{fa-css-prefix}-hospital-alt:after { content: "\10f47d"; }
.fad.@{fa-css-prefix}-hospital-symbol:after { content: "\10f47e"; }
.fad.@{fa-css-prefix}-hospital-user:after { content: "\10f80d"; }
.fad.@{fa-css-prefix}-hospitals:after { content: "\10f80e"; }
.fad.@{fa-css-prefix}-hot-tub:after { content: "\10f593"; }
.fad.@{fa-css-prefix}-hotdog:after { content: "\10f80f"; }
.fad.@{fa-css-prefix}-hotel:after { content: "\10f594"; }
.fad.@{fa-css-prefix}-hourglass:after { content: "\10f254"; }
.fad.@{fa-css-prefix}-hourglass-end:after { content: "\10f253"; }
.fad.@{fa-css-prefix}-hourglass-half:after { content: "\10f252"; }
.fad.@{fa-css-prefix}-hourglass-start:after { content: "\10f251"; }
.fad.@{fa-css-prefix}-house:after { content: "\10f90d"; }
.fad.@{fa-css-prefix}-house-damage:after { content: "\10f6f1"; }
.fad.@{fa-css-prefix}-house-day:after { content: "\10f90e"; }
.fad.@{fa-css-prefix}-house-flood:after { content: "\10f74f"; }
.fad.@{fa-css-prefix}-house-leave:after { content: "\10f90f"; }
.fad.@{fa-css-prefix}-house-night:after { content: "\10f910"; }
.fad.@{fa-css-prefix}-house-return:after { content: "\10f911"; }
.fad.@{fa-css-prefix}-house-signal:after { content: "\10f912"; }
.fad.@{fa-css-prefix}-hryvnia:after { content: "\10f6f2"; }
.fad.@{fa-css-prefix}-humidity:after { content: "\10f750"; }
.fad.@{fa-css-prefix}-hurricane:after { content: "\10f751"; }
.fad.@{fa-css-prefix}-i-cursor:after { content: "\10f246"; }
.fad.@{fa-css-prefix}-ice-cream:after { content: "\10f810"; }
.fad.@{fa-css-prefix}-ice-skate:after { content: "\10f7ac"; }
.fad.@{fa-css-prefix}-icicles:after { content: "\10f7ad"; }
.fad.@{fa-css-prefix}-icons:after { content: "\10f86d"; }
.fad.@{fa-css-prefix}-icons-alt:after { content: "\10f86e"; }
.fad.@{fa-css-prefix}-id-badge:after { content: "\10f2c1"; }
.fad.@{fa-css-prefix}-id-card:after { content: "\10f2c2"; }
.fad.@{fa-css-prefix}-id-card-alt:after { content: "\10f47f"; }
.fad.@{fa-css-prefix}-igloo:after { content: "\10f7ae"; }
.fad.@{fa-css-prefix}-image:after { content: "\10f03e"; }
.fad.@{fa-css-prefix}-image-polaroid:after { content: "\10f8c4"; }
.fad.@{fa-css-prefix}-images:after { content: "\10f302"; }
.fad.@{fa-css-prefix}-inbox:after { content: "\10f01c"; }
.fad.@{fa-css-prefix}-inbox-in:after { content: "\10f310"; }
.fad.@{fa-css-prefix}-inbox-out:after { content: "\10f311"; }
.fad.@{fa-css-prefix}-indent:after { content: "\10f03c"; }
.fad.@{fa-css-prefix}-industry:after { content: "\10f275"; }
.fad.@{fa-css-prefix}-industry-alt:after { content: "\10f3b3"; }
.fad.@{fa-css-prefix}-infinity:after { content: "\10f534"; }
.fad.@{fa-css-prefix}-info:after { content: "\10f129"; }
.fad.@{fa-css-prefix}-info-circle:after { content: "\10f05a"; }
.fad.@{fa-css-prefix}-info-square:after { content: "\10f30f"; }
.fad.@{fa-css-prefix}-inhaler:after { content: "\10f5f9"; }
.fad.@{fa-css-prefix}-integral:after { content: "\10f667"; }
.fad.@{fa-css-prefix}-intersection:after { content: "\10f668"; }
.fad.@{fa-css-prefix}-inventory:after { content: "\10f480"; }
.fad.@{fa-css-prefix}-island-tropical:after { content: "\10f811"; }
.fad.@{fa-css-prefix}-italic:after { content: "\10f033"; }
.fad.@{fa-css-prefix}-jack-o-lantern:after { content: "\10f30e"; }
.fad.@{fa-css-prefix}-jedi:after { content: "\10f669"; }
.fad.@{fa-css-prefix}-joint:after { content: "\10f595"; }
.fad.@{fa-css-prefix}-journal-whills:after { content: "\10f66a"; }
.fad.@{fa-css-prefix}-joystick:after { content: "\10f8c5"; }
.fad.@{fa-css-prefix}-jug:after { content: "\10f8c6"; }
.fad.@{fa-css-prefix}-kaaba:after { content: "\10f66b"; }
.fad.@{fa-css-prefix}-kazoo:after { content: "\10f8c7"; }
.fad.@{fa-css-prefix}-kerning:after { content: "\10f86f"; }
.fad.@{fa-css-prefix}-key:after { content: "\10f084"; }
.fad.@{fa-css-prefix}-key-skeleton:after { content: "\10f6f3"; }
.fad.@{fa-css-prefix}-keyboard:after { content: "\10f11c"; }
.fad.@{fa-css-prefix}-keynote:after { content: "\10f66c"; }
.fad.@{fa-css-prefix}-khanda:after { content: "\10f66d"; }
.fad.@{fa-css-prefix}-kidneys:after { content: "\10f5fb"; }
.fad.@{fa-css-prefix}-kiss:after { content: "\10f596"; }
.fad.@{fa-css-prefix}-kiss-beam:after { content: "\10f597"; }
.fad.@{fa-css-prefix}-kiss-wink-heart:after { content: "\10f598"; }
.fad.@{fa-css-prefix}-kite:after { content: "\10f6f4"; }
.fad.@{fa-css-prefix}-kiwi-bird:after { content: "\10f535"; }
.fad.@{fa-css-prefix}-knife-kitchen:after { content: "\10f6f5"; }
.fad.@{fa-css-prefix}-lambda:after { content: "\10f66e"; }
.fad.@{fa-css-prefix}-lamp:after { content: "\10f4ca"; }
.fad.@{fa-css-prefix}-lamp-desk:after { content: "\10f914"; }
.fad.@{fa-css-prefix}-lamp-floor:after { content: "\10f915"; }
.fad.@{fa-css-prefix}-landmark:after { content: "\10f66f"; }
.fad.@{fa-css-prefix}-landmark-alt:after { content: "\10f752"; }
.fad.@{fa-css-prefix}-language:after { content: "\10f1ab"; }
.fad.@{fa-css-prefix}-laptop:after { content: "\10f109"; }
.fad.@{fa-css-prefix}-laptop-code:after { content: "\10f5fc"; }
.fad.@{fa-css-prefix}-laptop-medical:after { content: "\10f812"; }
.fad.@{fa-css-prefix}-lasso:after { content: "\10f8c8"; }
.fad.@{fa-css-prefix}-laugh:after { content: "\10f599"; }
.fad.@{fa-css-prefix}-laugh-beam:after { content: "\10f59a"; }
.fad.@{fa-css-prefix}-laugh-squint:after { content: "\10f59b"; }
.fad.@{fa-css-prefix}-laugh-wink:after { content: "\10f59c"; }
.fad.@{fa-css-prefix}-layer-group:after { content: "\10f5fd"; }
.fad.@{fa-css-prefix}-layer-minus:after { content: "\10f5fe"; }
.fad.@{fa-css-prefix}-layer-plus:after { content: "\10f5ff"; }
.fad.@{fa-css-prefix}-leaf:after { content: "\10f06c"; }
.fad.@{fa-css-prefix}-leaf-heart:after { content: "\10f4cb"; }
.fad.@{fa-css-prefix}-leaf-maple:after { content: "\10f6f6"; }
.fad.@{fa-css-prefix}-leaf-oak:after { content: "\10f6f7"; }
.fad.@{fa-css-prefix}-lemon:after { content: "\10f094"; }
.fad.@{fa-css-prefix}-less-than:after { content: "\10f536"; }
.fad.@{fa-css-prefix}-less-than-equal:after { content: "\10f537"; }
.fad.@{fa-css-prefix}-level-down:after { content: "\10f149"; }
.fad.@{fa-css-prefix}-level-down-alt:after { content: "\10f3be"; }
.fad.@{fa-css-prefix}-level-up:after { content: "\10f148"; }
.fad.@{fa-css-prefix}-level-up-alt:after { content: "\10f3bf"; }
.fad.@{fa-css-prefix}-life-ring:after { content: "\10f1cd"; }
.fad.@{fa-css-prefix}-light-ceiling:after { content: "\10f916"; }
.fad.@{fa-css-prefix}-light-switch:after { content: "\10f917"; }
.fad.@{fa-css-prefix}-light-switch-off:after { content: "\10f918"; }
.fad.@{fa-css-prefix}-light-switch-on:after { content: "\10f919"; }
.fad.@{fa-css-prefix}-lightbulb:after { content: "\10f0eb"; }
.fad.@{fa-css-prefix}-lightbulb-dollar:after { content: "\10f670"; }
.fad.@{fa-css-prefix}-lightbulb-exclamation:after { content: "\10f671"; }
.fad.@{fa-css-prefix}-lightbulb-on:after { content: "\10f672"; }
.fad.@{fa-css-prefix}-lightbulb-slash:after { content: "\10f673"; }
.fad.@{fa-css-prefix}-lights-holiday:after { content: "\10f7b2"; }
.fad.@{fa-css-prefix}-line-columns:after { content: "\10f870"; }
.fad.@{fa-css-prefix}-line-height:after { content: "\10f871"; }
.fad.@{fa-css-prefix}-link:after { content: "\10f0c1"; }
.fad.@{fa-css-prefix}-lips:after { content: "\10f600"; }
.fad.@{fa-css-prefix}-lira-sign:after { content: "\10f195"; }
.fad.@{fa-css-prefix}-list:after { content: "\10f03a"; }
.fad.@{fa-css-prefix}-list-alt:after { content: "\10f022"; }
.fad.@{fa-css-prefix}-list-music:after { content: "\10f8c9"; }
.fad.@{fa-css-prefix}-list-ol:after { content: "\10f0cb"; }
.fad.@{fa-css-prefix}-list-ul:after { content: "\10f0ca"; }
.fad.@{fa-css-prefix}-location:after { content: "\10f601"; }
.fad.@{fa-css-prefix}-location-arrow:after { content: "\10f124"; }
.fad.@{fa-css-prefix}-location-circle:after { content: "\10f602"; }
.fad.@{fa-css-prefix}-location-slash:after { content: "\10f603"; }
.fad.@{fa-css-prefix}-lock:after { content: "\10f023"; }
.fad.@{fa-css-prefix}-lock-alt:after { content: "\10f30d"; }
.fad.@{fa-css-prefix}-lock-open:after { content: "\10f3c1"; }
.fad.@{fa-css-prefix}-lock-open-alt:after { content: "\10f3c2"; }
.fad.@{fa-css-prefix}-long-arrow-alt-down:after { content: "\10f309"; }
.fad.@{fa-css-prefix}-long-arrow-alt-left:after { content: "\10f30a"; }
.fad.@{fa-css-prefix}-long-arrow-alt-right:after { content: "\10f30b"; }
.fad.@{fa-css-prefix}-long-arrow-alt-up:after { content: "\10f30c"; }
.fad.@{fa-css-prefix}-long-arrow-down:after { content: "\10f175"; }
.fad.@{fa-css-prefix}-long-arrow-left:after { content: "\10f177"; }
.fad.@{fa-css-prefix}-long-arrow-right:after { content: "\10f178"; }
.fad.@{fa-css-prefix}-long-arrow-up:after { content: "\10f176"; }
.fad.@{fa-css-prefix}-loveseat:after { content: "\10f4cc"; }
.fad.@{fa-css-prefix}-low-vision:after { content: "\10f2a8"; }
.fad.@{fa-css-prefix}-luchador:after { content: "\10f455"; }
.fad.@{fa-css-prefix}-luggage-cart:after { content: "\10f59d"; }
.fad.@{fa-css-prefix}-lungs:after { content: "\10f604"; }
.fad.@{fa-css-prefix}-mace:after { content: "\10f6f8"; }
.fad.@{fa-css-prefix}-magic:after { content: "\10f0d0"; }
.fad.@{fa-css-prefix}-magnet:after { content: "\10f076"; }
.fad.@{fa-css-prefix}-mail-bulk:after { content: "\10f674"; }
.fad.@{fa-css-prefix}-mailbox:after { content: "\10f813"; }
.fad.@{fa-css-prefix}-male:after { content: "\10f183"; }
.fad.@{fa-css-prefix}-mandolin:after { content: "\10f6f9"; }
.fad.@{fa-css-prefix}-map:after { content: "\10f279"; }
.fad.@{fa-css-prefix}-map-marked:after { content: "\10f59f"; }
.fad.@{fa-css-prefix}-map-marked-alt:after { content: "\10f5a0"; }
.fad.@{fa-css-prefix}-map-marker:after { content: "\10f041"; }
.fad.@{fa-css-prefix}-map-marker-alt:after { content: "\10f3c5"; }
.fad.@{fa-css-prefix}-map-marker-alt-slash:after { content: "\10f605"; }
.fad.@{fa-css-prefix}-map-marker-check:after { content: "\10f606"; }
.fad.@{fa-css-prefix}-map-marker-edit:after { content: "\10f607"; }
.fad.@{fa-css-prefix}-map-marker-exclamation:after { content: "\10f608"; }
.fad.@{fa-css-prefix}-map-marker-minus:after { content: "\10f609"; }
.fad.@{fa-css-prefix}-map-marker-plus:after { content: "\10f60a"; }
.fad.@{fa-css-prefix}-map-marker-question:after { content: "\10f60b"; }
.fad.@{fa-css-prefix}-map-marker-slash:after { content: "\10f60c"; }
.fad.@{fa-css-prefix}-map-marker-smile:after { content: "\10f60d"; }
.fad.@{fa-css-prefix}-map-marker-times:after { content: "\10f60e"; }
.fad.@{fa-css-prefix}-map-pin:after { content: "\10f276"; }
.fad.@{fa-css-prefix}-map-signs:after { content: "\10f277"; }
.fad.@{fa-css-prefix}-marker:after { content: "\10f5a1"; }
.fad.@{fa-css-prefix}-mars:after { content: "\10f222"; }
.fad.@{fa-css-prefix}-mars-double:after { content: "\10f227"; }
.fad.@{fa-css-prefix}-mars-stroke:after { content: "\10f229"; }
.fad.@{fa-css-prefix}-mars-stroke-h:after { content: "\10f22b"; }
.fad.@{fa-css-prefix}-mars-stroke-v:after { content: "\10f22a"; }
.fad.@{fa-css-prefix}-mask:after { content: "\10f6fa"; }
.fad.@{fa-css-prefix}-meat:after { content: "\10f814"; }
.fad.@{fa-css-prefix}-medal:after { content: "\10f5a2"; }
.fad.@{fa-css-prefix}-medkit:after { content: "\10f0fa"; }
.fad.@{fa-css-prefix}-megaphone:after { content: "\10f675"; }
.fad.@{fa-css-prefix}-meh:after { content: "\10f11a"; }
.fad.@{fa-css-prefix}-meh-blank:after { content: "\10f5a4"; }
.fad.@{fa-css-prefix}-meh-rolling-eyes:after { content: "\10f5a5"; }
.fad.@{fa-css-prefix}-memory:after { content: "\10f538"; }
.fad.@{fa-css-prefix}-menorah:after { content: "\10f676"; }
.fad.@{fa-css-prefix}-mercury:after { content: "\10f223"; }
.fad.@{fa-css-prefix}-meteor:after { content: "\10f753"; }
.fad.@{fa-css-prefix}-microchip:after { content: "\10f2db"; }
.fad.@{fa-css-prefix}-microphone:after { content: "\10f130"; }
.fad.@{fa-css-prefix}-microphone-alt:after { content: "\10f3c9"; }
.fad.@{fa-css-prefix}-microphone-alt-slash:after { content: "\10f539"; }
.fad.@{fa-css-prefix}-microphone-slash:after { content: "\10f131"; }
.fad.@{fa-css-prefix}-microphone-stand:after { content: "\10f8cb"; }
.fad.@{fa-css-prefix}-microscope:after { content: "\10f610"; }
.fad.@{fa-css-prefix}-microwave:after { content: "\10f91b"; }
.fad.@{fa-css-prefix}-mind-share:after { content: "\10f677"; }
.fad.@{fa-css-prefix}-minus:after { content: "\10f068"; }
.fad.@{fa-css-prefix}-minus-circle:after { content: "\10f056"; }
.fad.@{fa-css-prefix}-minus-hexagon:after { content: "\10f307"; }
.fad.@{fa-css-prefix}-minus-octagon:after { content: "\10f308"; }
.fad.@{fa-css-prefix}-minus-square:after { content: "\10f146"; }
.fad.@{fa-css-prefix}-mistletoe:after { content: "\10f7b4"; }
.fad.@{fa-css-prefix}-mitten:after { content: "\10f7b5"; }
.fad.@{fa-css-prefix}-mobile:after { content: "\10f10b"; }
.fad.@{fa-css-prefix}-mobile-alt:after { content: "\10f3cd"; }
.fad.@{fa-css-prefix}-mobile-android:after { content: "\10f3ce"; }
.fad.@{fa-css-prefix}-mobile-android-alt:after { content: "\10f3cf"; }
.fad.@{fa-css-prefix}-money-bill:after { content: "\10f0d6"; }
.fad.@{fa-css-prefix}-money-bill-alt:after { content: "\10f3d1"; }
.fad.@{fa-css-prefix}-money-bill-wave:after { content: "\10f53a"; }
.fad.@{fa-css-prefix}-money-bill-wave-alt:after { content: "\10f53b"; }
.fad.@{fa-css-prefix}-money-check:after { content: "\10f53c"; }
.fad.@{fa-css-prefix}-money-check-alt:after { content: "\10f53d"; }
.fad.@{fa-css-prefix}-money-check-edit:after { content: "\10f872"; }
.fad.@{fa-css-prefix}-money-check-edit-alt:after { content: "\10f873"; }
.fad.@{fa-css-prefix}-monitor-heart-rate:after { content: "\10f611"; }
.fad.@{fa-css-prefix}-monkey:after { content: "\10f6fb"; }
.fad.@{fa-css-prefix}-monument:after { content: "\10f5a6"; }
.fad.@{fa-css-prefix}-moon:after { content: "\10f186"; }
.fad.@{fa-css-prefix}-moon-cloud:after { content: "\10f754"; }
.fad.@{fa-css-prefix}-moon-stars:after { content: "\10f755"; }
.fad.@{fa-css-prefix}-mortar-pestle:after { content: "\10f5a7"; }
.fad.@{fa-css-prefix}-mosque:after { content: "\10f678"; }
.fad.@{fa-css-prefix}-motorcycle:after { content: "\10f21c"; }
.fad.@{fa-css-prefix}-mountain:after { content: "\10f6fc"; }
.fad.@{fa-css-prefix}-mountains:after { content: "\10f6fd"; }
.fad.@{fa-css-prefix}-mouse:after { content: "\10f8cc"; }
.fad.@{fa-css-prefix}-mouse-alt:after { content: "\10f8cd"; }
.fad.@{fa-css-prefix}-mouse-pointer:after { content: "\10f245"; }
.fad.@{fa-css-prefix}-mp3-player:after { content: "\10f8ce"; }
.fad.@{fa-css-prefix}-mug:after { content: "\10f874"; }
.fad.@{fa-css-prefix}-mug-hot:after { content: "\10f7b6"; }
.fad.@{fa-css-prefix}-mug-marshmallows:after { content: "\10f7b7"; }
.fad.@{fa-css-prefix}-mug-tea:after { content: "\10f875"; }
.fad.@{fa-css-prefix}-music:after { content: "\10f001"; }
.fad.@{fa-css-prefix}-music-alt:after { content: "\10f8cf"; }
.fad.@{fa-css-prefix}-music-alt-slash:after { content: "\10f8d0"; }
.fad.@{fa-css-prefix}-music-slash:after { content: "\10f8d1"; }
.fad.@{fa-css-prefix}-narwhal:after { content: "\10f6fe"; }
.fad.@{fa-css-prefix}-network-wired:after { content: "\10f6ff"; }
.fad.@{fa-css-prefix}-neuter:after { content: "\10f22c"; }
.fad.@{fa-css-prefix}-newspaper:after { content: "\10f1ea"; }
.fad.@{fa-css-prefix}-not-equal:after { content: "\10f53e"; }
.fad.@{fa-css-prefix}-notes-medical:after { content: "\10f481"; }
.fad.@{fa-css-prefix}-object-group:after { content: "\10f247"; }
.fad.@{fa-css-prefix}-object-ungroup:after { content: "\10f248"; }
.fad.@{fa-css-prefix}-octagon:after { content: "\10f306"; }
.fad.@{fa-css-prefix}-oil-can:after { content: "\10f613"; }
.fad.@{fa-css-prefix}-oil-temp:after { content: "\10f614"; }
.fad.@{fa-css-prefix}-om:after { content: "\10f679"; }
.fad.@{fa-css-prefix}-omega:after { content: "\10f67a"; }
.fad.@{fa-css-prefix}-ornament:after { content: "\10f7b8"; }
.fad.@{fa-css-prefix}-otter:after { content: "\10f700"; }
.fad.@{fa-css-prefix}-outdent:after { content: "\10f03b"; }
.fad.@{fa-css-prefix}-outlet:after { content: "\10f91c"; }
.fad.@{fa-css-prefix}-oven:after { content: "\10f91d"; }
.fad.@{fa-css-prefix}-overline:after { content: "\10f876"; }
.fad.@{fa-css-prefix}-page-break:after { content: "\10f877"; }
.fad.@{fa-css-prefix}-pager:after { content: "\10f815"; }
.fad.@{fa-css-prefix}-paint-brush:after { content: "\10f1fc"; }
.fad.@{fa-css-prefix}-paint-brush-alt:after { content: "\10f5a9"; }
.fad.@{fa-css-prefix}-paint-roller:after { content: "\10f5aa"; }
.fad.@{fa-css-prefix}-palette:after { content: "\10f53f"; }
.fad.@{fa-css-prefix}-pallet:after { content: "\10f482"; }
.fad.@{fa-css-prefix}-pallet-alt:after { content: "\10f483"; }
.fad.@{fa-css-prefix}-paper-plane:after { content: "\10f1d8"; }
.fad.@{fa-css-prefix}-paperclip:after { content: "\10f0c6"; }
.fad.@{fa-css-prefix}-parachute-box:after { content: "\10f4cd"; }
.fad.@{fa-css-prefix}-paragraph:after { content: "\10f1dd"; }
.fad.@{fa-css-prefix}-paragraph-rtl:after { content: "\10f878"; }
.fad.@{fa-css-prefix}-parking:after { content: "\10f540"; }
.fad.@{fa-css-prefix}-parking-circle:after { content: "\10f615"; }
.fad.@{fa-css-prefix}-parking-circle-slash:after { content: "\10f616"; }
.fad.@{fa-css-prefix}-parking-slash:after { content: "\10f617"; }
.fad.@{fa-css-prefix}-passport:after { content: "\10f5ab"; }
.fad.@{fa-css-prefix}-pastafarianism:after { content: "\10f67b"; }
.fad.@{fa-css-prefix}-paste:after { content: "\10f0ea"; }
.fad.@{fa-css-prefix}-pause:after { content: "\10f04c"; }
.fad.@{fa-css-prefix}-pause-circle:after { content: "\10f28b"; }
.fad.@{fa-css-prefix}-paw:after { content: "\10f1b0"; }
.fad.@{fa-css-prefix}-paw-alt:after { content: "\10f701"; }
.fad.@{fa-css-prefix}-paw-claws:after { content: "\10f702"; }
.fad.@{fa-css-prefix}-peace:after { content: "\10f67c"; }
.fad.@{fa-css-prefix}-pegasus:after { content: "\10f703"; }
.fad.@{fa-css-prefix}-pen:after { content: "\10f304"; }
.fad.@{fa-css-prefix}-pen-alt:after { content: "\10f305"; }
.fad.@{fa-css-prefix}-pen-fancy:after { content: "\10f5ac"; }
.fad.@{fa-css-prefix}-pen-nib:after { content: "\10f5ad"; }
.fad.@{fa-css-prefix}-pen-square:after { content: "\10f14b"; }
.fad.@{fa-css-prefix}-pencil:after { content: "\10f040"; }
.fad.@{fa-css-prefix}-pencil-alt:after { content: "\10f303"; }
.fad.@{fa-css-prefix}-pencil-paintbrush:after { content: "\10f618"; }
.fad.@{fa-css-prefix}-pencil-ruler:after { content: "\10f5ae"; }
.fad.@{fa-css-prefix}-pennant:after { content: "\10f456"; }
.fad.@{fa-css-prefix}-people-carry:after { content: "\10f4ce"; }
.fad.@{fa-css-prefix}-pepper-hot:after { content: "\10f816"; }
.fad.@{fa-css-prefix}-percent:after { content: "\10f295"; }
.fad.@{fa-css-prefix}-percentage:after { content: "\10f541"; }
.fad.@{fa-css-prefix}-person-booth:after { content: "\10f756"; }
.fad.@{fa-css-prefix}-person-carry:after { content: "\10f4cf"; }
.fad.@{fa-css-prefix}-person-dolly:after { content: "\10f4d0"; }
.fad.@{fa-css-prefix}-person-dolly-empty:after { content: "\10f4d1"; }
.fad.@{fa-css-prefix}-person-sign:after { content: "\10f757"; }
.fad.@{fa-css-prefix}-phone:after { content: "\10f095"; }
.fad.@{fa-css-prefix}-phone-alt:after { content: "\10f879"; }
.fad.@{fa-css-prefix}-phone-laptop:after { content: "\10f87a"; }
.fad.@{fa-css-prefix}-phone-office:after { content: "\10f67d"; }
.fad.@{fa-css-prefix}-phone-plus:after { content: "\10f4d2"; }
.fad.@{fa-css-prefix}-phone-rotary:after { content: "\10f8d3"; }
.fad.@{fa-css-prefix}-phone-slash:after { content: "\10f3dd"; }
.fad.@{fa-css-prefix}-phone-square:after { content: "\10f098"; }
.fad.@{fa-css-prefix}-phone-square-alt:after { content: "\10f87b"; }
.fad.@{fa-css-prefix}-phone-volume:after { content: "\10f2a0"; }
.fad.@{fa-css-prefix}-photo-video:after { content: "\10f87c"; }
.fad.@{fa-css-prefix}-pi:after { content: "\10f67e"; }
.fad.@{fa-css-prefix}-piano:after { content: "\10f8d4"; }
.fad.@{fa-css-prefix}-piano-keyboard:after { content: "\10f8d5"; }
.fad.@{fa-css-prefix}-pie:after { content: "\10f705"; }
.fad.@{fa-css-prefix}-pig:after { content: "\10f706"; }
.fad.@{fa-css-prefix}-piggy-bank:after { content: "\10f4d3"; }
.fad.@{fa-css-prefix}-pills:after { content: "\10f484"; }
.fad.@{fa-css-prefix}-pizza:after { content: "\10f817"; }
.fad.@{fa-css-prefix}-pizza-slice:after { content: "\10f818"; }
.fad.@{fa-css-prefix}-place-of-worship:after { content: "\10f67f"; }
.fad.@{fa-css-prefix}-plane:after { content: "\10f072"; }
.fad.@{fa-css-prefix}-plane-alt:after { content: "\10f3de"; }
.fad.@{fa-css-prefix}-plane-arrival:after { content: "\10f5af"; }
.fad.@{fa-css-prefix}-plane-departure:after { content: "\10f5b0"; }
.fad.@{fa-css-prefix}-planet-moon:after { content: "\10f91f"; }
.fad.@{fa-css-prefix}-planet-ringed:after { content: "\10f920"; }
.fad.@{fa-css-prefix}-play:after { content: "\10f04b"; }
.fad.@{fa-css-prefix}-play-circle:after { content: "\10f144"; }
.fad.@{fa-css-prefix}-plug:after { content: "\10f1e6"; }
.fad.@{fa-css-prefix}-plus:after { content: "\10f067"; }
.fad.@{fa-css-prefix}-plus-circle:after { content: "\10f055"; }
.fad.@{fa-css-prefix}-plus-hexagon:after { content: "\10f300"; }
.fad.@{fa-css-prefix}-plus-octagon:after { content: "\10f301"; }
.fad.@{fa-css-prefix}-plus-square:after { content: "\10f0fe"; }
.fad.@{fa-css-prefix}-podcast:after { content: "\10f2ce"; }
.fad.@{fa-css-prefix}-podium:after { content: "\10f680"; }
.fad.@{fa-css-prefix}-podium-star:after { content: "\10f758"; }
.fad.@{fa-css-prefix}-police-box:after { content: "\10f921"; }
.fad.@{fa-css-prefix}-poll:after { content: "\10f681"; }
.fad.@{fa-css-prefix}-poll-h:after { content: "\10f682"; }
.fad.@{fa-css-prefix}-poll-people:after { content: "\10f759"; }
.fad.@{fa-css-prefix}-poo:after { content: "\10f2fe"; }
.fad.@{fa-css-prefix}-poo-storm:after { content: "\10f75a"; }
.fad.@{fa-css-prefix}-poop:after { content: "\10f619"; }
.fad.@{fa-css-prefix}-popcorn:after { content: "\10f819"; }
.fad.@{fa-css-prefix}-portal-enter:after { content: "\10f922"; }
.fad.@{fa-css-prefix}-portal-exit:after { content: "\10f923"; }
.fad.@{fa-css-prefix}-portrait:after { content: "\10f3e0"; }
.fad.@{fa-css-prefix}-pound-sign:after { content: "\10f154"; }
.fad.@{fa-css-prefix}-power-off:after { content: "\10f011"; }
.fad.@{fa-css-prefix}-pray:after { content: "\10f683"; }
.fad.@{fa-css-prefix}-praying-hands:after { content: "\10f684"; }
.fad.@{fa-css-prefix}-prescription:after { content: "\10f5b1"; }
.fad.@{fa-css-prefix}-prescription-bottle:after { content: "\10f485"; }
.fad.@{fa-css-prefix}-prescription-bottle-alt:after { content: "\10f486"; }
.fad.@{fa-css-prefix}-presentation:after { content: "\10f685"; }
.fad.@{fa-css-prefix}-print:after { content: "\10f02f"; }
.fad.@{fa-css-prefix}-print-search:after { content: "\10f81a"; }
.fad.@{fa-css-prefix}-print-slash:after { content: "\10f686"; }
.fad.@{fa-css-prefix}-procedures:after { content: "\10f487"; }
.fad.@{fa-css-prefix}-project-diagram:after { content: "\10f542"; }
.fad.@{fa-css-prefix}-projector:after { content: "\10f8d6"; }
.fad.@{fa-css-prefix}-pumpkin:after { content: "\10f707"; }
.fad.@{fa-css-prefix}-puzzle-piece:after { content: "\10f12e"; }
.fad.@{fa-css-prefix}-qrcode:after { content: "\10f029"; }
.fad.@{fa-css-prefix}-question:after { content: "\10f128"; }
.fad.@{fa-css-prefix}-question-circle:after { content: "\10f059"; }
.fad.@{fa-css-prefix}-question-square:after { content: "\10f2fd"; }
.fad.@{fa-css-prefix}-quidditch:after { content: "\10f458"; }
.fad.@{fa-css-prefix}-quote-left:after { content: "\10f10d"; }
.fad.@{fa-css-prefix}-quote-right:after { content: "\10f10e"; }
.fad.@{fa-css-prefix}-quran:after { content: "\10f687"; }
.fad.@{fa-css-prefix}-rabbit:after { content: "\10f708"; }
.fad.@{fa-css-prefix}-rabbit-fast:after { content: "\10f709"; }
.fad.@{fa-css-prefix}-racquet:after { content: "\10f45a"; }
.fad.@{fa-css-prefix}-radar:after { content: "\10f924"; }
.fad.@{fa-css-prefix}-radiation:after { content: "\10f7b9"; }
.fad.@{fa-css-prefix}-radiation-alt:after { content: "\10f7ba"; }
.fad.@{fa-css-prefix}-radio:after { content: "\10f8d7"; }
.fad.@{fa-css-prefix}-radio-alt:after { content: "\10f8d8"; }
.fad.@{fa-css-prefix}-rainbow:after { content: "\10f75b"; }
.fad.@{fa-css-prefix}-raindrops:after { content: "\10f75c"; }
.fad.@{fa-css-prefix}-ram:after { content: "\10f70a"; }
.fad.@{fa-css-prefix}-ramp-loading:after { content: "\10f4d4"; }
.fad.@{fa-css-prefix}-random:after { content: "\10f074"; }
.fad.@{fa-css-prefix}-raygun:after { content: "\10f925"; }
.fad.@{fa-css-prefix}-receipt:after { content: "\10f543"; }
.fad.@{fa-css-prefix}-record-vinyl:after { content: "\10f8d9"; }
.fad.@{fa-css-prefix}-rectangle-landscape:after { content: "\10f2fa"; }
.fad.@{fa-css-prefix}-rectangle-portrait:after { content: "\10f2fb"; }
.fad.@{fa-css-prefix}-rectangle-wide:after { content: "\10f2fc"; }
.fad.@{fa-css-prefix}-recycle:after { content: "\10f1b8"; }
.fad.@{fa-css-prefix}-redo:after { content: "\10f01e"; }
.fad.@{fa-css-prefix}-redo-alt:after { content: "\10f2f9"; }
.fad.@{fa-css-prefix}-refrigerator:after { content: "\10f926"; }
.fad.@{fa-css-prefix}-registered:after { content: "\10f25d"; }
.fad.@{fa-css-prefix}-remove-format:after { content: "\10f87d"; }
.fad.@{fa-css-prefix}-repeat:after { content: "\10f363"; }
.fad.@{fa-css-prefix}-repeat-1:after { content: "\10f365"; }
.fad.@{fa-css-prefix}-repeat-1-alt:after { content: "\10f366"; }
.fad.@{fa-css-prefix}-repeat-alt:after { content: "\10f364"; }
.fad.@{fa-css-prefix}-reply:after { content: "\10f3e5"; }
.fad.@{fa-css-prefix}-reply-all:after { content: "\10f122"; }
.fad.@{fa-css-prefix}-republican:after { content: "\10f75e"; }
.fad.@{fa-css-prefix}-restroom:after { content: "\10f7bd"; }
.fad.@{fa-css-prefix}-retweet:after { content: "\10f079"; }
.fad.@{fa-css-prefix}-retweet-alt:after { content: "\10f361"; }
.fad.@{fa-css-prefix}-ribbon:after { content: "\10f4d6"; }
.fad.@{fa-css-prefix}-ring:after { content: "\10f70b"; }
.fad.@{fa-css-prefix}-rings-wedding:after { content: "\10f81b"; }
.fad.@{fa-css-prefix}-road:after { content: "\10f018"; }
.fad.@{fa-css-prefix}-robot:after { content: "\10f544"; }
.fad.@{fa-css-prefix}-rocket:after { content: "\10f135"; }
.fad.@{fa-css-prefix}-rocket-launch:after { content: "\10f927"; }
.fad.@{fa-css-prefix}-route:after { content: "\10f4d7"; }
.fad.@{fa-css-prefix}-route-highway:after { content: "\10f61a"; }
.fad.@{fa-css-prefix}-route-interstate:after { content: "\10f61b"; }
.fad.@{fa-css-prefix}-router:after { content: "\10f8da"; }
.fad.@{fa-css-prefix}-rss:after { content: "\10f09e"; }
.fad.@{fa-css-prefix}-rss-square:after { content: "\10f143"; }
.fad.@{fa-css-prefix}-ruble-sign:after { content: "\10f158"; }
.fad.@{fa-css-prefix}-ruler:after { content: "\10f545"; }
.fad.@{fa-css-prefix}-ruler-combined:after { content: "\10f546"; }
.fad.@{fa-css-prefix}-ruler-horizontal:after { content: "\10f547"; }
.fad.@{fa-css-prefix}-ruler-triangle:after { content: "\10f61c"; }
.fad.@{fa-css-prefix}-ruler-vertical:after { content: "\10f548"; }
.fad.@{fa-css-prefix}-running:after { content: "\10f70c"; }
.fad.@{fa-css-prefix}-rupee-sign:after { content: "\10f156"; }
.fad.@{fa-css-prefix}-rv:after { content: "\10f7be"; }
.fad.@{fa-css-prefix}-sack:after { content: "\10f81c"; }
.fad.@{fa-css-prefix}-sack-dollar:after { content: "\10f81d"; }
.fad.@{fa-css-prefix}-sad-cry:after { content: "\10f5b3"; }
.fad.@{fa-css-prefix}-sad-tear:after { content: "\10f5b4"; }
.fad.@{fa-css-prefix}-salad:after { content: "\10f81e"; }
.fad.@{fa-css-prefix}-sandwich:after { content: "\10f81f"; }
.fad.@{fa-css-prefix}-satellite:after { content: "\10f7bf"; }
.fad.@{fa-css-prefix}-satellite-dish:after { content: "\10f7c0"; }
.fad.@{fa-css-prefix}-sausage:after { content: "\10f820"; }
.fad.@{fa-css-prefix}-save:after { content: "\10f0c7"; }
.fad.@{fa-css-prefix}-sax-hot:after { content: "\10f8db"; }
.fad.@{fa-css-prefix}-saxophone:after { content: "\10f8dc"; }
.fad.@{fa-css-prefix}-scalpel:after { content: "\10f61d"; }
.fad.@{fa-css-prefix}-scalpel-path:after { content: "\10f61e"; }
.fad.@{fa-css-prefix}-scanner:after { content: "\10f488"; }
.fad.@{fa-css-prefix}-scanner-image:after { content: "\10f8f3"; }
.fad.@{fa-css-prefix}-scanner-keyboard:after { content: "\10f489"; }
.fad.@{fa-css-prefix}-scanner-touchscreen:after { content: "\10f48a"; }
.fad.@{fa-css-prefix}-scarecrow:after { content: "\10f70d"; }
.fad.@{fa-css-prefix}-scarf:after { content: "\10f7c1"; }
.fad.@{fa-css-prefix}-school:after { content: "\10f549"; }
.fad.@{fa-css-prefix}-screwdriver:after { content: "\10f54a"; }
.fad.@{fa-css-prefix}-scroll:after { content: "\10f70e"; }
.fad.@{fa-css-prefix}-scroll-old:after { content: "\10f70f"; }
.fad.@{fa-css-prefix}-scrubber:after { content: "\10f2f8"; }
.fad.@{fa-css-prefix}-scythe:after { content: "\10f710"; }
.fad.@{fa-css-prefix}-sd-card:after { content: "\10f7c2"; }
.fad.@{fa-css-prefix}-search:after { content: "\10f002"; }
.fad.@{fa-css-prefix}-search-dollar:after { content: "\10f688"; }
.fad.@{fa-css-prefix}-search-location:after { content: "\10f689"; }
.fad.@{fa-css-prefix}-search-minus:after { content: "\10f010"; }
.fad.@{fa-css-prefix}-search-plus:after { content: "\10f00e"; }
.fad.@{fa-css-prefix}-seedling:after { content: "\10f4d8"; }
.fad.@{fa-css-prefix}-send-back:after { content: "\10f87e"; }
.fad.@{fa-css-prefix}-send-backward:after { content: "\10f87f"; }
.fad.@{fa-css-prefix}-sensor:after { content: "\10f928"; }
.fad.@{fa-css-prefix}-sensor-alert:after { content: "\10f929"; }
.fad.@{fa-css-prefix}-sensor-fire:after { content: "\10f92a"; }
.fad.@{fa-css-prefix}-sensor-on:after { content: "\10f92b"; }
.fad.@{fa-css-prefix}-sensor-smoke:after { content: "\10f92c"; }
.fad.@{fa-css-prefix}-server:after { content: "\10f233"; }
.fad.@{fa-css-prefix}-shapes:after { content: "\10f61f"; }
.fad.@{fa-css-prefix}-share:after { content: "\10f064"; }
.fad.@{fa-css-prefix}-share-all:after { content: "\10f367"; }
.fad.@{fa-css-prefix}-share-alt:after { content: "\10f1e0"; }
.fad.@{fa-css-prefix}-share-alt-square:after { content: "\10f1e1"; }
.fad.@{fa-css-prefix}-share-square:after { content: "\10f14d"; }
.fad.@{fa-css-prefix}-sheep:after { content: "\10f711"; }
.fad.@{fa-css-prefix}-shekel-sign:after { content: "\10f20b"; }
.fad.@{fa-css-prefix}-shield:after { content: "\10f132"; }
.fad.@{fa-css-prefix}-shield-alt:after { content: "\10f3ed"; }
.fad.@{fa-css-prefix}-shield-check:after { content: "\10f2f7"; }
.fad.@{fa-css-prefix}-shield-cross:after { content: "\10f712"; }
.fad.@{fa-css-prefix}-ship:after { content: "\10f21a"; }
.fad.@{fa-css-prefix}-shipping-fast:after { content: "\10f48b"; }
.fad.@{fa-css-prefix}-shipping-timed:after { content: "\10f48c"; }
.fad.@{fa-css-prefix}-shish-kebab:after { content: "\10f821"; }
.fad.@{fa-css-prefix}-shoe-prints:after { content: "\10f54b"; }
.fad.@{fa-css-prefix}-shopping-bag:after { content: "\10f290"; }
.fad.@{fa-css-prefix}-shopping-basket:after { content: "\10f291"; }
.fad.@{fa-css-prefix}-shopping-cart:after { content: "\10f07a"; }
.fad.@{fa-css-prefix}-shovel:after { content: "\10f713"; }
.fad.@{fa-css-prefix}-shovel-snow:after { content: "\10f7c3"; }
.fad.@{fa-css-prefix}-shower:after { content: "\10f2cc"; }
.fad.@{fa-css-prefix}-shredder:after { content: "\10f68a"; }
.fad.@{fa-css-prefix}-shuttle-van:after { content: "\10f5b6"; }
.fad.@{fa-css-prefix}-shuttlecock:after { content: "\10f45b"; }
.fad.@{fa-css-prefix}-sickle:after { content: "\10f822"; }
.fad.@{fa-css-prefix}-sigma:after { content: "\10f68b"; }
.fad.@{fa-css-prefix}-sign:after { content: "\10f4d9"; }
.fad.@{fa-css-prefix}-sign-in:after { content: "\10f090"; }
.fad.@{fa-css-prefix}-sign-in-alt:after { content: "\10f2f6"; }
.fad.@{fa-css-prefix}-sign-language:after { content: "\10f2a7"; }
.fad.@{fa-css-prefix}-sign-out:after { content: "\10f08b"; }
.fad.@{fa-css-prefix}-sign-out-alt:after { content: "\10f2f5"; }
.fad.@{fa-css-prefix}-signal:after { content: "\10f012"; }
.fad.@{fa-css-prefix}-signal-1:after { content: "\10f68c"; }
.fad.@{fa-css-prefix}-signal-2:after { content: "\10f68d"; }
.fad.@{fa-css-prefix}-signal-3:after { content: "\10f68e"; }
.fad.@{fa-css-prefix}-signal-4:after { content: "\10f68f"; }
.fad.@{fa-css-prefix}-signal-alt:after { content: "\10f690"; }
.fad.@{fa-css-prefix}-signal-alt-1:after { content: "\10f691"; }
.fad.@{fa-css-prefix}-signal-alt-2:after { content: "\10f692"; }
.fad.@{fa-css-prefix}-signal-alt-3:after { content: "\10f693"; }
.fad.@{fa-css-prefix}-signal-alt-slash:after { content: "\10f694"; }
.fad.@{fa-css-prefix}-signal-slash:after { content: "\10f695"; }
.fad.@{fa-css-prefix}-signal-stream:after { content: "\10f8dd"; }
.fad.@{fa-css-prefix}-signature:after { content: "\10f5b7"; }
.fad.@{fa-css-prefix}-sim-card:after { content: "\10f7c4"; }
.fad.@{fa-css-prefix}-siren:after { content: "\10f92d"; }
.fad.@{fa-css-prefix}-siren-on:after { content: "\10f92e"; }
.fad.@{fa-css-prefix}-sitemap:after { content: "\10f0e8"; }
.fad.@{fa-css-prefix}-skating:after { content: "\10f7c5"; }
.fad.@{fa-css-prefix}-skeleton:after { content: "\10f620"; }
.fad.@{fa-css-prefix}-ski-jump:after { content: "\10f7c7"; }
.fad.@{fa-css-prefix}-ski-lift:after { content: "\10f7c8"; }
.fad.@{fa-css-prefix}-skiing:after { content: "\10f7c9"; }
.fad.@{fa-css-prefix}-skiing-nordic:after { content: "\10f7ca"; }
.fad.@{fa-css-prefix}-skull:after { content: "\10f54c"; }
.fad.@{fa-css-prefix}-skull-cow:after { content: "\10f8de"; }
.fad.@{fa-css-prefix}-skull-crossbones:after { content: "\10f714"; }
.fad.@{fa-css-prefix}-slash:after { content: "\10f715"; }
.fad.@{fa-css-prefix}-sledding:after { content: "\10f7cb"; }
.fad.@{fa-css-prefix}-sleigh:after { content: "\10f7cc"; }
.fad.@{fa-css-prefix}-sliders-h:after { content: "\10f1de"; }
.fad.@{fa-css-prefix}-sliders-h-square:after { content: "\10f3f0"; }
.fad.@{fa-css-prefix}-sliders-v:after { content: "\10f3f1"; }
.fad.@{fa-css-prefix}-sliders-v-square:after { content: "\10f3f2"; }
.fad.@{fa-css-prefix}-smile:after { content: "\10f118"; }
.fad.@{fa-css-prefix}-smile-beam:after { content: "\10f5b8"; }
.fad.@{fa-css-prefix}-smile-plus:after { content: "\10f5b9"; }
.fad.@{fa-css-prefix}-smile-wink:after { content: "\10f4da"; }
.fad.@{fa-css-prefix}-smog:after { content: "\10f75f"; }
.fad.@{fa-css-prefix}-smoke:after { content: "\10f760"; }
.fad.@{fa-css-prefix}-smoking:after { content: "\10f48d"; }
.fad.@{fa-css-prefix}-smoking-ban:after { content: "\10f54d"; }
.fad.@{fa-css-prefix}-sms:after { content: "\10f7cd"; }
.fad.@{fa-css-prefix}-snake:after { content: "\10f716"; }
.fad.@{fa-css-prefix}-snooze:after { content: "\10f880"; }
.fad.@{fa-css-prefix}-snow-blowing:after { content: "\10f761"; }
.fad.@{fa-css-prefix}-snowboarding:after { content: "\10f7ce"; }
.fad.@{fa-css-prefix}-snowflake:after { content: "\10f2dc"; }
.fad.@{fa-css-prefix}-snowflakes:after { content: "\10f7cf"; }
.fad.@{fa-css-prefix}-snowman:after { content: "\10f7d0"; }
.fad.@{fa-css-prefix}-snowmobile:after { content: "\10f7d1"; }
.fad.@{fa-css-prefix}-snowplow:after { content: "\10f7d2"; }
.fad.@{fa-css-prefix}-socks:after { content: "\10f696"; }
.fad.@{fa-css-prefix}-solar-panel:after { content: "\10f5ba"; }
.fad.@{fa-css-prefix}-solar-system:after { content: "\10f92f"; }
.fad.@{fa-css-prefix}-sort:after { content: "\10f0dc"; }
.fad.@{fa-css-prefix}-sort-alpha-down:after { content: "\10f15d"; }
.fad.@{fa-css-prefix}-sort-alpha-down-alt:after { content: "\10f881"; }
.fad.@{fa-css-prefix}-sort-alpha-up:after { content: "\10f15e"; }
.fad.@{fa-css-prefix}-sort-alpha-up-alt:after { content: "\10f882"; }
.fad.@{fa-css-prefix}-sort-alt:after { content: "\10f883"; }
.fad.@{fa-css-prefix}-sort-amount-down:after { content: "\10f160"; }
.fad.@{fa-css-prefix}-sort-amount-down-alt:after { content: "\10f884"; }
.fad.@{fa-css-prefix}-sort-amount-up:after { content: "\10f161"; }
.fad.@{fa-css-prefix}-sort-amount-up-alt:after { content: "\10f885"; }
.fad.@{fa-css-prefix}-sort-circle:after { content: "\10f930"; }
.fad.@{fa-css-prefix}-sort-circle-down:after { content: "\10f931"; }
.fad.@{fa-css-prefix}-sort-circle-up:after { content: "\10f932"; }
.fad.@{fa-css-prefix}-sort-down:after { content: "\10f0dd"; }
.fad.@{fa-css-prefix}-sort-numeric-down:after { content: "\10f162"; }
.fad.@{fa-css-prefix}-sort-numeric-down-alt:after { content: "\10f886"; }
.fad.@{fa-css-prefix}-sort-numeric-up:after { content: "\10f163"; }
.fad.@{fa-css-prefix}-sort-numeric-up-alt:after { content: "\10f887"; }
.fad.@{fa-css-prefix}-sort-shapes-down:after { content: "\10f888"; }
.fad.@{fa-css-prefix}-sort-shapes-down-alt:after { content: "\10f889"; }
.fad.@{fa-css-prefix}-sort-shapes-up:after { content: "\10f88a"; }
.fad.@{fa-css-prefix}-sort-shapes-up-alt:after { content: "\10f88b"; }
.fad.@{fa-css-prefix}-sort-size-down:after { content: "\10f88c"; }
.fad.@{fa-css-prefix}-sort-size-down-alt:after { content: "\10f88d"; }
.fad.@{fa-css-prefix}-sort-size-up:after { content: "\10f88e"; }
.fad.@{fa-css-prefix}-sort-size-up-alt:after { content: "\10f88f"; }
.fad.@{fa-css-prefix}-sort-up:after { content: "\10f0de"; }
.fad.@{fa-css-prefix}-soup:after { content: "\10f823"; }
.fad.@{fa-css-prefix}-spa:after { content: "\10f5bb"; }
.fad.@{fa-css-prefix}-space-shuttle:after { content: "\10f197"; }
.fad.@{fa-css-prefix}-space-station-moon:after { content: "\10f933"; }
.fad.@{fa-css-prefix}-space-station-moon-alt:after { content: "\10f934"; }
.fad.@{fa-css-prefix}-spade:after { content: "\10f2f4"; }
.fad.@{fa-css-prefix}-sparkles:after { content: "\10f890"; }
.fad.@{fa-css-prefix}-speaker:after { content: "\10f8df"; }
.fad.@{fa-css-prefix}-speakers:after { content: "\10f8e0"; }
.fad.@{fa-css-prefix}-spell-check:after { content: "\10f891"; }
.fad.@{fa-css-prefix}-spider:after { content: "\10f717"; }
.fad.@{fa-css-prefix}-spider-black-widow:after { content: "\10f718"; }
.fad.@{fa-css-prefix}-spider-web:after { content: "\10f719"; }
.fad.@{fa-css-prefix}-spinner:after { content: "\10f110"; }
.fad.@{fa-css-prefix}-spinner-third:after { content: "\10f3f4"; }
.fad.@{fa-css-prefix}-splotch:after { content: "\10f5bc"; }
.fad.@{fa-css-prefix}-spray-can:after { content: "\10f5bd"; }
.fad.@{fa-css-prefix}-sprinkler:after { content: "\10f935"; }
.fad.@{fa-css-prefix}-square:after { content: "\10f0c8"; }
.fad.@{fa-css-prefix}-square-full:after { content: "\10f45c"; }
.fad.@{fa-css-prefix}-square-root:after { content: "\10f697"; }
.fad.@{fa-css-prefix}-square-root-alt:after { content: "\10f698"; }
.fad.@{fa-css-prefix}-squirrel:after { content: "\10f71a"; }
.fad.@{fa-css-prefix}-staff:after { content: "\10f71b"; }
.fad.@{fa-css-prefix}-stamp:after { content: "\10f5bf"; }
.fad.@{fa-css-prefix}-star:after { content: "\10f005"; }
.fad.@{fa-css-prefix}-star-and-crescent:after { content: "\10f699"; }
.fad.@{fa-css-prefix}-star-christmas:after { content: "\10f7d4"; }
.fad.@{fa-css-prefix}-star-exclamation:after { content: "\10f2f3"; }
.fad.@{fa-css-prefix}-star-half:after { content: "\10f089"; }
.fad.@{fa-css-prefix}-star-half-alt:after { content: "\10f5c0"; }
.fad.@{fa-css-prefix}-star-of-david:after { content: "\10f69a"; }
.fad.@{fa-css-prefix}-star-of-life:after { content: "\10f621"; }
.fad.@{fa-css-prefix}-star-shooting:after { content: "\10f936"; }
.fad.@{fa-css-prefix}-starfighter:after { content: "\10f937"; }
.fad.@{fa-css-prefix}-starfighter-alt:after { content: "\10f938"; }
.fad.@{fa-css-prefix}-stars:after { content: "\10f762"; }
.fad.@{fa-css-prefix}-starship:after { content: "\10f939"; }
.fad.@{fa-css-prefix}-starship-freighter:after { content: "\10f93a"; }
.fad.@{fa-css-prefix}-steak:after { content: "\10f824"; }
.fad.@{fa-css-prefix}-steering-wheel:after { content: "\10f622"; }
.fad.@{fa-css-prefix}-step-backward:after { content: "\10f048"; }
.fad.@{fa-css-prefix}-step-forward:after { content: "\10f051"; }
.fad.@{fa-css-prefix}-stethoscope:after { content: "\10f0f1"; }
.fad.@{fa-css-prefix}-sticky-note:after { content: "\10f249"; }
.fad.@{fa-css-prefix}-stocking:after { content: "\10f7d5"; }
.fad.@{fa-css-prefix}-stomach:after { content: "\10f623"; }
.fad.@{fa-css-prefix}-stop:after { content: "\10f04d"; }
.fad.@{fa-css-prefix}-stop-circle:after { content: "\10f28d"; }
.fad.@{fa-css-prefix}-stopwatch:after { content: "\10f2f2"; }
.fad.@{fa-css-prefix}-store:after { content: "\10f54e"; }
.fad.@{fa-css-prefix}-store-alt:after { content: "\10f54f"; }
.fad.@{fa-css-prefix}-stream:after { content: "\10f550"; }
.fad.@{fa-css-prefix}-street-view:after { content: "\10f21d"; }
.fad.@{fa-css-prefix}-stretcher:after { content: "\10f825"; }
.fad.@{fa-css-prefix}-strikethrough:after { content: "\10f0cc"; }
.fad.@{fa-css-prefix}-stroopwafel:after { content: "\10f551"; }
.fad.@{fa-css-prefix}-subscript:after { content: "\10f12c"; }
.fad.@{fa-css-prefix}-subway:after { content: "\10f239"; }
.fad.@{fa-css-prefix}-suitcase:after { content: "\10f0f2"; }
.fad.@{fa-css-prefix}-suitcase-rolling:after { content: "\10f5c1"; }
.fad.@{fa-css-prefix}-sun:after { content: "\10f185"; }
.fad.@{fa-css-prefix}-sun-cloud:after { content: "\10f763"; }
.fad.@{fa-css-prefix}-sun-dust:after { content: "\10f764"; }
.fad.@{fa-css-prefix}-sun-haze:after { content: "\10f765"; }
.fad.@{fa-css-prefix}-sunglasses:after { content: "\10f892"; }
.fad.@{fa-css-prefix}-sunrise:after { content: "\10f766"; }
.fad.@{fa-css-prefix}-sunset:after { content: "\10f767"; }
.fad.@{fa-css-prefix}-superscript:after { content: "\10f12b"; }
.fad.@{fa-css-prefix}-surprise:after { content: "\10f5c2"; }
.fad.@{fa-css-prefix}-swatchbook:after { content: "\10f5c3"; }
.fad.@{fa-css-prefix}-swimmer:after { content: "\10f5c4"; }
.fad.@{fa-css-prefix}-swimming-pool:after { content: "\10f5c5"; }
.fad.@{fa-css-prefix}-sword:after { content: "\10f71c"; }
.fad.@{fa-css-prefix}-sword-laser:after { content: "\10f93b"; }
.fad.@{fa-css-prefix}-sword-laser-alt:after { content: "\10f93c"; }
.fad.@{fa-css-prefix}-swords:after { content: "\10f71d"; }
.fad.@{fa-css-prefix}-swords-laser:after { content: "\10f93d"; }
.fad.@{fa-css-prefix}-synagogue:after { content: "\10f69b"; }
.fad.@{fa-css-prefix}-sync:after { content: "\10f021"; }
.fad.@{fa-css-prefix}-sync-alt:after { content: "\10f2f1"; }
.fad.@{fa-css-prefix}-syringe:after { content: "\10f48e"; }
.fad.@{fa-css-prefix}-table:after { content: "\10f0ce"; }
.fad.@{fa-css-prefix}-table-tennis:after { content: "\10f45d"; }
.fad.@{fa-css-prefix}-tablet:after { content: "\10f10a"; }
.fad.@{fa-css-prefix}-tablet-alt:after { content: "\10f3fa"; }
.fad.@{fa-css-prefix}-tablet-android:after { content: "\10f3fb"; }
.fad.@{fa-css-prefix}-tablet-android-alt:after { content: "\10f3fc"; }
.fad.@{fa-css-prefix}-tablet-rugged:after { content: "\10f48f"; }
.fad.@{fa-css-prefix}-tablets:after { content: "\10f490"; }
.fad.@{fa-css-prefix}-tachometer:after { content: "\10f0e4"; }
.fad.@{fa-css-prefix}-tachometer-alt:after { content: "\10f3fd"; }
.fad.@{fa-css-prefix}-tachometer-alt-average:after { content: "\10f624"; }
.fad.@{fa-css-prefix}-tachometer-alt-fast:after { content: "\10f625"; }
.fad.@{fa-css-prefix}-tachometer-alt-fastest:after { content: "\10f626"; }
.fad.@{fa-css-prefix}-tachometer-alt-slow:after { content: "\10f627"; }
.fad.@{fa-css-prefix}-tachometer-alt-slowest:after { content: "\10f628"; }
.fad.@{fa-css-prefix}-tachometer-average:after { content: "\10f629"; }
.fad.@{fa-css-prefix}-tachometer-fast:after { content: "\10f62a"; }
.fad.@{fa-css-prefix}-tachometer-fastest:after { content: "\10f62b"; }
.fad.@{fa-css-prefix}-tachometer-slow:after { content: "\10f62c"; }
.fad.@{fa-css-prefix}-tachometer-slowest:after { content: "\10f62d"; }
.fad.@{fa-css-prefix}-taco:after { content: "\10f826"; }
.fad.@{fa-css-prefix}-tag:after { content: "\10f02b"; }
.fad.@{fa-css-prefix}-tags:after { content: "\10f02c"; }
.fad.@{fa-css-prefix}-tally:after { content: "\10f69c"; }
.fad.@{fa-css-prefix}-tanakh:after { content: "\10f827"; }
.fad.@{fa-css-prefix}-tape:after { content: "\10f4db"; }
.fad.@{fa-css-prefix}-tasks:after { content: "\10f0ae"; }
.fad.@{fa-css-prefix}-tasks-alt:after { content: "\10f828"; }
.fad.@{fa-css-prefix}-taxi:after { content: "\10f1ba"; }
.fad.@{fa-css-prefix}-teeth:after { content: "\10f62e"; }
.fad.@{fa-css-prefix}-teeth-open:after { content: "\10f62f"; }
.fad.@{fa-css-prefix}-telescope:after { content: "\10f93e"; }
.fad.@{fa-css-prefix}-temperature-down:after { content: "\10f93f"; }
.fad.@{fa-css-prefix}-temperature-frigid:after { content: "\10f768"; }
.fad.@{fa-css-prefix}-temperature-high:after { content: "\10f769"; }
.fad.@{fa-css-prefix}-temperature-hot:after { content: "\10f76a"; }
.fad.@{fa-css-prefix}-temperature-low:after { content: "\10f76b"; }
.fad.@{fa-css-prefix}-temperature-up:after { content: "\10f940"; }
.fad.@{fa-css-prefix}-tenge:after { content: "\10f7d7"; }
.fad.@{fa-css-prefix}-tennis-ball:after { content: "\10f45e"; }
.fad.@{fa-css-prefix}-terminal:after { content: "\10f120"; }
.fad.@{fa-css-prefix}-text:after { content: "\10f893"; }
.fad.@{fa-css-prefix}-text-height:after { content: "\10f034"; }
.fad.@{fa-css-prefix}-text-size:after { content: "\10f894"; }
.fad.@{fa-css-prefix}-text-width:after { content: "\10f035"; }
.fad.@{fa-css-prefix}-th:after { content: "\10f00a"; }
.fad.@{fa-css-prefix}-th-large:after { content: "\10f009"; }
.fad.@{fa-css-prefix}-th-list:after { content: "\10f00b"; }
.fad.@{fa-css-prefix}-theater-masks:after { content: "\10f630"; }
.fad.@{fa-css-prefix}-thermometer:after { content: "\10f491"; }
.fad.@{fa-css-prefix}-thermometer-empty:after { content: "\10f2cb"; }
.fad.@{fa-css-prefix}-thermometer-full:after { content: "\10f2c7"; }
.fad.@{fa-css-prefix}-thermometer-half:after { content: "\10f2c9"; }
.fad.@{fa-css-prefix}-thermometer-quarter:after { content: "\10f2ca"; }
.fad.@{fa-css-prefix}-thermometer-three-quarters:after { content: "\10f2c8"; }
.fad.@{fa-css-prefix}-theta:after { content: "\10f69e"; }
.fad.@{fa-css-prefix}-thumbs-down:after { content: "\10f165"; }
.fad.@{fa-css-prefix}-thumbs-up:after { content: "\10f164"; }
.fad.@{fa-css-prefix}-thumbtack:after { content: "\10f08d"; }
.fad.@{fa-css-prefix}-thunderstorm:after { content: "\10f76c"; }
.fad.@{fa-css-prefix}-thunderstorm-moon:after { content: "\10f76d"; }
.fad.@{fa-css-prefix}-thunderstorm-sun:after { content: "\10f76e"; }
.fad.@{fa-css-prefix}-ticket:after { content: "\10f145"; }
.fad.@{fa-css-prefix}-ticket-alt:after { content: "\10f3ff"; }
.fad.@{fa-css-prefix}-tilde:after { content: "\10f69f"; }
.fad.@{fa-css-prefix}-times:after { content: "\10f00d"; }
.fad.@{fa-css-prefix}-times-circle:after { content: "\10f057"; }
.fad.@{fa-css-prefix}-times-hexagon:after { content: "\10f2ee"; }
.fad.@{fa-css-prefix}-times-octagon:after { content: "\10f2f0"; }
.fad.@{fa-css-prefix}-times-square:after { content: "\10f2d3"; }
.fad.@{fa-css-prefix}-tint:after { content: "\10f043"; }
.fad.@{fa-css-prefix}-tint-slash:after { content: "\10f5c7"; }
.fad.@{fa-css-prefix}-tire:after { content: "\10f631"; }
.fad.@{fa-css-prefix}-tire-flat:after { content: "\10f632"; }
.fad.@{fa-css-prefix}-tire-pressure-warning:after { content: "\10f633"; }
.fad.@{fa-css-prefix}-tire-rugged:after { content: "\10f634"; }
.fad.@{fa-css-prefix}-tired:after { content: "\10f5c8"; }
.fad.@{fa-css-prefix}-toggle-off:after { content: "\10f204"; }
.fad.@{fa-css-prefix}-toggle-on:after { content: "\10f205"; }
.fad.@{fa-css-prefix}-toilet:after { content: "\10f7d8"; }
.fad.@{fa-css-prefix}-toilet-paper:after { content: "\10f71e"; }
.fad.@{fa-css-prefix}-toilet-paper-alt:after { content: "\10f71f"; }
.fad.@{fa-css-prefix}-tombstone:after { content: "\10f720"; }
.fad.@{fa-css-prefix}-tombstone-alt:after { content: "\10f721"; }
.fad.@{fa-css-prefix}-toolbox:after { content: "\10f552"; }
.fad.@{fa-css-prefix}-tools:after { content: "\10f7d9"; }
.fad.@{fa-css-prefix}-tooth:after { content: "\10f5c9"; }
.fad.@{fa-css-prefix}-toothbrush:after { content: "\10f635"; }
.fad.@{fa-css-prefix}-torah:after { content: "\10f6a0"; }
.fad.@{fa-css-prefix}-torii-gate:after { content: "\10f6a1"; }
.fad.@{fa-css-prefix}-tornado:after { content: "\10f76f"; }
.fad.@{fa-css-prefix}-tractor:after { content: "\10f722"; }
.fad.@{fa-css-prefix}-trademark:after { content: "\10f25c"; }
.fad.@{fa-css-prefix}-traffic-cone:after { content: "\10f636"; }
.fad.@{fa-css-prefix}-traffic-light:after { content: "\10f637"; }
.fad.@{fa-css-prefix}-traffic-light-go:after { content: "\10f638"; }
.fad.@{fa-css-prefix}-traffic-light-slow:after { content: "\10f639"; }
.fad.@{fa-css-prefix}-traffic-light-stop:after { content: "\10f63a"; }
.fad.@{fa-css-prefix}-trailer:after { content: "\10f941"; }
.fad.@{fa-css-prefix}-train:after { content: "\10f238"; }
.fad.@{fa-css-prefix}-tram:after { content: "\10f7da"; }
.fad.@{fa-css-prefix}-transgender:after { content: "\10f224"; }
.fad.@{fa-css-prefix}-transgender-alt:after { content: "\10f225"; }
.fad.@{fa-css-prefix}-transporter:after { content: "\10f942"; }
.fad.@{fa-css-prefix}-transporter-1:after { content: "\10f943"; }
.fad.@{fa-css-prefix}-transporter-2:after { content: "\10f944"; }
.fad.@{fa-css-prefix}-transporter-3:after { content: "\10f945"; }
.fad.@{fa-css-prefix}-transporter-empty:after { content: "\10f946"; }
.fad.@{fa-css-prefix}-trash:after { content: "\10f1f8"; }
.fad.@{fa-css-prefix}-trash-alt:after { content: "\10f2ed"; }
.fad.@{fa-css-prefix}-trash-restore:after { content: "\10f829"; }
.fad.@{fa-css-prefix}-trash-restore-alt:after { content: "\10f82a"; }
.fad.@{fa-css-prefix}-trash-undo:after { content: "\10f895"; }
.fad.@{fa-css-prefix}-trash-undo-alt:after { content: "\10f896"; }
.fad.@{fa-css-prefix}-treasure-chest:after { content: "\10f723"; }
.fad.@{fa-css-prefix}-tree:after { content: "\10f1bb"; }
.fad.@{fa-css-prefix}-tree-alt:after { content: "\10f400"; }
.fad.@{fa-css-prefix}-tree-christmas:after { content: "\10f7db"; }
.fad.@{fa-css-prefix}-tree-decorated:after { content: "\10f7dc"; }
.fad.@{fa-css-prefix}-tree-large:after { content: "\10f7dd"; }
.fad.@{fa-css-prefix}-tree-palm:after { content: "\10f82b"; }
.fad.@{fa-css-prefix}-trees:after { content: "\10f724"; }
.fad.@{fa-css-prefix}-triangle:after { content: "\10f2ec"; }
.fad.@{fa-css-prefix}-triangle-music:after { content: "\10f8e2"; }
.fad.@{fa-css-prefix}-trophy:after { content: "\10f091"; }
.fad.@{fa-css-prefix}-trophy-alt:after { content: "\10f2eb"; }
.fad.@{fa-css-prefix}-truck:after { content: "\10f0d1"; }
.fad.@{fa-css-prefix}-truck-container:after { content: "\10f4dc"; }
.fad.@{fa-css-prefix}-truck-couch:after { content: "\10f4dd"; }
.fad.@{fa-css-prefix}-truck-loading:after { content: "\10f4de"; }
.fad.@{fa-css-prefix}-truck-monster:after { content: "\10f63b"; }
.fad.@{fa-css-prefix}-truck-moving:after { content: "\10f4df"; }
.fad.@{fa-css-prefix}-truck-pickup:after { content: "\10f63c"; }
.fad.@{fa-css-prefix}-truck-plow:after { content: "\10f7de"; }
.fad.@{fa-css-prefix}-truck-ramp:after { content: "\10f4e0"; }
.fad.@{fa-css-prefix}-trumpet:after { content: "\10f8e3"; }
.fad.@{fa-css-prefix}-tshirt:after { content: "\10f553"; }
.fad.@{fa-css-prefix}-tty:after { content: "\10f1e4"; }
.fad.@{fa-css-prefix}-turkey:after { content: "\10f725"; }
.fad.@{fa-css-prefix}-turntable:after { content: "\10f8e4"; }
.fad.@{fa-css-prefix}-turtle:after { content: "\10f726"; }
.fad.@{fa-css-prefix}-tv:after { content: "\10f26c"; }
.fad.@{fa-css-prefix}-tv-alt:after { content: "\10f8e5"; }
.fad.@{fa-css-prefix}-tv-music:after { content: "\10f8e6"; }
.fad.@{fa-css-prefix}-tv-retro:after { content: "\10f401"; }
.fad.@{fa-css-prefix}-typewriter:after { content: "\10f8e7"; }
.fad.@{fa-css-prefix}-ufo:after { content: "\10f947"; }
.fad.@{fa-css-prefix}-ufo-beam:after { content: "\10f948"; }
.fad.@{fa-css-prefix}-umbrella:after { content: "\10f0e9"; }
.fad.@{fa-css-prefix}-umbrella-beach:after { content: "\10f5ca"; }
.fad.@{fa-css-prefix}-underline:after { content: "\10f0cd"; }
.fad.@{fa-css-prefix}-undo:after { content: "\10f0e2"; }
.fad.@{fa-css-prefix}-undo-alt:after { content: "\10f2ea"; }
.fad.@{fa-css-prefix}-unicorn:after { content: "\10f727"; }
.fad.@{fa-css-prefix}-union:after { content: "\10f6a2"; }
.fad.@{fa-css-prefix}-universal-access:after { content: "\10f29a"; }
.fad.@{fa-css-prefix}-university:after { content: "\10f19c"; }
.fad.@{fa-css-prefix}-unlink:after { content: "\10f127"; }
.fad.@{fa-css-prefix}-unlock:after { content: "\10f09c"; }
.fad.@{fa-css-prefix}-unlock-alt:after { content: "\10f13e"; }
.fad.@{fa-css-prefix}-upload:after { content: "\10f093"; }
.fad.@{fa-css-prefix}-usb-drive:after { content: "\10f8e9"; }
.fad.@{fa-css-prefix}-usd-circle:after { content: "\10f2e8"; }
.fad.@{fa-css-prefix}-usd-square:after { content: "\10f2e9"; }
.fad.@{fa-css-prefix}-user:after { content: "\10f007"; }
.fad.@{fa-css-prefix}-user-alien:after { content: "\10f94a"; }
.fad.@{fa-css-prefix}-user-alt:after { content: "\10f406"; }
.fad.@{fa-css-prefix}-user-alt-slash:after { content: "\10f4fa"; }
.fad.@{fa-css-prefix}-user-astronaut:after { content: "\10f4fb"; }
.fad.@{fa-css-prefix}-user-chart:after { content: "\10f6a3"; }
.fad.@{fa-css-prefix}-user-check:after { content: "\10f4fc"; }
.fad.@{fa-css-prefix}-user-circle:after { content: "\10f2bd"; }
.fad.@{fa-css-prefix}-user-clock:after { content: "\10f4fd"; }
.fad.@{fa-css-prefix}-user-cog:after { content: "\10f4fe"; }
.fad.@{fa-css-prefix}-user-cowboy:after { content: "\10f8ea"; }
.fad.@{fa-css-prefix}-user-crown:after { content: "\10f6a4"; }
.fad.@{fa-css-prefix}-user-edit:after { content: "\10f4ff"; }
.fad.@{fa-css-prefix}-user-friends:after { content: "\10f500"; }
.fad.@{fa-css-prefix}-user-graduate:after { content: "\10f501"; }
.fad.@{fa-css-prefix}-user-hard-hat:after { content: "\10f82c"; }
.fad.@{fa-css-prefix}-user-headset:after { content: "\10f82d"; }
.fad.@{fa-css-prefix}-user-injured:after { content: "\10f728"; }
.fad.@{fa-css-prefix}-user-lock:after { content: "\10f502"; }
.fad.@{fa-css-prefix}-user-md:after { content: "\10f0f0"; }
.fad.@{fa-css-prefix}-user-md-chat:after { content: "\10f82e"; }
.fad.@{fa-css-prefix}-user-minus:after { content: "\10f503"; }
.fad.@{fa-css-prefix}-user-music:after { content: "\10f8eb"; }
.fad.@{fa-css-prefix}-user-ninja:after { content: "\10f504"; }
.fad.@{fa-css-prefix}-user-nurse:after { content: "\10f82f"; }
.fad.@{fa-css-prefix}-user-plus:after { content: "\10f234"; }
.fad.@{fa-css-prefix}-user-robot:after { content: "\10f94b"; }
.fad.@{fa-css-prefix}-user-secret:after { content: "\10f21b"; }
.fad.@{fa-css-prefix}-user-shield:after { content: "\10f505"; }
.fad.@{fa-css-prefix}-user-slash:after { content: "\10f506"; }
.fad.@{fa-css-prefix}-user-tag:after { content: "\10f507"; }
.fad.@{fa-css-prefix}-user-tie:after { content: "\10f508"; }
.fad.@{fa-css-prefix}-user-times:after { content: "\10f235"; }
.fad.@{fa-css-prefix}-user-visor:after { content: "\10f94c"; }
.fad.@{fa-css-prefix}-users:after { content: "\10f0c0"; }
.fad.@{fa-css-prefix}-users-class:after { content: "\10f63d"; }
.fad.@{fa-css-prefix}-users-cog:after { content: "\10f509"; }
.fad.@{fa-css-prefix}-users-crown:after { content: "\10f6a5"; }
.fad.@{fa-css-prefix}-users-medical:after { content: "\10f830"; }
.fad.@{fa-css-prefix}-utensil-fork:after { content: "\10f2e3"; }
.fad.@{fa-css-prefix}-utensil-knife:after { content: "\10f2e4"; }
.fad.@{fa-css-prefix}-utensil-spoon:after { content: "\10f2e5"; }
.fad.@{fa-css-prefix}-utensils:after { content: "\10f2e7"; }
.fad.@{fa-css-prefix}-utensils-alt:after { content: "\10f2e6"; }
.fad.@{fa-css-prefix}-vacuum:after { content: "\10f94d"; }
.fad.@{fa-css-prefix}-vacuum-robot:after { content: "\10f94e"; }
.fad.@{fa-css-prefix}-value-absolute:after { content: "\10f6a6"; }
.fad.@{fa-css-prefix}-vector-square:after { content: "\10f5cb"; }
.fad.@{fa-css-prefix}-venus:after { content: "\10f221"; }
.fad.@{fa-css-prefix}-venus-double:after { content: "\10f226"; }
.fad.@{fa-css-prefix}-venus-mars:after { content: "\10f228"; }
.fad.@{fa-css-prefix}-vhs:after { content: "\10f8ec"; }
.fad.@{fa-css-prefix}-vial:after { content: "\10f492"; }
.fad.@{fa-css-prefix}-vials:after { content: "\10f493"; }
.fad.@{fa-css-prefix}-video:after { content: "\10f03d"; }
.fad.@{fa-css-prefix}-video-plus:after { content: "\10f4e1"; }
.fad.@{fa-css-prefix}-video-slash:after { content: "\10f4e2"; }
.fad.@{fa-css-prefix}-vihara:after { content: "\10f6a7"; }
.fad.@{fa-css-prefix}-violin:after { content: "\10f8ed"; }
.fad.@{fa-css-prefix}-voicemail:after { content: "\10f897"; }
.fad.@{fa-css-prefix}-volcano:after { content: "\10f770"; }
.fad.@{fa-css-prefix}-volleyball-ball:after { content: "\10f45f"; }
.fad.@{fa-css-prefix}-volume:after { content: "\10f6a8"; }
.fad.@{fa-css-prefix}-volume-down:after { content: "\10f027"; }
.fad.@{fa-css-prefix}-volume-mute:after { content: "\10f6a9"; }
.fad.@{fa-css-prefix}-volume-off:after { content: "\10f026"; }
.fad.@{fa-css-prefix}-volume-slash:after { content: "\10f2e2"; }
.fad.@{fa-css-prefix}-volume-up:after { content: "\10f028"; }
.fad.@{fa-css-prefix}-vote-nay:after { content: "\10f771"; }
.fad.@{fa-css-prefix}-vote-yea:after { content: "\10f772"; }
.fad.@{fa-css-prefix}-vr-cardboard:after { content: "\10f729"; }
.fad.@{fa-css-prefix}-wagon-covered:after { content: "\10f8ee"; }
.fad.@{fa-css-prefix}-walker:after { content: "\10f831"; }
.fad.@{fa-css-prefix}-walkie-talkie:after { content: "\10f8ef"; }
.fad.@{fa-css-prefix}-walking:after { content: "\10f554"; }
.fad.@{fa-css-prefix}-wallet:after { content: "\10f555"; }
.fad.@{fa-css-prefix}-wand:after { content: "\10f72a"; }
.fad.@{fa-css-prefix}-wand-magic:after { content: "\10f72b"; }
.fad.@{fa-css-prefix}-warehouse:after { content: "\10f494"; }
.fad.@{fa-css-prefix}-warehouse-alt:after { content: "\10f495"; }
.fad.@{fa-css-prefix}-washer:after { content: "\10f898"; }
.fad.@{fa-css-prefix}-watch:after { content: "\10f2e1"; }
.fad.@{fa-css-prefix}-watch-calculator:after { content: "\10f8f0"; }
.fad.@{fa-css-prefix}-watch-fitness:after { content: "\10f63e"; }
.fad.@{fa-css-prefix}-water:after { content: "\10f773"; }
.fad.@{fa-css-prefix}-water-lower:after { content: "\10f774"; }
.fad.@{fa-css-prefix}-water-rise:after { content: "\10f775"; }
.fad.@{fa-css-prefix}-wave-sine:after { content: "\10f899"; }
.fad.@{fa-css-prefix}-wave-square:after { content: "\10f83e"; }
.fad.@{fa-css-prefix}-wave-triangle:after { content: "\10f89a"; }
.fad.@{fa-css-prefix}-waveform:after { content: "\10f8f1"; }
.fad.@{fa-css-prefix}-waveform-path:after { content: "\10f8f2"; }
.fad.@{fa-css-prefix}-webcam:after { content: "\10f832"; }
.fad.@{fa-css-prefix}-webcam-slash:after { content: "\10f833"; }
.fad.@{fa-css-prefix}-weight:after { content: "\10f496"; }
.fad.@{fa-css-prefix}-weight-hanging:after { content: "\10f5cd"; }
.fad.@{fa-css-prefix}-whale:after { content: "\10f72c"; }
.fad.@{fa-css-prefix}-wheat:after { content: "\10f72d"; }
.fad.@{fa-css-prefix}-wheelchair:after { content: "\10f193"; }
.fad.@{fa-css-prefix}-whistle:after { content: "\10f460"; }
.fad.@{fa-css-prefix}-wifi:after { content: "\10f1eb"; }
.fad.@{fa-css-prefix}-wifi-1:after { content: "\10f6aa"; }
.fad.@{fa-css-prefix}-wifi-2:after { content: "\10f6ab"; }
.fad.@{fa-css-prefix}-wifi-slash:after { content: "\10f6ac"; }
.fad.@{fa-css-prefix}-wind:after { content: "\10f72e"; }
.fad.@{fa-css-prefix}-wind-turbine:after { content: "\10f89b"; }
.fad.@{fa-css-prefix}-wind-warning:after { content: "\10f776"; }
.fad.@{fa-css-prefix}-window:after { content: "\10f40e"; }
.fad.@{fa-css-prefix}-window-alt:after { content: "\10f40f"; }
.fad.@{fa-css-prefix}-window-close:after { content: "\10f410"; }
.fad.@{fa-css-prefix}-window-frame:after { content: "\10f94f"; }
.fad.@{fa-css-prefix}-window-frame-open:after { content: "\10f950"; }
.fad.@{fa-css-prefix}-window-maximize:after { content: "\10f2d0"; }
.fad.@{fa-css-prefix}-window-minimize:after { content: "\10f2d1"; }
.fad.@{fa-css-prefix}-window-restore:after { content: "\10f2d2"; }
.fad.@{fa-css-prefix}-windsock:after { content: "\10f777"; }
.fad.@{fa-css-prefix}-wine-bottle:after { content: "\10f72f"; }
.fad.@{fa-css-prefix}-wine-glass:after { content: "\10f4e3"; }
.fad.@{fa-css-prefix}-wine-glass-alt:after { content: "\10f5ce"; }
.fad.@{fa-css-prefix}-won-sign:after { content: "\10f159"; }
.fad.@{fa-css-prefix}-wreath:after { content: "\10f7e2"; }
.fad.@{fa-css-prefix}-wrench:after { content: "\10f0ad"; }
.fad.@{fa-css-prefix}-x-ray:after { content: "\10f497"; }
.fad.@{fa-css-prefix}-yen-sign:after { content: "\10f157"; }
.fad.@{fa-css-prefix}-yin-yang:after { content: "\10f6ad"; }
