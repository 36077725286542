.caps {
  text-transform: uppercase;
}

// TEXT BOX
.text-box-width-limit{
  max-width: var(--width-limit);
}
.text-box {

  .title {
    > *:not(a) {
      font: @f_hbold var(--headname-2)/113% @font;
      position: relative;
      margin-bottom: 10px;
      flex: 1 1 auto;

      @media only screen and (max-width: 860px),
      only screen and (max-device-width: 860px) {
        font: @f_hbold var(--headname-3)/113% @font;
      }
    }
    margin-bottom: @gap;
  }

  .title__icn{
    display: flex;

    > a{
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      width: 44px;
      min-width: 44px;
      height: 44px;
      background: @color_1;
      color:@color_w;
      text-decoration: none;

      &:hover{
        background: @color_2;
        color:@color_w;
      }
    }
  }

  .sub-title {
    > * {
      font: @f_hbold var(--headname-4) /120% @font;
      margin-bottom: 10px;
    }
  }

  .sub-sub-title {
    font: @f_hbold var(--headname-5) /140% @font;
  }

  figure{
    margin-bottom: @gap;
  }

  p{
    font: @f_reg var(--paragraph-1) /140% @font;
    margin-bottom: @gap;
  }

  .big-img{
    overflow: hidden;
    max-width: 100%;
    display: flex;
    border-radius: @br;
    //padding-bottom: 56.25%;
    position: relative;
    margin-bottom: @gap*2;

    img{
      width: 100%;
      height: 100%;
      -o-object-fit: cover !important;
      object-fit: cover !important;
      font-family: 'object-fit: cover;';
      //position: absolute;
      //top: 0;
      //left: 0;
    }
  }

  [class*='prev-img']{
    width: 33.33%;
    display: flex;

    > div{
      overflow: hidden;
      display: flex;
      flex: 1 0 120px;
      width: 100%;
      padding-bottom: 66.25%;
      position: relative;
      margin-bottom: @gap;
      border-radius: @br;
      .box-shadow;

      img{
        width: 100%;
        height: 100%;
        -o-object-fit: cover !important;
        object-fit: cover !important;
        font-family: 'object-fit: cover;';
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    &.prev-img__left{
      float: left;
      margin-right: @gap;
    }
    &.prev-img__right{
      float: right;
      margin-left: @gap;
    }
  }

  .quote,
  .quote_c,
  blockquote {
    position: relative;
    margin-top: @gap /2;
    margin-bottom: @gap;
    font-size: 16px;
    display: inline-block;
    width: 100%;
    background: @color_b6;
    .radius(@br);
    padding: 20px 20px 20px 60px;
  }

  .quote_c {
    text-align: center;
    padding-left: 20px !important;
  }

  .quote,
  blockquote {
    &::before {
      content: "";
      width: 40px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      display: block;
      background: @color_1;
      .radius(@br 0 0 @br);
    }

    &::after {
      content: "\f10d";
      width: 40px;
      height: 40px;
      position: absolute;
      color: @color_w;
      .fas;
      .centerxy;
      font-size: 23px;
      top: 20px;
      left: 8px;
      z-index: 2;
      display: block;
    }
  }

  ul:not(.todo-list):not(.list-unstyled):not(.terminal-business-list){
    list-style-type: none;
    counter-reset: num;
    margin: 0 auto @gap *2 auto;

    > li {
      position: relative;
      list-style: none;
      margin-bottom: 15px;
      min-height: 25px;
      padding-left: 18px;

      &:before {
        content: '';
        width: 8px;
        height: 8px;
        position: absolute;
        left: 2px;
        top: 7px;
        z-index: 3;
        background: @color_2;
        .radius(100%);
      }

      > * {
        margin: 10px auto 5px;

        > li {

          &:before {
            content: '';
            width: 8px;
            height: 8px;
            position: absolute;
            left: 2px;
            top: 7px;
            z-index: 3;
            background: @color_b4;
            .radius(100%);
          }
        }
      }
    }
  }

  ul.todo-list{
    list-style-type: none;
    counter-reset: num;
    margin: 0 auto @gap *2 auto;

    > li {
      position: relative;
      list-style: none;
      margin-bottom: 15px;
      min-height: 25px;
      padding-left: 18px;

      label {
        position: absolute;
        left: 0;
        top: -2px;
        margin-right: 10px;
      }
    }
  }

  ol {
    list-style-type: none;
    counter-reset: num;
    margin: 0 auto @gap *2 auto;

    > li {
      position: relative;
      list-style: none;
      margin-bottom: 15px;
      min-height: 25px;
      padding-left: 30px;

      &::before {
        content: counter(num) ' ' !important;
        counter-increment: num !important;
        color: @color_b1;
        background: @color_b5;
        width: 23px;
        height: 23px;
        .centerxy;
        position: absolute;
        line-height: 17px;
        left: 0;
        top: 0;
        font-size: 12px;
        font-weight: @f_bold;
        .radius(@br);
      }

      > ul{
        margin-top: 20px  !important;
      }
    }
  }

  .action-color{
    color: @color_1;
  }

  h1, h1> *:not(a){
    font: @f_hbold var(--headname-2)/113% @font;

    @media only screen and (max-width: 860px),
    only screen and (max-device-width: 860px) {
      font: @f_hbold var(--headname-3)/113% @font;
    }
  }
  h2, h2> *:not(a){
     font: @f_hbold var(--headname-3)/113% @font;

    @media only screen and (max-width: 860px),
    only screen and (max-device-width: 860px) {
      font: @f_hbold var(--headname-4)/113% @font;
    }
  }
  h3, h3> *:not(a){
    font: @f_hbold var(--headname-4)/113% @font;

    @media only screen and (max-width: 860px),
    only screen and (max-device-width: 860px) {
      font: @f_hbold var(--headname-5)/113% @font;
    }
  }
  h4, h4> *:not(a){
    font: @f_sbold var(--headname-5)/113% @font;

    @media only screen and (max-width: 860px),
    only screen and (max-device-width: 860px) {
      font: @f_sbold var(--headname-6)/113% @font;
    }
  }
  h1, h1> *:not(a),
  h2, h2> *:not(a),
  h3, h3> *:not(a),
  h4, h4> *:not(a){
    margin-bottom: 10px;
  }

  .img-wrap{
    display: flex;
    width: 100%;
    margin-bottom: @gap;

    img {
      width: 100%;
      height: auto !important;
    }

    &__size-big{
      width: 100%;
    }
    &__size-half{
      max-width: 360px;
      width: 100%;
    }
    &__size-small__left{
      max-width: 200px;
      width: 100%;
      float: left;
      margin-right: @gap;

      @media only screen and (max-width: 560px),
      only screen and (max-device-width: 560px) {
        float: none;
        clear: both;
      }
    }
    &__size-small__right{
      max-width: 200px;
      width: 100%;
      float: right;
      margin-left: @gap;

      @media only screen and (max-width: 560px),
      only screen and (max-device-width: 560px) {
        float: none;
        clear: both;
      }
    }
  }
}

.mrgn__btm-1{
  margin-bottom: @gap !important;
}
.mrgn__btm-2{
  margin-bottom: @gap*2 !important;
}
.mrgn__btm-3{
  margin-bottom: @gap*3 !important;
}
.mrgn__btm-4{
  margin-bottom: @gap*4 !important;
}

// LISTS STYLE
//=============================================================================
[class*='list_v'] {
  margin: 0 auto 20px;

  li {
    position: relative;
    margin-bottom: 15px;
    padding-left: 25px;
  }
}

.list_v1 {
  li {

    &:before {
      content: @fa-var-check;
      width: 20px;
      height: 20px;
      position: absolute;
      left: -3px;
      top: 0;
      .fas;
      z-index: 3;
      .centerxy;
      font-size: 17px;
      text-rendering: auto;
      font-style: normal !important;
      color: @color_2;
    }
  }
}

.list_v2 {
  li {
    &:before {
      content: "\f00d";
      width: 20px;
      height: 20px;
      position: absolute;
      left: 0;
      top: 4px;
      z-index: 3;
      .centerxy;
      font-size: 16px;
      text-rendering: auto;
      font-style: normal !important;
      color: @color_4 !important;
      .fas;
    }
  }
}

.list_v3 {
  list-style-type: none;
  counter-reset: num;
  margin-bottom: 40px;

  > li {
    list-style: none;
    margin-bottom: 15px;
    min-height: 25px;

    &::before {
      content: counter(num) ' ' !important;
      counter-increment: num !important;
      color: @color_w;
      background: @color_1;
      width: 25px;
      height: 25px;
      .centerxy;
      position: absolute;
      line-height: 17px;
      left: -8px;
      top: 0;
      font-size: 13px;
      font-weight: 600;
      .radius(@br);

    }
  }
}

.list_v4 {
  li {
    padding: 0 0 14px 0;
    border-bottom: 1px solid @color_b6;
  }
}

.list_v5 {
  > li {
    margin-bottom: 10px;

    &::before {
      content: "\f111";
      background-position: 50% -20px * 18;
      width: 20px;
      height: 20px;
      position: absolute;
      left: -3px;
      top: 1px;
      font-size: 9px;
      .fas;
      z-index: 3;
      .centerxy;
      color: @color_2;
    }

    > ul {

      margin: 13px auto 0;

      > li {
        width: 100%;

        &::before {
          content: "\f111";
          background-position: 50% -20px * 18;
          width: 20px;
          height: 20px;
          position: absolute;
          left: -3px;
          top: 1px;
          font-size: 9px;
          .fas;
          z-index: 3;
          .centerxy;
          color: @color_b5;
        }
      }
    }
  }
}

.list_v6 {
  > li {
    margin-bottom: 8px;
    padding-left: 13px;

    &::before {
      content: @fa-var-circle;
      width: 10px;
      height: 10px;
      position: absolute;
      left: -3px;
      top: 3px;
      font-size: 6px;
      .fas;
      z-index: 3;
      .centerxy;
      color: @color_1;
    }

    > ul {

      margin: 13px auto 0;

      > li {
        width: 100%;

        &::before {
          content: @fa-var-circle;
          background-position: 50% -20px * 18;
          width: 10px;
          height: 10px;
          position: absolute;
          left: -3px;
          top: 1px;
          font-size: 9px;
          .fas;
          z-index: 3;
          .centerxy;
          color: @color_b5;
        }
      }
    }
  }
}



