// ---------------------------------- PARKING BOOKING LIST
.shuttle-book-list {
  position: relative;

  .shuttle-book-list__wrap {
    display: flex;
    flex-direction: column;
    gap: @gap /2;
  }

  .shuttle-book-list__item {
    display: flex;
    justify-content: flex-start; // align X
    align-items: flex-start; // align Y
    align-content: flex-start; // align Y ios
    flex-wrap: wrap;
    width: 100%;
    flex-direction: row;
    text-decoration: none;
    .block-style-css;

    &__body {
      width: 100%;
      display: flex;
      padding: @gap;
      gap: @gap;

      @media only screen and (max-width: 480px),
      only screen and (max-device-width: 480px) {
        flex-direction: column;

      }

    }

    .shuttle-book-list__item__prev-img {
      position: relative;
      padding-bottom: 0 !important;

      > div, > a {
        display: flex;
        max-width: 100px;
        min-width: 100px;
        position: relative;
        padding-bottom: 80%;
        width: 100%;
        overflow: hidden;
        border-radius: @br;

        @media only screen and (max-width: 480px),
        only screen and (max-device-width: 480px) {
          max-width: none;
          min-width: 200px;
          padding-bottom: 52%;
        }

        img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          transition: all 0.2s linear;
          font-family: 'object-fit: cover;';
        }
      }


    }

    .shuttle-book-list__item__text-content {
      flex: 1 1 280px;

      @media only screen and (max-width: 480px),
      only screen and (max-device-width: 480px) {
        flex: 1 1 auto;
      }

      .shuttle-book-list__item__title {
        margin-bottom: 10px;

        > * {
          font: @f_sbold 18px/120% @font;
          display: flex;
          gap: @gap / 2;
          justify-content: flex-start; // align X
          align-items: flex-start; // align Y
          align-content: flex-start; // align Y ios

          a {
            display: inline-flex;
            min-height: 22px;
            text-decoration: none;
            font: @f_sbold 18px/120% @font;
            color: @color_b1;

          }

          span.transport-type{
            display: flex;
            font: @f_reg 16px/120% @font;
            color: @color_b3;
            font-style: italic !important;
            position: relative;
            top: 1px;
          }
        }


      }

      .shuttle-book-list__item__content {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;

        .parking-rating {
          display: flex;
          gap: @gap / 2;
          justify-content: flex-start; // align X
          align-items: center; // align Y
          align-content: center; // align Y ios

          &__stars {
            display: flex;
            justify-content: flex-start; // align X
            align-items: center; // align Y
            align-content: center; // align Y ios

            .stars-svg {
              width: 80px;
              min-width: 80px;
              height: calc(80px / 5.9);
              position: relative;
              top: -3px;

              .stars-mask{
                fill: blanchedalmond;
              }

              .stars-scale {
                transform: translateX(-100%);
                fill: @color_3;
              }

              .stars-border {
                stroke: @color_3;
              }
            }
          }

          &__reviews {
            color: @color_b3;

          }
        }

        > * {
          font: @f_reg 14px/120% @font;
        }
      }

      .shuttle-book-list__item__content.limited-x5-lines {
        display: -webkit-box;
        -webkit-line-clamp: 5; // количество строк
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    .shuttle-book-list__item__price-box {
      display: flex;
      flex-direction: row;
      justify-content: flex-start; // align X
      align-items: center; // align Y
      align-content: center; // align Y ios
      border-top: 1px solid @color_b5;
      width: 100%;
      gap: @gap;
      padding: 8px 16px;

      .parking-price-container {
        display: flex;
        width: 100%;
        justify-content: flex-end; // align X
        align-items: flex-start; // align Y
        align-content: flex-start; // align Y ios
        flex-wrap: wrap;
        gap: @gap /2;

        &__passengers {
          * {
            white-space: nowrap;
          }
        }

        // доповнююча інфа ліворуч

        &__additional-info {
          display: flex;
          width: auto;
          gap: 8px;
          margin-right: auto;

          @media only screen and (max-width: 720px),
          only screen and (max-device-width: 720px) {
            min-width: calc(100% + (@gap * 2));
            position: relative;
            left: @gap;
            overflow: hidden;
            padding-left: @gap;
            padding-right: @gap;
            overflow-x: auto;
            -ms-overflow-style: none;  /* Приховує скроллбар в Internet Explorer і Edge */
            scrollbar-width: none;     /* Приховує скроллбар в Firefox */

          }


          &__box {
            display: flex;
            justify-content: center; // align X
            align-items: flex-start; // align Y
            align-content: flex-start; // align Y ios
            flex-direction: column;
            min-height: 40px;
            border-radius: @br;
            position: relative;
            white-space: nowrap;
            background: @color_b6;
            padding: 6px 8px 6px 32px;

            > span {
              font-size: var(--caption-micro);
              line-height: 110%;
              color: @color_b3;
            }

            > div {
              font-size: var(--caption);
              line-height: 110%;
            }

            svg {
              position: absolute;
              left: 10px;
              top: 10px;
              width: 16px;
              height: 16px;
              fill: @color_b3;
            }
          }

        }

        // ціни
        &__base-rate {
          display: flex;
          border-radius: @br;
          justify-content: flex-start; // align X
          align-items: flex-start; // align Y
          align-content: flex-start; // align Y ios
          gap: @gap;
          min-height: 40px;
          padding-bottom: 6px;

          @media only screen and (max-width: 460px),
          only screen and (max-device-width: 460px) {
            width: 100%;
            flex-wrap: wrap;
            justify-content: center; // align X
            background: @color_b6;
          }
          * {
            white-space: nowrap;
          }

          &__box {
            display: flex;
            flex: 1 1 auto;
            justify-content: flex-start; // align X
            align-items: flex-end; // align Y
            align-content: flex-end; // align Y ios

            @media only screen and (max-width: 460px),
            only screen and (max-device-width: 460px) {
              justify-content: center; // align X

            }

            > span {
              font-size: var(--caption);
              display: flex;
              line-height: 110%;

              b {
                display: inline-block;
                margin-left: 3px;
                margin-right: 3px;
              }

            }

            .base-rate {
              display: flex;
              text-align: right;
              justify-content: flex-end; // align X
              align-items: flex-end; // align Y
              align-content: flex-end; // align Y ios
              height: 30px;
              gap: 3px;

              > div {
                font-size: var(--headname-4);
                font-weight: @f_hbold;
              }

              > span {
                font-size: var(--paragraph-1);
                font-weight: @f_sbold;
                line-height: 110%;
                position: relative;
                top: -1px;
              }
            }
          }
        }
      }

      .price-box__buttons {
        display: flex;
        justify-content: flex-end; // align X
        align-items: flex-start; // align Y
        align-content: flex-start; // align Y ios

        > * {
          white-space: nowrap !important;
        }
      }
    }

    .parking-time-limit {
      display: flex;

      > * {
        display: inline-flex;
        padding: 4px 6px;
        background: @color_b6;
        border-radius: @br;
        font-size: var(--caption);
        justify-content: center; // align X
        align-items: center; // align Y
        align-content: center; // align Y ios
        margin-right: 8px;
        margin-bottom: 8px;

        &::after {
          content: '\f00c';
          color: @color_1;
          .fas;
          margin-left: 4px;
        }

      }
    }

    .parking-contacts {
      display: flex;
      flex-direction: column;
      gap: 8px;

      &__address, &__tel a, &__tel p, &__distance {
        display: flex;
        text-decoration: none;
        color: @color_b1;
        justify-content: flex-start; // align X
        align-items: center; // align Y
        align-content: center; // align Y ios

        &::before {
          display: flex !important;
          width: 18px;
          height: 18px;
          align-content: center;
          align-items: center;
          justify-content: center;
          position: relative;
          margin-right: 8px;
          top: -1px;
          .fas;
          font-size: var(--caption);
          color: @color_b3;
        }
      }

      &__address::before {
        content: '\f3c5';
      }


      &__distance {
        color: @color_b3;

        &::before {
          content: '\f4d7';
        }
      }

      &__tel a::before, &__tel p::before {
        content: '\f879';
      }
    }
  }
}



.shuttle-book-preview{
  display: flex;
  flex-direction: column;
  gap: @gap/2;

  &__descriptions{
    font-size: var(--paragraph-2);
  }

  &__tags{
    > p{
      display: flex;
      flex-wrap: wrap;
      gap: @gap/3 8px;
      font-size: var(--caption);

      span{
        display: inline-flex;
        position: relative;
        padding: 2px 8px 2px 0;
        white-space: nowrap;

        &:not(:last-child){
          border-right: 1px solid @color_b5;
        }
      }
    }
  }


}

body:not(.mobile) {
  .shuttle-book-list__item__prev-img:hover img {
    transform: scale(1.1);
  }

  .shuttle-book-list__item__title a:hover {
    color: @color_2 !important;
  }
}

// ---------------------------------- SHUTTLE FROM TO FORM

.shuttle-reservation-block {
  display: flex;
  justify-content: center; // align X
  align-items: center; // align Y
  align-content: center; // align Y ios
  gap: @gap /2;
  flex-direction: column;
  width: 100%;
  margin-bottom: @gap ;


  &__item {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: @gap*1.5 @gap @gap*1.5 @gap ;
    gap: @gap /2;
    border-radius: @br;
    border: 1px solid @color_b5;
    //background: @color_w;
    background: @color_b1;
    box-shadow: @shadow;

    &__title {
      display: flex;
      margin-bottom: 16px;

      > * {
        font: @f_sbold var(--headname-4)/120% @font;
        color: @color_b1;
      }
    }

    &__body {

      .shuttle-from-to-form {
        display: flex;
        position: relative;
        flex-direction: column;
        gap: @gap *1.5;
        transition: all 0.1s linear;

        .swap-btn{
          width: 32px;
          height: 32px;
          max-width: 32px;
          position: absolute;
          left: 50%;
          top: 50%;
          z-index: 4;
          transform: translate(-50%, -50%);
          display: flex;
          justify-content: center; // align X
          align-items: center; // align Y
          align-content: center; // align Y ios

          svg{
            width: 32px;
            height: 32px;
            fill: @color_b4;
            transition: all 0.2s linear;
          }

          @media (hover: hover) and (pointer: fine) {
            &:hover{
              cursor: pointer;

              svg{
                fill: @color_2;
              }
            }
          }

          @media only screen and (max-width: 560px),
          only screen and (max-device-width: 560px) {
            width: 46px;
            height: 46px;
            max-width: 46px;
            background: @color_1;
            border-radius: 100%;
            left: auto;
            right: 8px;
            transform: translate(0, -50%);

            svg{
              fill: @color_w;
            }

            @media (hover: hover) and (pointer: fine) {
                &:hover{
                    background: @color_2;

                  svg{
                    fill: @color_w !important;
                  }
                }
            }
          }
        }

        .fields-wrap {
          display: flex;
          flex-wrap: nowrap;
          gap:  @gap*1.5 calc(@gap*4);
          position: relative;

          @media only screen and (max-width: 560px),
          only screen and (max-device-width: 560px) {
              flex-direction: column;
          }


          > *:not(.swap-btn) {
            flex: 1 1 50%;
            position: relative;
          }
        }

        input, textarea{
          &:focus:placeholder-shown,
          &:not(:placeholder-shown){
            & ~ label.label_bottom{
              color: @color_w;
            }
          }
        }
      }

      .price-info{
        display: flex;
        width: 100%;
        flex-direction: column;
        padding-bottom: @gap  /2;
        border-bottom: 1px solid @color_b5;
        margin-bottom: @gap /2;

        .more-price-info-btn{
          display: flex;
          gap: @gap / 2;
          justify-content: flex-start; // align X
          align-items: center; // align Y
          align-content: center; // align Y ios
          cursor: pointer;

          i{
            position: relative;
            top:-2px;
          }
        }

        > *:first-child{
          display: flex;
          width: 100%;
          justify-content: space-between; // align X
          align-items: center; // align Y
          align-content: center; // align Y ios

          font-size: var(--paragraph-2);
        }

        &.total-sum{
          > div:first-child{
            > div{
              font: @f_hbold var(--headname-4)/120% @font;

              @media only screen and (max-width: 860px),
              only screen and (max-device-width: 860px) {
                font: @f_hbold var(--headname-5)/120% @font;
              }
            }
          }

        }

        &__details{
          display: none;

          table{
            width: 100%;
            tr{
              td{
                font-size: var(--caption-micro);
                color: @color_b2;
                vertical-align: middle;

                &:first-child{
                  padding-left: 12px;
                  position: relative;


                  &::after{
                    content: '';
                    width: 3px;
                    height: 3px;
                    background: @color_b3;
                    border-radius: 100%;
                    position: absolute;
                    top: 9px;
                    left: 4px;
                  }
                }
                &:last-child{
                  text-align: right;
                }
              }
            }
          }
        }

        &.active{
          .price-info__details{
            display: block;
          }
        }
      }

      .button-search{
        display:flex;
        justify-content: stretch; // align X
        align-items: center; // align Y
        align-content: center; // align Y ios
        border-color:  greenyellow !important;

        > button{
          width: 100% !important;
        }
      }
    }

    .button-stretched {
      display: flex;
      flex: 1 1 auto;

      > * {
        width: 100% !important;
        max-width: none;
        margin-left: auto;
      }
    }

    &.form-edit-close {
      .edit-dates-block ~ .shuttle-reservation-block__item__body{
        // form vis
        .shuttle-start-end-form{
          transform: scaleY(0);
          opacity: 0;
          pointer-events: none;
          max-height: 0;
          padding-bottom: 0;
          border-bottom: none;
          margin-bottom: 0;
        }
      }

      // button edit \ cancel
      .edit-dates-block{
        .edit-date-btn{
          display: flex;
        }
        .edit-cancel-btn{
          display: none;
        }

      }
      // dates previews
      .edit-dates-block__body{
        transform: scaleY(1);
        opacity: 1;
        pointer-events: all;
        max-height: 300px;
        padding-bottom: @gap / 2;
      }
    }

    &:not(.form-edit-close){
      .edit-dates-block ~ .shuttle-reservation-block__item__body {
        // form vis
        .shuttle-start-end-form {
          padding-top: 10px;
          transform: scaleY(1);
          opacity: 1;
          pointer-events: all;
          max-height: 300px;
          padding-bottom: @gap;
          border-bottom: 1px solid @color_b5;
          margin-bottom: @gap / 2;

        }
      }

      // button edit \ cancel
      .edit-dates-block{
        .edit-date-btn{
          display: none;
        }
        .edit-cancel-btn{
          display: flex;
        }

      }

      // dates previews
      .edit-dates-block__body{
        transform: scaleY(0);
        opacity: 0;
        pointer-events: none;
        max-height: 0;
        padding-bottom: 0;
      }
    }
  }

}

body:not(.mobile){
  .edit-dates-block {
    &__head {
      &__button {
        > *:hover {
          cursor: pointer;
          color: @color_2;
          user-select: none;
        }
      }
    }
  }

  .more-price-info-btn:hover i svg{
    fill: @color_2;

  }
}

.info-icon{
  display: flex;
  justify-content: center; // align X
  align-items: center; // align Y
  align-content: center; // align Y ios

  svg{
    width: 12px;
    height: 12px;
    fill: @color_b3;
  }
}


.main-shuttle-image-preview{
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-99999px , -50% );
  pointer-events: none;
  opacity: 1;
  margin-right: auto;
  border-radius: 100%;
  overflow: hidden;

  img{
    width:  40px;
    height: 40px;
  }
}
