// BUTTONS style
//=============================================================================
.btn__theme-1 {
  border: none;
  background: none;
  outline: none;
  .radius(@br);
  font: @f_reg var(--paragraph-1) /140% @font;
  .centerxy;
  width: auto;
  min-width: 120px;
  height: @elements_h;
  padding: 2px 20px 0;
  background: @color_1;
  text-align: center;
  text-decoration: none;
  color: @color_w;
  white-space: nowrap;
  .anim_on(@speed);

  > *:not(:last-child) {
    margin-right: 7px;
  }

}

.btn__theme-2 {
  .centerxy;
  width: auto;
  min-width: 120px;
  height: @elements_h;
  padding: 0 15px;
  text-align: center;
  text-decoration: none;
  border: 1px solid @color_1;
  background: none;
  color: @color_1 !important;
  white-space: nowrap;
  .anim_on(@speed);
  .radius(@br);

  > *:not(:last-child) {
    margin-right: 7px;
  }

  &:active {
    transform: translateY(1px);
  }
}

.btn__theme-3 {
  .centerxy;
  width: auto;
  height: @elements_h;
  padding: 0 15px;
  text-align: center;
  text-decoration: none;
  border: 2px solid @color_w;
  color: @color_b1;
  background: @color_w;
  white-space: nowrap;
  .anim_on(@speed);
  .radius(@br);
  .shadow(@shadow);

  &:active {
    .transform(translateY(1px));
  }

}

[class*="style-btn__"] {
  display: flex;
  display: -ms-flexbox;
  .no_select;

  > * {
    i {
      margin-right: 10px;
    }
  }
}

.style-btn__1 {
  > * {
    .btn__theme-1;
  }
}

.style-btn__2 {
  > * {
    .btn__theme-2;
  }
}

.style-btn__3 { // white
  > * {
    .btn__theme-3;
  }
}


// HOVER not mobile
body:not(.mobile) {
  .style-btn__1 > *:not([disabled]):hover,
  .btn__theme-1:not([disabled]):hover {
    cursor: pointer !important;
    background: @color_2;
    color: @color_w;
    box-shadow: @primary-btn-hover;
  }

  .style-btn__2 > *:not([disabled]):hover,
  .btn__theme-2:not([disabled]):hover {
    cursor: pointer !important;
    background: @color_w;
    border-color: @color_2;
    color: @color_2 !important;
    box-shadow: @primary-btn-hover;
  }

  .style-btn__3 > *:not([disabled]):hover,
  .btn__theme-3:not([disabled]):hover {
    cursor: pointer !important;
    background: @color_w;
    color: @color_b1 !important;
    box-shadow: @primary-btn-hover;
  }
}

.btns_group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-content: flex-start;
  align-items: stretch;
}

.btn_l {
  justify-content: flex-start !important;
}

.btn_c {
  justify-content: center !important;
}

.btn_r {
  justify-content: flex-end !important;

}


.btn-small-size{
  //margin-left: auto;
  display: inline-flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 5px 12px 4px;
  min-width: 124px;
  border-radius: @br;
  border: 1px solid @color_1;
  background: @color_1;
  color: @color_w;
  font-size: 12px;
  white-space: nowrap;

  &:after {
    content: '\f178';
    .fal;
    margin-left: 8px;
  }

  &:hover {
    background: @color_2;
    color: @color_w;
    border-color: @color_2;
    box-shadow: @primary-btn-hover;
  }
}

.btn-small-size-secondary{
  //margin-left: auto;
  display: inline-flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 5px 12px 4px;
  border-radius: @br / 1.5;
  border: 1px solid @color_1;
  background: @color_w;
  color: @color_1;
  font-size: 12px;
  white-space: nowrap;

  &:after {
    content: '\f178';
    .fal;
    margin-left: 8px;
  }

  &:hover {
    background: @color_w;
    color: @color_2;
    border-color: @color_2;
    box-shadow: @primary-btn-hover;
  }
}