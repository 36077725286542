:root{
  --app-height: 100%;
  --app-width: 100vw;
  --left-nav-width: 220px;
  --right-parking-sidebar-width: 240px;
  // width limit
  --width-limit: 858px;

  // headnames
  --headname-1: 38px;
  --headname-2: 32px;
  --headname-3: 24px;
  --headname-4: 20px;
  --headname-5: 18px;
  --headname-6: 16px;
  --headname-7: 14px;

  // paragraph
  --paragraph-1: 16px;
  --paragraph-2: 14px;
  --caption: 12px;
  --caption-micro: 10px;


  --interactive-map-menu: 200px;

  --header-d-height: 70px;
  --header-m-height: 50px;
}