.footer {
  background: @color_1;
  color: @color_w;
  display: flex;
  z-index: 3;
  justify-content: space-between;
  padding: @gap/2 @gap *2 @gap/2 @gap;
  min-height: 40px;

  @media only screen and (max-width: 959.98px),
  only screen and (max-device-width: 959.98px){
    padding: 4px 12px;
    min-height: 32px;
  }

  &__copyright {
    display: flex;
    justify-content: flex-start; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios
    font-size: var(--caption);

    @media only screen and (max-width: 959.98px),
    only screen and (max-device-width: 959.98px){
      order: 3;
      font-size: var(--caption-micro);
    }
  }

  &__links {
    display: flex;
    justify-content: flex-end; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios
    font-size: var(--caption);

    @media only screen and (max-width: 959.98px),
    only screen and (max-device-width: 959.98px) {
      display: none;
      flex-direction: column;
      align-content: center;
      align-items: center;
      justify-content: center;

      a{
        text-align: center;
        font-size: var(--caption-micro);
      }
    }

    a{
      display: inline-flex;
      color: rgba(@color_w, .5);
      margin-left: @gap/2;
      text-decoration: none;

      &:not(:last-child){
        margin-right: @gap/2;
      }
      &:hover{
        color: @color_w;
      }
    }
  }

  @media only screen and (max-width: 959.98px),
  only screen and (max-device-width: 959.98px) {
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  @media only screen and (max-width: 959.98px),
  only screen and (max-device-width: 959.98px) {

    &__copyright {
     font-size: 13px;
    }
  }

}