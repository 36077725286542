.daterangepicker {
  position: absolute;
  color: inherit;
  background-color: @color_w;
  border-radius: @br;
  border: 1px solid @color_b5;
  width: 278px;
  max-width: none;
  padding: 0;
  margin-top: 7px;
  top: 100px;
  left: 20px;
  z-index: 3001;
  display: none;
  font-family: @font;
  font-size: 14px;
  line-height: 1em;
  box-shadow: @shadow;

  &:before, &:after {
    position: absolute;
    display: inline-block;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    content: '';
  }

  &:before {
    top: -7px;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    border-bottom: 7px solid @color_b4;
  }

  &:after {
    top: -6px;
    border-right: 6px solid transparent;
    border-bottom: 6px solid @color_w;
    border-left: 6px solid transparent;
  }

  &.opensleft {
    &:before {
      right: 9px;
    }

    &:after {
      right: 10px;
    }
  }

  &.openscenter {
    &:before, &:after {
      left: 0;
      right: 0;
      width: 0;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &.opensright {
    &:before {
      left: 9px;
    }

    &:after {
      left: 10px;
    }
  }

  &.drop-up {
    margin-top: -7px;

    &:before {
      top: initial;
      bottom: -7px;
      border-bottom: initial;
      border-top: 7px solid @color_b4;
    }

    &:after {
      top: initial;
      bottom: -6px;
      border-bottom: initial;
      border-top: 6px solid @color_w;
    }
  }

  &.single {
    .ranges, .drp-calendar {
      float: none;
    }

    .drp-selected {
      display: none;
    }
  }

  &.show-calendar {
    .drp-calendar {
      display: block;
    }

    .drp-buttons {
      display: block;
    }
  }

  &.auto-apply {
    .drp-buttons {
      display: none;
    }
  }

  .drp-calendar {
    display: none;
    max-width: 270px;

    &.left {
      padding: 8px 0 8px 8px;
    }

    &.right {
      padding: 8px;
    }

    &.single .calendar-table {
      border: none;
    }
  }

  .calendar-table {
    .next span, .prev span {
      color: @color_w;
      border: solid @color_b2;
      border-width: 0 2px 2px 0;
      border-radius: 0;
      display: inline-block;
      padding: 3px;
    }

    .next span {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }

    .prev span {
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
    }

    th, td {
      white-space: nowrap;
      text-align: center;
      vertical-align: middle;
      min-width: 32px;
      width: 32px;
      height: 24px;
      line-height: 24px;
      font-size: 12px;
      border-radius: @br;
      border: 1px solid transparent;
      cursor: pointer;
    }

    table {
      width: 100%;
      margin: 0;
      border-spacing: 0;
      border-collapse: collapse;
    }

    td.available:hover, th.available:hover {
      background-color: @color_b6;
      border-color: transparent;
      color: inherit;
    }

    td.week, th.week {
      font-size: 80%;
      color: @color_b4;
    }

    td.off, td.off.in-range, td.off.start-date, td.off.end-date {
      background-color: @color_w;
      border-color: transparent;
      color: #999;
    }

    td.in-range {
      background-color: #ebf4f8;
      border-color: transparent;
      color: #000;
      border-radius: 0;
    }

    td.start-date {
      border-radius: @br 0 0 @br;
    }

    td.end-date {
      border-radius: 0 @br @br 0;
    }

    td.start-date.end-date {
      border-radius: @br;
    }

    td.active, td.active:hover {
      background-color: @color_1;
      border-color: transparent;
      color: @color_w;
    }

    td:not(.active).today{
      box-shadow: inset 0 0 0 1px @color_b4;
    }

    th.month {
      width: auto;
    }

    td.disabled, option.disabled {
      color: #999;
      cursor: not-allowed;
      text-decoration: line-through;
    }
  }

  select.monthselect, select.yearselect {
    font-size: 12px;
    padding: 1px;
    height: auto;
    margin: 0;
    cursor: default;

    &.monthselect {
      margin-right: 2%;
      width: 56%;
    }

    &.yearselect {
      width: 40%;
    }
  }

  select.hourselect, select.minuteselect, select.secondselect, select.ampmselect {
    width: 50px;
    margin: 0 auto;
    background: @color_b6;
    border: 1px solid @color_b6;
    padding: 2px;
    outline: 0;
    font-size: 12px;
    border-radius: @br;
  }

  .calendar-time {
    text-align: center;
    margin: 4px auto 0 auto;
    line-height: 30px;
    position: relative;

    select.disabled {
      color: @color_b4;
      cursor: not-allowed;
    }
  }

  .drp-buttons {
    clear: both;
    text-align: right;
    padding: 8px;
    border-top: 1px solid @color_b5;
    display: none;
    line-height: 12px;
    vertical-align: middle;

    .btn {
      margin-left: 8px;
      font-size: 12px;
      font-weight: @f_reg;
      padding: 4px 8px;
      border: none;

      &.btn-default{
        background: @color_w;
        border: 1px solid @color_1;
        border-radius: @br;
        color: @color_b1;
      }
      &.btn-primary{
        background: @color_1;
        border: 1px solid @color_1;
        border-radius: @br;
        color: @color_w;
      }
    }
  }

  .drp-selected {
    display: inline-block;
    font-size: 12px;
    padding-right: 8px;
  }

  &.show-ranges {
    &.single {
      .drp-calendar.left {
        border-right: 1px solid @color_b5;
      }

      &.ltr {
        .drp-calendar.left {
          border-left: 1px solid @color_b5;
        }
      }
    }

    &.rtl {
      .drp-calendar.right {
        border-right: 1px solid @color_b5;
      }
    }

    &.ltr {
      .drp-calendar.left {
        border-left: 1px solid @color_b5;
      }
    }

    .ranges {
      float: none;
      text-align: left;
      margin: 0;

      ul {
        list-style: none;
        margin: 0 auto;
        padding: 0;
        width: 100%;
      }

      li {
        font-size: 12px;
        padding: 8px 12px;
        cursor: pointer;

        &:hover {
          background-color: @color_b6;
        }

        &.active {
          background-color: @color_1;
          color: @color_w;
        }
      }
    }

    &.show-calendar {
      .ranges {
        margin-top: 8px;
      }
    }
  }

  @media (min-width: 564px) {
    width: auto;

    .ranges ul {
      width: 140px;
    }

    &.single {
      .ranges ul {
        width: 100%;
      }

      .drp-calendar.left {
        clear: none;
      }

      .ranges, .drp-calendar {
        float: left;
      }
    }

    direction: ltr;
    text-align: left;

    .drp-calendar.left {
      clear: left;
      margin-right: 0;

      .calendar-table {
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .drp-calendar.right {
      margin-left: 0;

      .calendar-table {
        border-left: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    .drp-calendar.left .calendar-table {
      padding-right: 8px;
    }

    .ranges, .drp-calendar {
      float: left;
    }
  }

  @media (min-width: 730px) {
    .ranges {
      width: auto;
      float: left;

      &.rtl {
        float: right;
      }
    }

    .drp-calendar.left {
      clear: none !important;
    }
  }
}