// ---------------------- MAP 3D INTERACTIVE --------------------
.interactive-map-title {
  position: absolute;
  left: var(--interactive-map-menu);
  padding: 16px;
  z-index: 2;

  @media only screen and (max-width: 780px), only screen and (max-device-width: 780px) {
    left: 0;
  }

  h1 {
    text-align: left;
    font-size: 0;

    a {
      position: relative;

      @media only screen and (max-width: 760px), only screen and (max-device-width: 760px) {
        span {
          display: none !important;
        }
      }


      &::before {
        border-bottom: 1.2px dashed rgba(@color_b1, 0.5);
        display: block;
        content: '';
        position: absolute;
        width: calc(100% - 24px);
        bottom: -2px;
        left: 0;
        transition: all 0.2s linear;
      }
    }

    a, b {
      text-decoration: none;
      color: @color_b1;
      position: relative;
      font: @f_sbold var(--headname-3)/110% @font;

      @media only screen and (max-width: 840px), only screen and (max-device-width: 840px) {
        font-size: var(--headname-4);
      }


      &:not(:last-child) {
        padding-right: 22px;

        &::after {
          content: '';
          width: 2px;
          height: 80%;
          right: 10px;
          top: 50%;
          opacity: 0.5;
          position: absolute;
          transform: translateY(-50%);
          background: @color_b2;
        }
      }
    }
  }
}

body:not(.mobile) {
  .interactive-map-title a:hover {
    color: @color_2;

    &::before {
      border-color: @color_2;
    }
  }
}

.interactive-map-menu__wrap {
  overflow: hidden;
  overflow-y: auto;
  -ms-overflow-style: none; /* Приховує скроллбар в Internet Explorer і Edge */
  scrollbar-width: none; /* Приховує скроллбар в Firefox */
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: var(--interactive-map-menu);
  height: var(--app-height);
  position: fixed;
  top: 0;
  left: 0;
  padding: 8px;
  z-index: 3;
  transition: all 0.2s linear;

  @media (min-width: 960px) and (max-width: 2480px) {
    padding: calc(70px + 8px) 8px 40px;
  }
  @media (min-width: 280px) and (max-width: 959.98px) {
    padding: calc(50px + 8px) 8px 32px;
  }

  @media only screen and (max-width: 780px), only screen and (max-device-width: 780px) {
    transform: translateX(-100%);
    background: @color_b6;
    box-shadow: @shadow;

    &.active {
      transform: translateX(0);
      opacity: 1;
      visibility: visible;

      & ~ .btn-interactive-map-menu-mobile {
        left: calc(var(--interactive-map-menu) + 10px);

        svg {
          &.btn-interactive-map-menu-mobile__open {
            display: none;
          }

          &.btn-interactive-map-menu-mobile__close {
            display: block;
          }
        }
      }

    }

  }

}

// button menu visible
.btn-interactive-map-menu-mobile {
  display: none;
}

@media only screen and (max-width: 780px), only screen and (max-device-width: 780px) {
  .btn-interactive-map-menu-mobile {
    display: flex !important;
    justify-content: center; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios
    width: 40px;
    height: 40px;
    position: fixed;
    left: 10px;
    bottom: calc(32px + 10px);
    z-index: 3;
    border-radius: @br;
    border: 1px solid @color_b5;
    background: @color_w;
    box-shadow: 1px 4px 20px -4px rgba(0, 0, 0, .1);
    transition: all 0.2s linear;

    svg {
      width: 24px;
      height: 24px;
      fill: @color_1;
      transition: all 0.2s linear;
    }

    &__open {
      display: block;
    }

    &__close {
      display: none;
    }
  }
}


.interactive-map-menu {
  gap: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: @gap;

  &__box {
    border-radius: @br;
    border: 1px solid @color_b5;

    &__item {
      display: flex;
      justify-content: flex-start; // align X
      align-items: center; // align Y
      align-content: center; // align Y ios
      background: @color_w;
      min-height: 40px;
      font-size: 12px;

      .back-link {
        padding: 0 8px;
        text-decoration: none;
        display: flex;
        width: 100%;
        justify-content: flex-start; // align X
        align-items: center; // align Y
        align-content: center; // align Y ios
        gap: 4px;
        min-height: 40px;
        color: @color_b1;
        line-height: 120%;

        svg {
          width: 24px;
          height: 24px;
          fill: @color_b3;
          line-height: 140%;
        }

      }

      .reset-btn {
        padding: 0 8px;
        text-decoration: none;
        display: flex;
        min-height: 36px;
        width: 100%;
        justify-content: flex-start; // align X
        align-items: center; // align Y
        align-content: center; // align Y ios
        gap: 4px;
        line-height: 120%;
        outline: none;
        border: 0;
        box-shadow: none;
        background: 0;
        color: @color_b1;

        svg {
          width: 24px;
          height: 24px;
          fill: @color_b3;
          line-height: 140%;
          transition: all 0.2s linear;
        }

      }

      .invert-btn {
        padding: 0 8px;
        text-decoration: none;
        display: flex;
        min-height: 36px;
        width: 100%;
        justify-content: flex-start; // align X
        align-items: center; // align Y
        align-content: center; // align Y ios
        gap: 4px;
        line-height: 120%;
        outline: none;
        border: 0;
        box-shadow: none;
        background: 0;
        color: @color_b1;

        svg {
          width: 24px;
          height: 24px;
          fill: @color_b3;
          line-height: 140%;
          transition: all 0.2s linear;
        }

      }

      &:first-child {
        border-radius: @br @br 0 0;
      }

      &:not(:last-child) {
        border-bottom: 1px solid @color_b5;
      }

      &:last-child {
        border-radius: 0 0 @br @br;
      }

      &:first-child:last-child {
        border-radius: @br;
      }
    }
  }

}

.interactive-map-container {
  background: @color_b4;
  position: absolute !important;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;

  @media (min-width: 960px) and (max-width: 2480px) {
    //padding: 70px 0 40px;
    top: var(--header-d-height);
    min-height: calc(var(--app-height) - (var(--header-d-height) + 40px));
  }
  @media (min-width: 280px) and (max-width: 959.98px) {
    //padding: 50px 0 32px;
    top: var(--header-m-height);
    min-height: calc(var(--app-height) - (var(--header-m-height) + 32px));
  }

}

.radio-btn {
  display: block;
  width: 100%;

  label {
    display: flex;
    justify-content: center; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios
    min-height: 40px;
    padding: 0 8px;
    gap: 8px;
    width: 100%;
    cursor: pointer;

    input {
      pointer-events: none;
      position: absolute;
      opacity: 0;
    }

    .radio-btn__color-mark {
      width: 32px;
      height: 32px;
      min-width: 32px;
      min-height: 32px;
      transition: all 0.2s linear;
      opacity: 0.5;
    }

    b {
      font-weight: @f_reg !important;
      width: 100%;
      line-height: 120%;
      transition: all 0.2s linear;
      opacity: 0.5;
    }

    .check {
      display: none;
      position: relative;
      transition: all 0.2s linear;
      opacity: 0;

      svg {
        width: 16px;
        height: 16px;
        min-width: 16px;
        min-height: 16px;
        fill: @color_b1;
      }
    }

    input:not(:checked) {
      & ~ .radio-btn__color-mark {
        opacity: 0.5;
        filter: grayscale(100%);
      }
    }

    input:checked {
      & ~ .check,
      & ~ .radio-btn__color-mark,
      & ~ b {
        opacity: 1;
      }
    }


  }


}

.custom-drop-list {
  display: flex;
  width: 100%;
  position: relative;
  justify-content: flex-start; // align X
  align-items: center; // align Y
  align-content: center; // align Y ios
  gap: 8px;
  padding: 0 8px;
  min-height: 40px;
  z-index: 2;
  cursor: pointer;
  user-select: none;

  &.active {
    z-index: 10;
    -moz-border-radius-bottomleft: 0;
    -moz-border-radius-bottomright: 0;
  }

  &__icon {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    fill: @color_b3;
    position: relative;
    transition: all 0.2s linear;
    pointer-events: none;
  }

  &__selected {
    white-space: nowrap;
    line-height: 120%;
    width: calc(100% - 54px);
    overflow: hidden;
    text-overflow: ellipsis;

    &::before {
      .centerxy !important;
      content: '\f078';
      width: 24px;
      height: 24px;
      position: absolute;
      pointer-events: none;
      font-size: 12px;
      right: 4px;
      top: 50%;
      transform: translateY(-50%) scale(1);
      color: @color_b3;
      .fal;
    }
  }

  &__options {
    position: absolute;
    opacity: 0;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    width: calc(100% + 2px);
    top: 32px;
    transform: scaleY(0.7);
    pointer-events: none;
    left: -1px;
    transition: all 0.2s linear;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
    transform-origin: 0 0;
    border-radius: 0 0 @br @br;
    border-style: solid;
    border-color: @color_b5;
    border-width: 0 1px 1px 1px;

    &__item {
      display: flex;
      background: @color_w;

      &:first-child {
        border-radius: 0;
      }

      &:last-child {
        border-radius: 0 0 @br @br;

        a {
          border-radius: 0 0 @br @br;
        }

      }

      &:not(:last-child) {
        border-bottom: 1px solid @color_b6;
      }

      &:first-child:last-child {
        border-radius: @br;
      }

      a {
        width: 100%;
        display: flex;
        justify-content: flex-start; // align X
        align-items: center; // align Y
        align-content: center; // align Y ios
        white-space: nowrap;
        padding: 6px 12px;
        min-height: 36px;
        text-decoration: none;
        color: @color_b1;
        position: relative;

        &.active {
          font-weight: @f_hbold;
          pointer-events: none;
          cursor: default !important;

          &::after {
            content: '';
            display: block;
            width: 6px;
            height: 6px;
            border-radius: 3px;
            background: @color_1;
            position: absolute;
            right: 13px;
            z-index: 2;
            pointer-events: none;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }

  &.active {
    .custom-drop-list__selected {
      &::before {
        color: @color_1;
        transform: translateY(-50%) scale(-1);
      }
    }

    .custom-drop-list__options {
      top: 34px;
      opacity: 1;
      transform: scaleY(1);
      pointer-events: all;
    }
  }

}

body:not(.mobile) {
  .interactive-map-menu {
    .back-link:hover {
      box-shadow: @primary-btn-hover;
      color: @color_2;

      svg {
        fill: @color_2;
      }

    }

    .reset-btn:hover {
      color: @color_1;

      svg {
        fill: @color_2;
      }

    }

    .invert-btn:hover {
      color: @color_1;

      svg {
        fill: @color_2;
      }
    }
  }

  .custom-drop-list {
    &__options {
      &__item {
        a:not(.active):hover {
          background: @color_b6;
          cursor: pointer;
        }
      }
    }
  }
}


body:not(.mobile) {
  .btn-interactive-map-menu-mobile:hover {
    background: @color_2;
    border-color: @color_2;
    box-shadow: @primary-btn-hover;
    cursor: pointer;

    svg {
      fill: @color_w;
    }

  }
}


// TOOLTIPS

// 2D map
.custom-popup {
  .leaflet-popup-content-wrapper, .leaflet-popup-tip {
    box-shadow: 0 8px 20px -4px rgba(0, 0, 0, 0.1) !important;
    font-family: Roboto, Arial, sans-serif;

    h4 {
      font-size: 14px;
      font-weight: 500;
    }
    dl {


      > div {
        display: flex;
        gap: 4px;
      }
    }
  }

  .leaflet-popup-close-button{
    display: flex;
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    border-radius: 100%;
    justify-content: center; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios
    top: -2px;
    right: -2px;

    @media (hover: hover) and (pointer: fine) {
      &:hover{
        background: none !important;
        svg {
          fill: @color_2;
        }
      }
    }


    svg {
      width: 16px;
      height: 16px;
      min-width: 16px;
      min-height: 16px;
      fill: @color_b3;
      transition: all 0.2s linear;
    }

  }
}

.my-div-icon {
  .my-div-span {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap !important;
    text-align: center;
    bottom: -18px;
  }
}

// 3D map

.custom-popup-interactive {
  .mapboxgl-popup-content {
    box-shadow: 0 8px 20px -4px rgba(0, 0, 0, 0.1) !important;
    font-family: Roboto, Arial, sans-serif;
    padding: 20px 20px;
    border-radius: 8px;
    position: relative;

    h4 {
      font-size: 14px;
      font-weight: 500;
    }

    dl {


      > div {
        display: flex;
        gap: 4px;
      }
    }

    .mapboxgl-popup-close-button {
      display: flex;
      width: 24px;
      height: 24px;
      min-width: 24px;
      min-height: 24px;
      border-radius: 100%;
      justify-content: center; // align X
      align-items: center; // align Y
      align-content: center; // align Y ios
      top: -2px;
      right: -2px;

      @media (hover: hover) and (pointer: fine) {
        &:hover{
          background: none !important;
          svg {
            fill: @color_2;
          }
        }
      }


      svg {
        width: 16px;
        height: 16px;
        min-width: 16px;
        min-height: 16px;
        fill: @color_b3;
        transition: all 0.2s linear;
      }

    }
  }

}



