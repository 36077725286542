// ACCORDION BASE
.accordion {
  display: flex;
  flex-direction: column;
  //border: 1px solid red;

  .accordion__item {
    display: flex;
    flex-direction: column;
    //border: 1px solid red;
    position: relative;

    .accordion__item__title {
      //border: 1px solid green;
      display: flex;
      align-items: center;
      align-content: center;
      text-align: left;
      position: relative;
      outline: none;

      &.fixed {
        position: fixed;
        z-index: 500;
        background: @color_w;
        border-radius: 0;
        border-width: 1px 1px 0 1px;
        border-color: @color_1;
        border-style: solid;
        box-shadow: 0 5px 20px -5px rgba(@color_b1, 0.1);
        transform: translateX(-1px);
      }

      &:hover {
        cursor: pointer;
      }

      &::after {
        content: '\f055';
        .far;
        position: absolute;
        right: 10px;
        top: 10px;
        color: @color_1;
      }
    }
  }

  .accordion__item.active {
    > .accordion__item__title {
      &::after {
        content: '\f056';
        color: @color_1;
      }
    }

    > .accordion__item__content {
      // display: block;
    }
  }
}

// accordion styles v1
.accordion__theme-one {
  margin-bottom: @gap;

  .accordion__item {
    background: @color_w;
    border-radius: @br;
    margin-bottom: @gap / 2;
    border: 1px solid @color_b5;

    &.active {
      border-color: @color_1;
      // box-shadow: 0 4px 17px 0 rgba(0,76,175, .13), inset 0 0 0 1px @color_1;
    }

    .accordion__item__title {
      //border: 1px solid green;
      min-height: 46px;
      padding: 10px 40px 10px 16px;

      > * {
        font: @f_sbold 20px/120% @font;

        @media only screen and (max-width: 860px), only screen and (max-device-width: 860px) {
          font: @f_sbold 18px/120% @font;

        }

      }

      .names {
        margin-left: @gap;
        font: @f_reg 16px/120% @font;
      }

      &::after {
        right: 14px;
        top: 14px;
      }
    }

    .accordion__item__content {
      display: none;
      padding: 3px 16px 16px 16px;

      > * {
        font: @f_reg 16px/120% @font !important;
      }

      //border: 1px solid yellowgreen;
    }
  }

  .accordion__item.active {
    > .accordion__item__title {
      &::after {
        content: '\f056';
        color: @color_1;
      }
    }
  }
}



