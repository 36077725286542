[class*='col-wrap_'] {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: stretch;

  margin-left: -@gap / 2;
  margin-right: -@gap / 2;

  & > * {
    min-height: 20px;
    margin-bottom: @gap*2;
    padding-left: @gap / 2;
    padding-right: @gap / 2;

    > * {
      //display: block;
      //width: 100%;
    }
  }


  &.col-wrap__2 {
    > * {
      width: 50%;
    }
  }

  &.col-wrap__3 {
    > * {
      width: 33.33%;
    }
  }

  &.col-wrap__4 {
    > * {
      width: 25%;
    }
  }

  &.col-wrap__5 {
    > * {
      width: 20%;
    }
  }

  &.col-wrap__theme-2 {
    > * {
      width: 33.33%;
    }

    > *:nth-child(4n-1), > *:nth-child(4n-2) {
      width: 66.66%;
    }

    @media only screen and (max-width: 680px),
    only screen and (max-device-width: 680px) {
      > * {
        width: 20%;
      }
      > *:nth-child(4n-1), > *:nth-child(4n-2) {
        width: 80%;
      }
    }

    @media only screen and (max-width: 480px),
    only screen and (max-device-width: 480px) {
      flex-direction: column;

      > * {
        width: 100%;
      }
      > *:nth-child(4n-1), > *:nth-child(4n-2) {
        width: 100%;
      }
    }

  }

  &.col-wrap__theme-2 {
    > * {
      flex: 0 0 33.33%;
    }

    > *:nth-child(4n-2) {
      flex: 0 0 66.66%;
    }

  }


}

.col_test {
  > * {
    > * {
      // test vis prev
      border: 1 solid @color_b6;
      min-height: 30vh;
      background: @color_w;
      .shadow(@shadow);
      .radius(@br);
    }
  }
}
/*
.wrap {

  flex-wrap: wrap;
}

.nowrap {

  flex-wrap: nowrap;
}

 */

